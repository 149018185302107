import React, { FC, useState } from "react";
import "./journeyEventCard.scss";
import userPlaceholder from "../../../../../assets/userPlaceholder.png";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import DpChip from "../../../../../shared/components/DpChip";
import { Staff } from "../../../../../models/Hospital/staff.model";
import StaffBasicDetails from "../../../../../shared/components/StaffBasicDetails";

interface JourneyEventCardProps {
  journeyStep?: JourneyTemplateStep;
  index: number;
}

const JourneyEventCard: FC<JourneyEventCardProps> = (props) => {
  const { journeyStep, index } = props;

  const [currentStaff, setCurrentStaff] = useState<Staff>();

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <div className={`journey-events__card completed`}>
      <div className="journey-events__card__title">
        {journeyStep?.title}{" "}
        <span className="journey-event__visit">Visit - {index + 1}</span>
      </div>
      <div
        className="journey-events__card__description"
        dangerouslySetInnerHTML={{ __html: journeyStep?.description ?? "" }}
      />
      {journeyStep?.staffs?.map(step => <div
        onClick={(e) => {
          e.stopPropagation()
          setCurrentStaff(step);
          setShowDetails(true);
        }}
        className="journey-events__card__staff">
        <DpChip name={`${step.firstname || ""} ${step.lastname || ""}`} size="mini"
          url={step?.displayPictureUrl} />
        {/* <span className="journey-events__card__staff__name ml-2">
          {journeyStep?.staffs && journeyStep?.staffs?.length > 0
            ? step.firstname +
            " " +
            step?.lastname
            : "Name not available"}
        </span> */}
      </div>)}
      {currentStaff && <StaffBasicDetails
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        staff={currentStaff}
        setCurrentStaff={setCurrentStaff}
      />}
    </div>
  );
};

export default JourneyEventCard;
