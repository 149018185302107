import React, {FC} from "react";
import {Button} from "antd";
import './buttonComponent.scss';

interface ButtonComponentProps{
    loading?: boolean;
    className?: string;
    type?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
    text?: string;
    htmlType?: "button" | "submit" | "reset" ;
    onClick?:()=>void;
    disabled?:boolean;
    iconClass?:string;
}

const ButtonComponent:FC<ButtonComponentProps> = (props) => {

    const {text, iconClass} = props;

    return(
        <Button {...props}>
            {iconClass && <span className={iconClass+" btn-icon"}/>} {text}
        </Button>
    )
}

export default ButtonComponent;
