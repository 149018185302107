import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import "./chatInput.scss"
import { Input, Spin } from "antd";
import { PaperClipOutlined, SendOutlined, LoadingOutlined } from "@ant-design/icons";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import { Journey } from "../../../../models/Journey/journey.model";
import ChatService from "../../../../services/ChatService/chat.service";
import { ChatMessagesModel } from "../../../../models/Chat/chat.model";
import { urlRegex } from "../../../../shared/utils/convertTextToUrl";
import { NotificationTypes } from '../../../../enums/notificationTypes';
import Notification from '../../../../shared/components/Notification';

interface ChatInputProps {
    currentChat: Journey;
    scrollToBottom: Function;
}

const ChatInput: FC<ChatInputProps> = (props) => {
    const { currentChat, scrollToBottom } = props;

    const { createMessage, buttonLoading } = ChatService();

    const fileInputRef = useRef<HTMLInputElement>();

    const [currentAttachment, setCurrentAttachment] = useState<File | undefined>();
    const [currentText, setCurrentText] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false)
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files?.length) {
            pickerFilesRemove()
            return
        }

        if (event?.target?.files[0].size >= 15000000) {
            pickerFilesRemove()
            Notification({
                message: "",
                description: "File size should not exceed 15 MB",
                type: NotificationTypes.ERROR,
            });
            return
        }

        setCurrentAttachment(event?.target?.files[0])
    }

    const pickerFilesRemove = () => {
        if (fileInputRef?.current)
            fileInputRef.current.value = ''
    }

    const handleFileClear = () => {
        if (fileInputRef?.current) fileInputRef.current.value = '';
        setCurrentAttachment(undefined)
    }
    useEffect(() => {
        setSending(buttonLoading)
        scrollToBottom();
    },[buttonLoading])

    const handleSendMessage = () => {
        const message = currentText;
        const attachment = currentAttachment;
        setCurrentText('');
        setCurrentAttachment(undefined);
        const messageType = attachment?.type?.includes('image') ? 'image' :
            attachment?.type?.includes('pdf') ? 'document' :
            attachment?.type?.includes('video') ? "video" :
                urlRegex.test(message) ? 'link' : 'text';

        const data: ChatMessagesModel = {
            message: message,
            attachment: attachment,
            messageType: messageType
        };

        if ((message || attachment) && currentChat?.id) {
            createMessage(currentChat?.id, data).then(() => {
                handleFileClear();
            })
        }
    }

    return (
        <div className={buttonLoading? "message-loading chat-input" : "chat-input"}>
            {currentAttachment &&
                <div className={'chat-input__attachment__preview'}>
                    <div className={"show-selected-image__remove"}
                        onClick={handleFileClear}
                    >
                        x
                    </div>
                    {currentAttachment.type.includes('image') ?
                        <img src={window.URL.createObjectURL(currentAttachment)}
                            className={"attachment-preview"}
                            alt={''}
                        /> :
                    currentAttachment.type.includes('video')?
                    <video width='20%'> <source src={window.URL.createObjectURL(currentAttachment)} type="video/mp4" />
            </video>
                    :
                        <iframe src={window.URL.createObjectURL(currentAttachment)}
                            className={"attachment-preview"}
                        />
                    }
                </div>
            }
            <Input key={"chat-input"}
                className={"chat-input__field"}
                placeholder={"Type a new message..."}
                value={currentText}
                onChange={({ target: { value } }) => { setCurrentText(value) }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSendMessage()
                }}
                prefix={
                    <div className={"chat-input__attachments__trigger"}>
                        <input type={"file"}
                            onChange={handleFileChange}
                            className={"chat-input__attachments__input"}
                            ref={fileInputRef as any}
                            accept="application/pdf, image/*, video/*"
                        />
                        <PaperClipOutlined className={"chat-input__icon"} />
                    </div>
                }
                suffix={
                        <SendOutlined className={`chat-input__icon ${!!currentText || !!currentAttachment}`}
                        onClick={handleSendMessage}
                    />
                }
            />
        {sending && 
        <div className='sending-loader'>
          <span><Spin indicator={antIcon} />Sending...</span>
          </div>
        }
        </div>
    )
}

export default ChatInput;