import React, { FC, useEffect, useState } from 'react'
import "./staffBasicDetails.scss"
import { Modal } from "antd";
import { validationSchema } from "./ChangePasswordValidation";
import { Form, Formik, FormikValues } from "formik";
import InputField from "../InputField";
import ButtonComponent from "../ButtonComponent";
import DpChip from '../DpChip';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import UserService from '../../../services/AuthService/auth.service';
import { getFullName } from '../../utils/dataFormatConverter';

interface StaffBasicDetailsProps {
    showDetails: boolean;
    setShowDetails: Function;
    staff: any;
    setCurrentStaff: Function;
    enablePasswordReset?: boolean;
}

const StaffBasicDetails: FC<StaffBasicDetailsProps> = (props) => {
    const { showDetails, setShowDetails, staff, setCurrentStaff, enablePasswordReset } = props;

    const initialValue = {
        password: "",
        newPassword: "",
        confirmPassword: ""
    }

    const { changePassword } = UserService()

    const [showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {
        !showDetails && setShowForm(false);
    }, [showDetails])

    const onSubmit = async (data: FormikValues) => {
        const values = { ...data }
        // values['new_password'] = values['newPassword']
        const response = await changePassword(values)
        response && setShowDetails(false)
    };


    return (
        <div className="staff-basic-details">
            <Modal title={null}
                visible={showDetails}
                onCancel={(e) => {
                    e.stopPropagation()
                    setShowDetails(false);
                    setCurrentStaff({});
                }}
                footer={null}
                maskClosable = {false}
                destroyOnClose

                className="staff-basic-details__modal"
            >
                <div onClick={e => e.stopPropagation()} className={`staff-basic-details__row ${showForm ? 'hide-border-radius' : ''}`}>
                    <div className="staff-basic-details__column image-column">
                        {/* <img src={ userPlaceholder} alt={'profile-'}/> */}
                        <DpChip name={staff?.name}
                            url={staff?.displayPictureUrl} 
                            userId={staff?.id}/>
                    </div>
                    <div className="staff-basic-details__column ">
                        <div className="staff-basic-details__name text-capitalize">{staff?.title} {staff?.name}</div>
                        <div className="staff-basic-details__speciality text-capitalize">{staff?.specialities?.map((o: any) => o.label).join(', ')}</div>
                        <div className="staff-basic-details__email"
                            onClick={(e) => {
                                window.location.href = `mailto:${staff?.email}`;
                                e.preventDefault();
                            }}
                        >
                            {staff?.email}
                        </div>
                        <div className="staff-basic-details__phone">
                            {formatPhoneNumberIntl((staff?.isdCode ? staff.isdCode : "") + (staff.phone || "") + (!isNaN(parseInt(staff.extCode || "")) ? "#" + staff.extCode : ""))}
                        </div>
                        <div className="staff-basic-details__link">
                            <a href={staff?.link} target="_blank">
                                {staff?.link}
                            </a>
                        </div>
                    </div>
                    {enablePasswordReset && <div className="staff-basic-details__action"
                        onClick={() => setShowForm(!showForm)}
                    >
                        {showForm ? 'Hide Form' : 'Change password'}
                    </div>}
                </div>
                <div className={`staff-basic-details__form ${showForm ? 'show-form' : ''}`}>
                    <Formik
                        initialValues={initialValue}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form className="staff-basic-details__password">
                            <div className="auth-form__header">
                                Change Password
                            </div>
                            <div className="auth-form__field">
                                <div className="form-label">Current password</div>
                                <InputField type="password" name="password" placeholder="Enter your current password" />
                            </div>
                            <div className="auth-form__field">
                                <div className="form-label">New password</div>
                                <InputField type="password" name="newPassword" placeholder="Enter your new password" />
                            </div>
                            <div className="auth-form__field">
                                <div className="form-label">Confirm New password</div>
                                <InputField type="password" name="confirmPassword" placeholder="Enter your new password again" />
                            </div>
                            <div className="staff-basic-details__form-actions">
                                <ButtonComponent loading={false}
                                    className="auth-form__action"
                                    type="text"
                                    text="Cancel"
                                    onClick={() => {
                                        setShowDetails(false)
                                        setCurrentStaff({})
                                    }}
                                />
                                <ButtonComponent htmlType="submit"
                                    loading={false}
                                    className="auth-form__action"
                                    type="primary"
                                    text="Update"
                                />
                            </div>
                        </Form>
                    </Formik>
                </div>
            </Modal>
        </div>
    )
}

export default StaffBasicDetails;