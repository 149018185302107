import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { User, UserDisplayPicture } from "../../models/user.model";
import { store } from "../../store";
import {AUTHENTICATED, UNAUTHENTICATED} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import {JOURNEYS, LOGIN, PATIENTS} from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { generatePath } from "react-router-dom";

const UserService = () => {
	const history = useHistory();

	const [user, setUser] = useState<User>();

	const [displayPictureUrl, setDisplayPictureUrl] = useState<string>()

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const loginUser = (data: User) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.USER_LOGIN, data)
			.then((response:any) => {
				const userDetails = deserialize(User, response.data);
				const accessToken = response.data["access_token"];
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});

				if(!userDetails?.title) userDetails.title = "";
				if(userDetails) localStorage.setItem("user",JSON.stringify(userDetails));
				localStorage.setItem("access-token",accessToken ?? '');

				Notification({
					message: "Login",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				setUser(userDetails);
				setLoading(false);
				userDetails?.role === 'Hospital Admin' ? history.push(PATIENTS) : history.push(JOURNEYS)
			})
			.catch((error) => {
				setError(error);
				setLoading(false);
			})
			.finally(() => {});
	};

	const logoutUser = () => {
		setLoading(true);
		return axiosInstance
			.delete(ApiRoutes.USER_LOGOUT)
			.then((response) => {
				store.dispatch({
					type: UNAUTHENTICATED,
					payload: {
						authenticated: false,
						user: {},
					},
				});

				localStorage.removeItem("access-token");
				localStorage.removeItem("user");

				Notification({
					message: "Logout",
					description: "Logged out successfully",
					type: NotificationTypes.SUCCESS,
				});
				history.push(LOGIN);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPassword = (data: any) => {
		setLoading(true);
		return axiosInstance
			.put(ApiRoutes.FORGOT_PASSWORD, data)
			.then((response) => {
				Notification({
					message: response.data.valid? "Success" : 'Failure',
					description: response.data.valid? "You will receive a password reset link to the submitted email address!" : "No user exists with this email!",
					type: response.data.valid? NotificationTypes.SUCCESS: NotificationTypes.ERROR,
				});
			}).catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getUserDisplayPicture = (employeeId:number) =>{
		setLoading(true);
		return axiosInstance
			.get(generatePath(ApiRoutes.EMPLOYEE,{id: employeeId.toString()}))
			.then(res => {
				const response = deserialize(UserDisplayPicture,res.data);
				setDisplayPictureUrl(response.displayPictureUrl);
			})
	}

	const resetPassword = (data: any) => {
		setLoading(true);
		return axiosInstance
			.put(ApiRoutes.RESET_PASSWORD, data)
			.then((response) => {
				Notification({
					message: "Success",
					description: "Password has been reset successfully",
					type: NotificationTypes.SUCCESS,
				});
				history.push(LOGIN)
			}).catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const changePassword = (data: any) => {
		setLoading(true);
		const passwordData = serialize(User, data);
		return axiosInstance
			.put(ApiRoutes.CHANGE_PASSWORD, passwordData)
			.then((response) => {
				Notification({
					message: "Success",
					description: "Password has been changed successfully",
					type: NotificationTypes.SUCCESS,
				});
				localStorage.clear()
				store.dispatch({
					type: UNAUTHENTICATED,
					payload: {
						authenticated: false,
						user: {},
					},
				});
				return true
			}).catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return {
		user,
		error,
		loading,
		displayPictureUrl,
		loginUser,
		logoutUser,
		forgotPassword,
		getUserDisplayPicture,
		resetPassword,
		changePassword
	};
};

export default UserService;
