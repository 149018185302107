import React, {FC, useEffect, useState} from 'react'
import "./chatAttachments.scss"
import {Journey} from "../../../../../models/Journey/journey.model";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ChatService from "../../../../../services/ChatService/chat.service";
import {ChatMessagesModel} from "../../../../../models/Chat/chat.model";
import SpinnerLoader from "../../../../../shared/components/SpinnerLoader";
import AttachmentViewer from '../../../../../shared/components/AttachmentViewer';
import { convertTextToUrl } from '../../../../../shared/utils/convertTextToUrl';

interface ChatAttachmentsProps {
    currentView: string;
    chat: Journey;
}

const ChatAttachments: FC<ChatAttachmentsProps> = ({currentView, chat}) => {

    const { messagesLoading, currentPage, totalItems, fetchMessages} = ChatService();

    const [filteredMessages,setFilteredMessages] = useState<ChatMessagesModel[]>([]);

    const attachmentPerPage = 15;

    useEffect(()=>{
        setFilteredMessages([])
        if(chat?.id)
            fetchMessages(chat.id,{message_type:currentView,page:1,page_size:attachmentPerPage})
                .then((response)=>{
                    response &&
                    setFilteredMessages([...response]);
                });
    },[currentView])

    const loadMore = () => {
        if(chat?.id)
            fetchMessages(chat.id,{message_type:currentView,page:currentPage+1,page_size:attachmentPerPage})
            .then((response)=>{
                response &&
                setFilteredMessages(filteredMessages?.length ? [...filteredMessages,...response] : [...response]);
            });
    }

    const hasNextPage = () => currentPage*attachmentPerPage < totalItems

    const [sentryRef,{ rootRef }] = useInfiniteScroll({
        loading: messagesLoading,
        hasNextPage: hasNextPage(),
        onLoadMore: loadMore,
        disabled: false,
        rootMargin: '0px 0px 20px 0px',
    });

    return (
        <div className="chat-attachments">
            <div className="chat-attachments__container" ref={rootRef}>
                {/* {filteredMessages?.map((message,i) =>
                    message.messageType === 'link' ?
                        <div dangerouslySetInnerHTML={{__html:`<a href=${message.message} />`}} className={'attachment-links'}/>
                        :
                        <div className={"chat-info-drawer__card__latest-attachments info-drawer-attachments"} key={i}>
                            {message.messageType === 'image' &&
                                <>
                                    <a href={message.attachmentUrl} target={"_blank"}/>
                                    <img src={message.attachmentUrl}
                                         alt={''}
                                         className='attachment-card'
                                    />
                                    <div className='attachment-name'><p>{message.attachmentName}</p></div>
                                </>
                            }
                            {message.messageType === 'video' &&
                                <>
                                    <a href={message.attachmentUrl} target={"_blank"}/>
                                    <video src={message.attachmentUrl} preload="none" poster="https://upload.wikimedia.org/wikipedia/commons/4/46/Bear_Alaska_%283%29.jpg"
                                         className='attachment-card'
                                    />
                                    <div className='attachment-name'><p>{message.attachmentName}</p></div>
                                </>
                            }
                            {message.messageType === 'document' &&
                                <>
                                    <a href={message.attachmentUrl} target={"_blank"}/>
                                    <iframe src={message.attachmentUrl}
                                            className='attachment-card'
                                    />
                                    <div className='attachment-name'><p>{message.attachmentName}</p></div>
                                </>
                            }
                        </div>
                )} */}
                {filteredMessages?.map((message,i) => 
                message?.messageType !== 'link'? 
               (<AttachmentViewer
                showPreview = { true }
                attachmentName={message?.attachmentName}
                attachmentUrl={message?.attachmentUrl} 
                small = {true}
              />) : (<div
              dangerouslySetInnerHTML={{ __html:convertTextToUrl(message?.message)}}
            /> )
                )}
                {messagesLoading &&
                    <div ref={sentryRef}>
                        <SpinnerLoader/>
                    </div>
                }
            </div>
        </div>
    )
}

export default ChatAttachments;