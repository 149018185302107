import React, { FC, useEffect, useState } from "react";
import "./chatInfoDrawer.scss";
import { Drawer, Tabs } from "antd";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import surgeryPlaceholder from "../../../../assets/surgery.png";
import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import { Journey } from "../../../../models/Journey/journey.model";
import moment from "moment";
import ChatAttachments from "./ChatAttachments";
import ChatService from "../../../../services/ChatService/chat.service";
import { ChatMessagesModel } from "../../../../models/Chat/chat.model";
import { getAgeText } from "../../../../shared/utils/dateHelpers";
import { getFullName } from "../../../../shared/utils/dataFormatConverter";

const { TabPane } = Tabs;

interface ChatInfoDrawerProps {
  showDrawer: boolean;
  setShowDrawer: Function;
  currentChat: Journey;
}

const ChatInfoDrawer: FC<ChatInfoDrawerProps> = (props) => {
  const { showDrawer, setShowDrawer, currentChat } = props;

  const { fetchMessages } = ChatService();

  const [view, setView] = useState<string>("info");
  const [selectedKey, setSelectedKey] = useState<string>("image");
  const [latestImages, setLatestImages] = useState<ChatMessagesModel[]>([]);

  useEffect(() => {
    if (currentChat?.id && showDrawer)
      fetchMessages(currentChat.id, {
        message_type: "image",
        page: 1,
        page_size: 6,
      }).then((response) => {
        response && setLatestImages([...response]);
      });
  }, [currentChat, showDrawer]);

  return (
    <div className="chat-info-drawer">
      <Drawer
        title="Contact Info"
        placement="right"
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        destroyOnClose
        width={550}
        className={"chat-info-drawer__drawer"}
      >
        {view === "info" ? (
          <>
            <div className="chat-info-drawer__current-user">
              <img
                src={currentChat?.patient?.displayPictureUrl ?? userPlaceholder}
                alt={""}
              />
              <div className={"chat-info-drawer__current-user__name text-capitalize"}>
                {currentChat?.patient?.name}
              </div>
              <div className={"chat-info-drawer__current-user__info text-capitalize"}>
                {currentChat?.patient?.dob
                  ? getAgeText(currentChat?.patient?.dob)
                  : "-"}
                - {currentChat?.patient?.gender ?? "-"}
              </div>
            </div>
            <div className="chat-info-drawer__card">
              <div className={"chat-info-drawer__card__title"}>
                Group Members
              </div>
              <div className={"chat-info-drawer__group-member"}>
                <div className={"chat-info-drawer__group-member__image"}>
                  <img
                    src={
                      currentChat?.caretaker?.displayPictureUrl ??
                      userPlaceholder
                    }
                    alt={""}
                  />
                </div>
                <div className={"chat-info-drawer__group-member__info"}>
                  <div className={"chat-info-drawer__group-member__name"}>
                    {currentChat?.caretaker?.firstname}
                  </div>
                  <div className={"chat-info-drawer__group-member__role"}>
                    Parent
                  </div>
                </div>
              </div>
              {currentChat?.staffs?.map((staff) => (
                <div
                  className={"chat-info-drawer__group-member"}
                  key={staff?.id}
                >
                  <div className={"chat-info-drawer__group-member__image"}>
                    <img
                      src={staff?.displayPictureUrl ?? userPlaceholder}
                      alt={""}
                    />
                  </div>
                  <div className={"chat-info-drawer__group-member__info"}>
                    <div className={"chat-info-drawer__group-member__name"}>
                    {getFullName(staff)}
                    </div>
                    <div
                      className={
                        "chat-info-drawer__group-member__role text-capitalize"
                      }
                    >
                      {staff?.specialities?.map((o) => o.label).join(", ")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="chat-info-drawer__card">
              <div
                className={"chat-info-drawer__card__title"}
                onClick={() => setView("attachments")}
              >
                Images, Videos, Attachments, Links
                <RightOutlined />
              </div>
              {latestImages?.map((latestImage, i) => (
                <a
                  href={latestImage?.attachmentUrl}
                  target="_blank"
                  className={
                    "chat-info-drawer__card__latest-attachments latest-images"
                  }
                  key={i}
                >
                  <img
                    src={latestImage?.attachmentUrl}
                    alt={""}
                    className="attachment-card"
                  />
                </a>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="go-back" onClick={() => setView("info")}>
              <ArrowLeftOutlined />
            </div>
            <Tabs
              defaultActiveKey="image"
              onChange={(key: string) => setSelectedKey(key)}
              className={"chat-drawer-tabs"}  
            >
              <TabPane tab="Images" key="image">
                <ChatAttachments
                  currentView={selectedKey}
                  chat={currentChat}
                  key={"media-viewer"}
                />
              </TabPane>
              <TabPane tab="Videos" key="video">
                <ChatAttachments
                  currentView={selectedKey}
                  chat={currentChat}
                  key={"media-viewer"}
                />
              </TabPane>
              <TabPane tab="Documents" key="document">
                <ChatAttachments
                  currentView={selectedKey}
                  chat={currentChat}
                  key={"doc-viewer"}
                />
              </TabPane>
              <TabPane tab="Links" key="link">
                <ChatAttachments
                  currentView={selectedKey}
                  chat={currentChat}
                  key={"link-viewer"}
                />
              </TabPane>
            </Tabs>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default ChatInfoDrawer;
