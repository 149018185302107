import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getFileType } from "../../utils/dataFormatConverter";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "./attachmentViewer.scss";
import videothumbnail from "../../../assets/videothumbnail.png";
import { DownloadOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined, FundViewOutlined, VideoCameraOutlined } from "@ant-design/icons";
interface AttachmentViewerProps {
  attachmentUrl?: string;
  attachmentName?: string;
  showDeleteIcon?: boolean;
  showPreview?: boolean;
  small ?: boolean
  onDelete?: () => void;
}

function AttachmentViewer({
  attachmentUrl,
  attachmentName,
  showDeleteIcon,
  showPreview,
  onDelete,
  small
}: AttachmentViewerProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const history = useHistory();

  const [showAttachment, setShowAttachment] = useState(false);

  const [numPages, setNumPages] = useState(null);

  const [pdfUrl, setPdfUrl] = useState<string | ArrayBuffer | null>();

  const [pageNumber, setPageNumber] = useState(1);

  const handleShowModal = () => setShowAttachment(true);

  const [videoLoading, setVideoLoading] = useState<boolean>(true)
  const handleCloseModal = () => setShowAttachment(false);
  
  const fileType = getFileType(attachmentName as string);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  // useEffect(() => {
  //   if((attachmentName as string)?.length > 12){
  //     attachmentName = attachmentName?.substring(0,12);
  //   }
  // },[attachmentName])
  const AttachmentPreview = () => {
    switch(fileType) {
      case "image" : 
        return (
         (showPreview && attachmentName) ?(
          <div className="image-preview-box" >
             <img
              src={attachmentUrl}
              alt="Attachment preview"
              className={small? "attachment-viewer__preview-small": "attachment-viewer__preview"}
            />
          <div className="image__options">
            <span className="options__download"><a href={attachmentUrl+"&response-content-disposition=attachment"} target='_self' download><DownloadOutlined style={{fontSize: small? '24px': '45px'}}/></a></span>
            <span className="options__preview" onClick={handleShowModal}><FundViewOutlined style={{fontSize: small? "24px":'45px'}}/></span>
          </div>
        </div>
        ): (
          <div  className="attachment-box" onClick={handleShowModal}>
            <FileImageOutlined style={{fontSize: "20px"}} />
            <span className= "attachment-label">{attachmentName}</span>
          </div> )
        )
      case "video":
        return  (
          (showPreview && attachmentName) ?
        (<div  className="attachment-box" onClick={handleShowModal}>
         {videoLoading?<img src={videothumbnail} alt="video Thumbnail" width={120} />:<video height="100"> <source src={attachmentUrl} type="video/mp4" onLoad={() => setVideoLoading(false)}/> </video>}
        <div className= "attachment-label">{attachmentName}</div>
      </div>)
          :
          (
          <div  className="attachment-box" onClick={handleShowModal}>
            <VideoCameraOutlined style={{fontSize: "20px"}} />
            <span className= "attachment-label">{attachmentName}</span>
          </div> ) )
      case "document":
        return (<div  className="attachment-box" onClick={handleShowModal}> <FilePdfOutlined style={{fontSize: '20px'}} />{attachmentName}</div>)

      default : 
          return (<div className="attachment-box" onClick={handleShowModal}> <FileOutlined style={{fontSize: '20px'}} /><span className= "attachment-label">{attachmentName}</span></div>)
    }
  }

  return (
    <div className="attachment-viewer">
      <div className="attachment-viewer__label" >
        <AttachmentPreview />
      </div>
      {history?.location?.state !== "" && showDeleteIcon && (
        <div className="remove-icon" onClick={onDelete}>
          x
        </div>
      )}
      <Modal
        title={null}
        footer={null}
        width="80%"
        visible={showAttachment}
        maskClosable={false}
        onCancel={handleCloseModal}
        className="attachment-viewer-modal"
      >
        {attachmentName && getFileType(attachmentName) === "image" ? (
          <img src={attachmentUrl} alt="Attachment" />
        ) : (
          attachmentName && getFileType(attachmentName) === 'video' ? <video height="600" controls> <source src={attachmentUrl} type="video/mp4" />
            </video>: <iframe src={attachmentUrl} />
        )}
      </Modal>
    </div>
  );
}

export default AttachmentViewer;
