import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./hospitalDetails.scss";
import { useParams } from "react-router-dom";
import HospitalForm from "../HospitalForm";
import { useHistory } from "react-router";
import HospitalService from "../../../services/HospitalService/hospital.service";
import HospitalInfoTabs from "./HospitalInfoTabs";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import SpinnerLoader from "../../../shared/components/SpinnerLoader";
import GoBack from "../../../shared/components/GoBack";

const HospitalDetails = () => {
  const { id } = useParams();

  const history = useHistory();

  const { tableLoading, showHospital, hospital } = HospitalService();

  const [showOverflow, setShowOverflow] = useState<boolean>(false);
  const [showHospitalForm, setShowHospitalForm] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (descriptionRef.current){
      descriptionRef.current.innerHTML = hospital?.bio?.replace(/\n/gm, '<br />') || "";
      descriptionRef.current.innerHTML = hospital?.bio?.replace(/<p>&nbsp;<\/p>/i, ' ') || "";
      descriptionRef.current.innerHTML = hospital?.bio?.replace(/<br>/gm, ' ') || "";
  }})
  }, [hospital?.bio, descriptionRef.current])

  useEffect(() => {
    showHospital(id).then();
  }, []);

  useEffect(() => {
    setShowOverflow(!!hospital?.bio && hospital?.bio?.length > 90);
  }, [hospital]);

  if (tableLoading)
    return <div className="app-wrapper hospital-details">
      <SpinnerLoader />
    </div>

  return (
    <div className="app-wrapper hospital-details">
      <HospitalForm
        showForm={showHospitalForm}
        setShowForm={setShowHospitalForm}
        hospital={hospital}
        refreshHospital={showHospital}
      />

      <div className="hospital-details__basic-info">
        <div className="hospital-details__navigation">
          <GoBack />
          <div className="hospital-details__name text-capitalize">
            {hospital?.name}
          </div>
        </div>
        <div className="hospital-details__bio">
          <span
            ref={descriptionRef}
            className={showOverflow ? "text-overflow" : ""} />
          {hospital?.bio && hospital?.bio?.length > 90 && (
            <span
              className="view-more"
              onClick={() => setShowOverflow(!showOverflow)}
            >
              {showOverflow ? "View more" : "View less"}
            </span>
          )}
        </div>
        <div className="hospital-details__website">
          <a href={hospital?.website} target="_blank">
            {hospital?.website}
          </a>
        </div>
        <div className="hospital-details__communication">
          <div className="hospital-details__contact">
            <span className="hospital-details__contact-icon">
              <i className="icon-email" />
            </span>
            <span
              className="hospital-details__contact-info cursor-pointer"
              onClick={(e) => {
                window.location.href = `mailto:${hospital?.email}`;
                e.preventDefault();
              }}
            >
              {hospital?.email}
            </span>
          </div>
          <div className="hospital-details__contact">
            <span className="hospital-details__contact-icon">
              <i className="icon-mobile" />
            </span>
            <span className="hospital-details__contact-info">
              {formatPhoneNumberIntl((hospital?.isdCode ? hospital.isdCode : "") + hospital.phone + (hospital.extCode ? "#" + hospital.extCode : ""))}
            </span>
          </div>
          {/* <div className="hospital-details__contact">
            <span className="hospital-details__contact-icon">
              <i className="icon-employees" />
            </span>
            <span className="hospital-details__contact-info">
              {hospital?.noOfEmployees} Employees
            </span>
          </div> */}
          <div className="hospital-details__contact">
            <span className="hospital-details__contact-icon">
              <i className="icon-location" />
            </span>
            <span className="hospital-details__contact-info text-capitalize">
              {hospital?.location}
            </span>
          </div>
        </div>
        <span
          className="hospital-details__edit"
          onClick={() => setShowHospitalForm(true)}
        >
          <i className="icon-edit-1" />
        </span>
      </div>

      <div className="hospital-details__staffs">
        <HospitalInfoTabs />
      </div>
    </div>
  );
};

export default HospitalDetails;
