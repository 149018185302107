import * as Yup from "yup";

export const caretakerValidationSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('First name is required!'),
    lastname: Yup.string()
        .required('Last name is required!'),
    email: Yup.string()
        .email('E-mail is not valid!')
        .required('E-mail is required!'),
    phone: Yup.string()
        .required('Contact number is required!')
        .min(6, "Enter a valid Phone Number!"),
});