import React, { FC, useEffect, useRef, useState } from "react";
import "./journeyTemplate.scss";
import AppHeader from "../../shared/components/AppHeader";
import TableComponent from "../../shared/components/TableComponent";
import { generatePath, useLocation } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import { Dropdown, Menu, Modal, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import JourneyTemplateService from "../../services/JourneyTemplateService/journeyTemplate.service";
import moment from "moment";
import { JourneyTemplate } from "../../models/JourneyTemplate/journeyTemplate.model";

interface JourneyTemplateProps { }

const JourneyTemplateList: FC<JourneyTemplateProps> = (props) => {
  const history = useHistory();
  const location = useLocation<{currentPage: string,search: string}>();
  const page = parseInt(location?.state?.currentPage) || 1;
  const search = (location?.state?.search) || "";
  const [journeyTemplate, setJourneyTemplate] = useState<any>({});
  const rootRef = useRef<null | HTMLDivElement>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>(search);

  const {
    journeyTemplateList,
    fetchJourneyTemplates,
    tableLoading,
    totalItems,
    deleteJourneyTemplate,
    duplicateJourneyTemplate,
    buttonLoading,
  } = JourneyTemplateService();

  useEffect(() => {
    fetchJourneyTemplates(searchText, page, 10);
    rootRef!.current!.scrollIntoView({
      behavior :'smooth'
    });
  }, [location]);

  const statusMeta = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const columns = [
    {
      title: "PLAN NAME",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => <span className="text-capitalize">{record?.name}</span>
    },
    // {
    //   title: "NO OF WEEKS",
    //   dataIndex: "noOfWeeks",
    //   key: "noOfWeeks",
    // },
    // {
    //   title: "NO OF STEPS",
    //   dataIndex: "noOfSteps",
    //   key: "noOfSteps",
    // },
    {
      title: "CREATED ON",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <span>{moment(text).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => (
        <Tag
          key={record?.id}
          className={`app-table-tags ${record?.status ? "green" : "red"}`}
        >
          {record?.status ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: null,
      key: "action",
      render: (text: string, record: JourneyTemplate) => (
        <div onClick={(event) => event.stopPropagation()}>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            trigger={["click"]}
            className="manage-groups__details--action"
            onVisibleChange={(visible) => {
              setJourneyTemplate(visible ? record : {});
            }}
          >
            <EllipsisOutlined className="table-menu" />
          </Dropdown>
        </div>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          history.push(
            generatePath(appRoutes.SHOW_JOURNEY_TEMPLATES, {
              id: journeyTemplate?.id,
            }),
            "edit"
          )
        }
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          Modal.confirm({
            icon: null,
            width: "40%",
            className: "ant-confirm-modal",
            title: "Duplicate Journey Template",
            content: <div>Do you wish to duplicate this template?</div>,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              duplicateJourneyTemplate(journeyTemplate?.id).then(() =>
                fetchJourneyTemplates()
              );
            },
            onCancel: () => { },
          });
        }}
      >
        Duplicate
      </Menu.Item>
    </Menu>
  );

  const handleJourneyTemplateSearch = (searchText: string) => {
     history.push({pathname:appRoutes.JOURNEY_TEMPLATES, search: `?page=${1}&search=${searchText}`, state:{currentPage:1, search: searchText }} );
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    history.push({pathname:appRoutes.JOURNEY_TEMPLATES, search: `?page=${page}${search !== ""? `&search=${search}`: ''}`, state:{currentPage:page, search: search }});

  };

  return (
    <div className="app-wrapper journey-template" ref={rootRef}>
      <AppHeader
        iconClassName="icon-journey-template"
        title="Plans"
        isSearchEnabled={true}
        buttonText="Journey Template"
        buttonHandler={() => {
          setJourneyTemplate({});
          history.push(
            generatePath(appRoutes.SHOW_JOURNEY_TEMPLATES, { id: "new" }),
            "create"
          );
        }}
        searchValue={searchText}
        setSearchValue = {setSearchText}
        handleSearch={handleJourneyTemplateSearch}
        showGoBack={false}
      />

      <TableComponent
        columns={columns}
        data={journeyTemplateList}
        loading={tableLoading}
        handleRowClick={(record: JourneyTemplate) =>
          history.push(
            generatePath(appRoutes.SHOW_JOURNEY_TEMPLATES, { id: record?.id }),{currentPage: page, search: search}
          )
        }
        currentPage={page}
        total={totalItems}
        onPageChange={handlePageChange}
      />

      <DeleteConfirmation
        handleDelete={() => deleteJourneyTemplate(journeyTemplate?.id)}
        setShowConfirmation={setShowDeleteConfirmation}
        showConfirmation={showDeleteConfirmation}
        entityName={"Journey template"}
        buttonLoading={buttonLoading}
        key="delete-confirmation"
        refresh={fetchJourneyTemplates}
      />
    </div>
  );
};

export default JourneyTemplateList;
