import { useState } from "react";
import { Journey } from "../../models/Journey/journey.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { ChatMessagesModel } from "../../models/Chat/chat.model";
import { JourneyTemplateStep } from "../../models/JourneyTemplate/journeyTemplateStep.model";

const CalendarService = () => {
  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);

  const [calendarEvents, setCalendarEvents] = useState<JourneyTemplateStep[]>(
    []
  );

  const fetchCalendarEvents = (queryParams: any = {}) => {
    setCalendarLoading(true);

    let apiUrl =
      ApiRoutes.CALENDAR_EVENTS +
      `?${Object.keys(queryParams)
        .map((o) => o + "=" + queryParams[o])
        .join("&")}`;

    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const calendarEvents: any = deserialize(
          JourneyTemplateStep,
          response.data
        );
        calendarEvents && setCalendarEvents([...calendarEvents]);
      })
      .catch((error) => {})
      .finally(() => {
        setCalendarLoading(false);
      });
  };

  return {
    calendarLoading,
    fetchCalendarEvents,
    calendarEvents,
  };
};

export default CalendarService;
