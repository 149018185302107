import { useState } from "react";
import { JourneyTemplate } from "../../models/JourneyTemplate/journeyTemplate.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { generatePath } from "react-router-dom";

const JourneyTemplateService = () => {
  const [journeyTemplate, setJourneyTemplate] = useState<JourneyTemplate>(
    new JourneyTemplate()
  );

  const [journeyTemplateList, setJourneyTemplateList] = useState<
    Array<JourneyTemplate>
  >([]);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [journeyLoading, setJourneyLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [totalItems, setTotalItems] = useState<number>(0);

  const fetchJourneyTemplates = (
    search?: string,
    page?: number,
    pageSize?: number,
    isMeta?: boolean
  ) => {
    setTableLoading(true);
    let apiUrl =
      ApiRoutes.JOURNEY_TEMPLATES +
      `?${search ? `search=${search}&` : ""}${(page && !isMeta) ? `page=${page}&page_size=${pageSize}` : ""
      }${isMeta? '&metadata=metadata': ""}`;
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyTemplates: any = deserialize(
          JourneyTemplate,
          isMeta? response.data: response.data["results"]
        );
        const paginationData = deserialize(PaginationModel, response.data);
        setJourneyTemplateList(journeyTemplates);
        setCurrentPage(paginationData?.page);
        setTotalItems(paginationData?.total);
      })
      .catch((error) => { })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const showJourneyTemplate = (id?: string) => {
    setJourneyLoading(true);
    return axiosInstance
      .get(ApiRoutes.JOURNEY_TEMPLATES + `/${id}`)
      .then((response) => {
        const journeyTemplate = deserialize(JourneyTemplate, response.data);
        setJourneyTemplate(journeyTemplate);
      })
      .catch((error) => { })
      .finally(() => {
        setJourneyLoading(false);
      });
  };

  const createJourneyTemplate = (data: JourneyTemplate) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplate, data);
    return axiosInstance
      .post(ApiRoutes.JOURNEY_TEMPLATES, serializedData)
      .then((response) => {
        const journeyTemplate = deserialize(JourneyTemplate, response.data);
        setJourneyTemplateList([...journeyTemplateList, journeyTemplate]);
        setJourneyTemplate(journeyTemplate);
        Notification({
          message: "Success",
          description: "Journey Template has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        return journeyTemplate
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const updateJourneyTemplate = (data: JourneyTemplate, id?: number) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplate, data);
    return axiosInstance
      .put(ApiRoutes.JOURNEY_TEMPLATES + `/${id}`, serializedData)
      .then((response) => {
        const journeyTemplate = deserialize(JourneyTemplate, response.data);
        setJourneyTemplate(journeyTemplate);
        Notification({
          message: "Success",
          description: "Journey template has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const deleteJourneyTemplate = (id?: string) => {
    setButtonLoading(true);
    return axiosInstance
      .delete(ApiRoutes.JOURNEY_TEMPLATES + `/${id}`)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey template has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const duplicateJourneyTemplate = (id?: string) => {
    setButtonLoading(true);
    return axiosInstance
      .post(generatePath(ApiRoutes.JOURNEY_TEMPLATE_DUPLICATE, { id }), {})
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey template has been duplicated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const createJourneyTemplateFromJourney = (data: { journey_id: string }) => {
    setButtonLoading(true);
    return axiosInstance
      .post(ApiRoutes.JOURNEY_TEMPLATES + `/auto_create`, data)
      .then((response) => {
        const journeyTemplate = deserialize(JourneyTemplate, response.data);
        setJourneyTemplateList([...journeyTemplateList, journeyTemplate]);
        setJourneyTemplate(journeyTemplate);
        Notification({
          message: "Success",
          description: "Journey template has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return {
    journeyTemplate,
    journeyTemplateList,
    tableLoading,
    buttonLoading,
    fetchJourneyTemplates,
    createJourneyTemplate,
    deleteJourneyTemplate,
    showJourneyTemplate,
    updateJourneyTemplate,
    currentPage,
    totalItems,
    createJourneyTemplateFromJourney,
    duplicateJourneyTemplate,
    journeyLoading,
  };
};

export default JourneyTemplateService;
