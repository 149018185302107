import React, { FC, useEffect, useState } from "react";
import "./journeyTemplateCarouselForm.scss";
//@ts-ignore
import ItemsCarousel from "react-items-carousel";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import { Input } from "antd";
import JourneyTemplateAttachmentService from "../../../../../../services/JourneyTemplateService/journeyTemplateAttachment.service";
import { JourneyTemplateAttachment } from "../../../../../../models/JourneyTemplate/journeyTemplateAttachment.model";
import DeleteConfirmation from "../../../../../../shared/components/DeleteConfirmation";
import JourneyStepAttachmentService from "../../../../../../services/JourneyService/journeyStepAttachment.service";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";

const { TextArea } = Input;

interface JourneyTemplateCarouselFormProps {
  step?: JourneyTemplateStep;
  showCarousel: boolean;
  setShowCarousel: Function;
  customizeJourney?: boolean;
}

const JourneyTemplateCarouselForm: FC<JourneyTemplateCarouselFormProps> = (
  props
) => {
  const { step, showCarousel, setShowCarousel, customizeJourney } = props;

  const {
    journeyTemplateAttachmentList,
    journeyTemplateAttachment,
    fetchJourneyTemplateAttachments,
    createJourneyTemplateAttachment,
    deleteJourneyTemplateAttachment,
    buttonLoading,
    updateJourneyTemplateAttachment,
  } = JourneyTemplateAttachmentService();

  const {
    journeyStepAttachmentList,
    journeyStepAttachment,
    fetchJourneyStepAttachments,
    createJourneyStepAttachment,
    deleteJourneyStepAttachment,
    journeyAttachmentButtonLoading,
    updateJourneyStepAttachment,
  } = JourneyStepAttachmentService();

  const [currentImage, setCurrentImage] = useState<any>(null);
  const [currentDescription, setCurrentDescription] = useState<string>();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [carouselList, setCarouselList] = useState<
    Array<JourneyTemplateAttachment>
  >([]);

  useEffect(() => {
    setCurrentImage("");
    setCurrentDescription("");
    if (step?.id)
      customizeJourney
        ? fetchJourneyStepAttachments(step?.id, "carousel")
        : fetchJourneyTemplateAttachments(step?.id, "carousel");
    else setShowCarousel(false);
  }, [step]);

  useEffect(() => {
    setShowCarousel(
      customizeJourney
        ? journeyStepAttachmentList.length > 0
        : journeyTemplateAttachmentList.length > 0
    );
  }, [journeyStepAttachmentList, journeyTemplateAttachmentList]);

  useEffect(() => {
    setCarouselList(
      customizeJourney
        ? [...journeyStepAttachmentList]
        : [...journeyTemplateAttachmentList]
    );
  }, [journeyStepAttachmentList, journeyTemplateAttachmentList]);

  const handleFileSelection = (event: any) => {
    if (event?.target?.files?.length) {
      const data: JourneyTemplateAttachment = {
        attachment: event?.target?.files[0],
        [customizeJourney ? "stepAttachmentType" : "templateAttachmentType"]:
          "carousel",
      };
      customizeJourney
        ? createJourneyStepAttachment(data, step?.id)
        : createJourneyTemplateAttachment(data, step?.id);
    }
  };

  const handleDescriptionChange = (event: any, attachmentId?: number) => {
    const data = {
      description: event?.target?.value,
    };
    customizeJourney
      ? updateJourneyStepAttachment(step?.id, attachmentId, data)
      : updateJourneyTemplateAttachment(step?.id, attachmentId, data);
  };

  const handleDeleteSuccess = () => {
    setCurrentImage(null);
    setCurrentDescription("");
  };

  return (
    <div className="journey-template-carousel-form">
      {showCarousel && (
        <>
          <DeleteConfirmation
            showConfirmation={showDelete}
            setShowConfirmation={setShowDelete}
            entityName={"carousel image"}
            handleDelete={() =>
              customizeJourney
                ? deleteJourneyStepAttachment(step?.id, currentImage?.id).then(
                  () => handleDeleteSuccess()
                )
                : deleteJourneyTemplateAttachment(
                  step?.id,
                  currentImage?.id
                ).then(() => handleDeleteSuccess())
            }
            buttonLoading={
              customizeJourney ? journeyAttachmentButtonLoading : buttonLoading
            }
            refresh={() =>
              customizeJourney
                ? fetchJourneyStepAttachments(step?.id, "carousel")
                : fetchJourneyTemplateAttachments(step?.id, "carousel")
            }
          />
          <div className="journey-template-step__label">Carousel</div>
          <div className="journey-template-carousel-form__images">
            <div className="carousel__add-image">
              <Input
                type={"file"}
                onChange={handleFileSelection}
                className="journey-template-carousels__image-input"
                accept="image/png, image/gif, image/jpeg"
              />
              <ButtonComponent
                text={"+"}
                type={"text"}
                loading={false}
                className="journey-template-carousels__add"
                onClick={() => { }}
              />
            </div>
            {carouselList?.map(
              (attachment: JourneyTemplateAttachment, i: number) => (
                <div
                  className="journey-template-carousel-form__image"
                  onClick={() => {
                    setCurrentImage(attachment);
                    setCurrentDescription(attachment?.description);
                  }}
                  key={i}
                >
                  <img src={attachment?.attachmentUrl} alt="" />
                  <div
                    className="carousel-image-remove"
                    onClick={(event) => {
                      event.stopPropagation();
                      setCurrentImage(attachment);
                      setCurrentDescription(attachment?.description);
                      setShowDelete(true);
                    }}
                  >
                    X
                  </div>
                </div>
              )
            )}
          </div>

          {currentImage && (
            <div className="journey-template-carousel-form__field">
              <TextArea
                className="journey-template-carousel-form__description"
                rows={2}
                onBlur={(event) => {
                  handleDescriptionChange(event, currentImage?.id);
                }}
                value={currentDescription}
                onChange={(event) =>
                  setCurrentDescription(event?.target?.value)
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JourneyTemplateCarouselForm;
