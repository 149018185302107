import { serializable, alias, primitive, list, object } from "serializr";
import { ArticleModel } from "../Article/article.model";
import { Staff } from "../Hospital/staff.model";
import { Journey } from "../Journey/journey.model";

export class JourneyTemplateStepEntityModel {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("class_name", primitive()))
  className?: string;
}

export class JourneyTemplateStepEntityListModel {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("link", object(JourneyTemplateStepEntityModel)))
  link?: JourneyTemplateStepEntityModel;
}

export class JourneyTemplateStep {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  key?: number;

  @serializable(alias("name", primitive()))
  title?: string;

  @serializable(alias("is_completed", primitive()))
  isCompleted?: boolean;

  @serializable(alias("day", primitive()))
  scheduleDays?: number;

  @serializable(alias("day_type", primitive()))
  schedule?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("journey_template_id", primitive()))
  journeyTemplateId?: number;

  @serializable(alias("journey", object(Journey)))
  journey?: Journey;

  @serializable(alias("journey_template_category_id", primitive()))
  journeyTemplateCategoryId?: number;

  @serializable(alias("journey_category_id", primitive()))
  journeyCategoryId?: number;

  @serializable(alias("journey_id", primitive()))
  journeyId?: number;

  @serializable(alias("employee_ids", list(primitive())))
  staffIds?: Array<string>;

  @serializable(alias("employees", list(object(Staff))))
  staffs?: Array<Staff>;

  @serializable(alias("notes", primitive()))
  doctorNotes?: string;

  @serializable(alias("step_time", primitive()))
  stepTime?: string;

  @serializable(alias("step_end_time", primitive()))
  stepEndTime?: string;

  @serializable(alias("order", primitive()))
  order?: string;

  @serializable(
    alias(
      "template_step_articles",
      list(object(JourneyTemplateStepEntityModel))
    )
  )
  templateStepArticles?: JourneyTemplateStepEntityModel[];

  @serializable(
    alias(
      "template_step_article_list",
      list(object(JourneyTemplateStepEntityModel))
    )
  )
  templateStepArticleList?: JourneyTemplateStepEntityModel[];

  @serializable(
    alias(
      "template_step_question_list",
      list(object(JourneyTemplateStepEntityModel))
    )
  )
  templateStepQuestionList?: JourneyTemplateStepEntityModel[];

  @serializable(
    alias(
      "template_step_questions",
      list(object(JourneyTemplateStepEntityModel))
    )
  )
  templateStepQuestions?: JourneyTemplateStepEntityModel[];

  @serializable(
    alias("step_articles", list(object(JourneyTemplateStepEntityModel)))
  )
  stepArticles?: JourneyTemplateStepEntityModel[];

  @serializable(
    alias("step_article_list", list(object(JourneyTemplateStepEntityListModel)))
  )
  stepArticleList?: JourneyTemplateStepEntityListModel[];

  @serializable(
    alias("step_questions", list(object(JourneyTemplateStepEntityModel)))
  )
  stepQuestions?: JourneyTemplateStepEntityModel[];

  @serializable(
    alias(
      "step_question_list",
      list(object(JourneyTemplateStepEntityListModel))
    )
  )
  stepQuestionList?: JourneyTemplateStepEntityListModel[];

  @serializable(alias("require_validation", primitive()))
  requireValidation?: boolean;
}
