import React, { FC, useEffect, useState } from "react";
import "./journeyStepForm.scss";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import JourneyBasicForm from "./JourneyBasicForm";
import JourneyTemplateSteps from "../../JourneyTemplate/JourneyTemplateDetails/JourneyTemplateSteps";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import JourneyStepService from "../../../services/JourneyService/journeyStep.service";
import JourneyTemplateService from "../../../services/JourneyTemplateService/journeyTemplate.service";
import JourneyService from "../../../services/JourneyService/journey.service";
import { useJourneyContext } from "../../../context/JourneyContext";
import { Button, Dropdown, Menu, Skeleton } from "antd";
import SpinnerLoader from "../../../shared/components/SpinnerLoader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getFullName } from "../../../shared/utils/dataFormatConverter";
import GoBack from "../../../shared/components/GoBack";
import JourneyPatientCard from "../../../shared/components/JourneyPatientCard";
import { Patient } from "../../../models/Patient/patient.model";

interface JourneyStepFormProps { }

const JourneyStepForm: FC<JourneyStepFormProps> = (props) => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [currentFormStep, setCurrentFormStep] = useState<any>(1);

  const { journeyStepList, tableLoading } = JourneyStepService();

  const { createJourneyTemplateFromJourney } = JourneyTemplateService();

  const {
    journeyDetails,
    setJourneyCategories,
    setActiveJourneyCategory,
    setActiveJourneyCategoryStep,
  } = useJourneyContext();

  const {
    publishJourney,
    buttonLoading,
    showJourney,
    journey,
    tableLoading: journeyFetching,
  } = JourneyService();

  useEffect(() => {
    return () => {
      setJourneyCategories([])
      setActiveJourneyCategory(undefined)
      setActiveJourneyCategoryStep(undefined)
    }
  }, [])

  useEffect(() => {
    setActiveJourneyCategoryStep(undefined);
    setActiveJourneyCategory(undefined);
  }, []);

  useEffect(() => {
    if (id && id !== "new") {
      showJourney(id);
    }
  }, [id]);

  useEffect(() => {
    if (history?.location?.state) setCurrentFormStep(history?.location?.state);
  }, [history?.location?.state]);

  const handleTemplateCreation = () => {
    createJourneyTemplateFromJourney({ journey_id: id }).then();
  };

  const handleJourneyPublish = async () => {
    const journey = await showJourney(id);
    if (journey && !journey?.isPublished)
      publishJourney(id).then(() =>
        history.push(generatePath(appRoutes.JOURNEY_DETAILS, { id: id }))
      );
    else history.push(generatePath(appRoutes.JOURNEY_DETAILS, { id: id }));
  };

  const secondaryButton = <Menu className="secondary-button-dropdown">
    <Menu.Item>
      <Button
        type="primary"
        onClick={handleTemplateCreation}
      >Save as new template
      </Button>
    </Menu.Item>
  </Menu>

  return (
    <div className="app-wrapper journey-step-form">
      <div className="journey-step-form__header">
        {tableLoading
          ? <Skeleton.Button active shape="round" style={{ width: "150px" }} />
          : currentFormStep === 1 ? (
            <div className="journey-step-form__title">
               <div className="d-flex">
                <GoBack route={id === 'new' ? {pathname: appRoutes.JOURNEYS}: {pathname: generatePath(appRoutes.JOURNEY_DETAILS, {id})}} showText={currentFormStep ===1} />
              </div>
            </div>
          ) : (
            <div className="journey-step-form__title">
              <div className="d-flex">
                <div className="go-back mr-3" onClick={() => setCurrentFormStep(1)}><ArrowLeftOutlined /></div>
                <div>
                  <span className="text-capitalize">
                  {journey?.name}</span>
                </div>{" "}
                {journey?.status && <div
                  className={`journey-step-form__journey-status ${journey?.status}`}
                >
                  {journey?.status}
                </div>}
              </div>
              {journey?.patient && <JourneyPatientCard hospitalId={journey?.hospitalId as string} patient={journey?.patient as Patient} allowRedirect/>}
              {!!journeyDetails.categories.length && journeyDetails?.categories?.every(cat => !!cat.id) && <div className="journey-step-form__steps__actions">
                <Dropdown.Button
                  type="primary"
                  trigger={["click"]}
                  placement="bottomRight"
                  overlay={secondaryButton}
                  onClick={() => history.push(generatePath(appRoutes.JOURNEY_DETAILS, { id }))}
                >
                  Save
                </Dropdown.Button>
              </div>}
            </div>
          )}
      </div>
      {
        tableLoading || journeyFetching
          ? <div className="journey-step-form__loading">
            <SpinnerLoader />
          </div>
          : currentFormStep === 1 ? (
            <JourneyBasicForm
              id={id}
              currentStep={currentFormStep}
              setCurrentStep={setCurrentFormStep}
            />
          ) : (
            <div className="journey-step-form__steps">
              <JourneyTemplateSteps
                editMode={false}
                customizeJourney
                loading={tableLoading}
              />
            </div>
          )}
    </div>
  );
};

export default JourneyStepForm;
