import React, { ChangeEvent, FC, useState } from 'react'
import "./chatHeader.scss"
import { Col, Dropdown, Input, Menu, Row } from "antd";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import ChatInfoDrawer from "../ChatInfoDrawer";
import { Journey } from '../../../../models/Journey/journey.model';
import DpChip from '../../../../shared/components/DpChip';

interface ChatHeaderProps {
    currentChat: Journey;
}

const ChatHeader: FC<ChatHeaderProps> = (props) => {
    const { currentChat } = props;

    const active = Math.random() < 0.5;

    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showInfoDrawer, setShowInfoDrawer] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');

    const handleSearchToggle = () => setShowSearch(!showSearch)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if (!event.target.value) setShowSearch(false)
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => setShowInfoDrawer(true)}>
                Chat info
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="chat-messages__header">
            <ChatInfoDrawer showDrawer={showInfoDrawer}
                setShowDrawer={setShowInfoDrawer}
                currentChat={currentChat}
            />
            <Row>
                <Col span={12}>
                    <DpChip name={currentChat?.patient?.name || ""}
                        size="mini"
                        url={currentChat?.patient?.displayPictureUrl} />
                    {/* <div className={"chat-messages__header__patient-image"}>
                        <img src={currentChat?.patient?.displayPictureUrl ?? userPlaceholder} alt={'profile'} /> */}
                    {/*<div className={`chat-status-icon ${active ? 'active':''}`}/>*/}
                    {/* </div> */}
                    <div className={"chat-messages__header__patient-info"}>
                        <div className={"chat-messages__header__patient-name text-capitalize"}>
                            {/* {currentChat?.patient?.fullname} */}
                            {currentChat?.patient?.name || ""}
                        </div>
                        {/*<div className={`chat-status ${active ? 'active':''}`}>
                            {active ? 'Active' : 'Offline'}
                        </div>*/}
                    </div>
                </Col>
                <Col span={8} offset={2}>
                    {/*<div className={`chat-messages__search ${showSearch ? 'show' : ''}`}>
                        <Input type={"text"}
                               allowClear
                               className={"search-input"}
                               value={searchText}
                               onChange={handleChange}
                        />
                        <span className={`chat-messages__search__toggle`} onClick={handleSearchToggle}><SearchOutlined /></span>
                    </div>*/}
                </Col>
                <Col span={1} offset={1}>
                    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}
                        className='manage-groups__details--action'
                    >
                        <EllipsisOutlined className={"chat-messages__info-trigger"} />
                    </Dropdown>
                </Col>
            </Row>
        </div>
    )
}

export default ChatHeader;