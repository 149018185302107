import { serializable, alias, primitive, object, list, custom } from 'serializr';
import { MetaModel } from "../meta.model";
import { Department } from "./department.model";
import { Location } from "./location.model";

export class Staff {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('firstname', primitive()))
    firstname?: string;

    @serializable(alias('lastname', primitive()))
    lastname?: string;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('phone_number', primitive()))
    phone?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('extension_code', primitive()))
    extCode?: string;

    @serializable(alias('employee_role_id', primitive()))
    roleId?: string;

    @serializable(alias('employee_role', object(MetaModel)))
    role?: MetaModel;

    @serializable(alias('hospital_department_id', primitive()))
    departmentId?: string;

    @serializable(alias('hospital_department', object(Department)))
    department?: Department;

    @serializable(alias('speciality_ids', list(primitive())))
    specialityIds?: any;

    @serializable(alias('specialities', list(object(MetaModel))))
    specialities?: Array<MetaModel>;

    @serializable(alias('experience', primitive()))
    experience?: string;

    @serializable(alias('language_ids', list(primitive())))
    languagesKnownIds?: any;

    @serializable(alias('languages', list(object(MetaModel))))
    languagesKnown?: Array<MetaModel>;

    @serializable(alias('hospital_location_id', primitive()))
    locationId?: string;

    @serializable(alias('location', primitive()))
    location?: string;

    @serializable(alias('website', primitive()))
    link?: string;

    @serializable(alias('bio', primitive()))
    bio?: string;

    @serializable(alias('display_picture', custom(
        (file) => file,
        (json) => { }
    )))
    displayPicture?: File;

    @serializable(alias('display_picture_url', primitive()))
    displayPictureUrl?: string;

    @serializable(alias('hospital_id', primitive()))
    hospitalId?: string;

    @serializable(alias('active', primitive()))
    status?: boolean;
}
