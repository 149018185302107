import React, { FC, useEffect, useState } from 'react'
import "./DpChip.scss"
import userPlaceholder from "../../../assets/userPlaceholder.png";
import UserService from '../../../services/AuthService/auth.service';

interface DpChipProps {
    name: string
    size?: "small" | "large" | "mini"
    url?: string
    userId?: number;
}

const DpChip: FC<DpChipProps> = (props) => {

    const {
        url,
        name,
        size = "small",
        userId
    } = props
		
    const {getUserDisplayPicture, displayPictureUrl} = UserService();
    
    const [names, setNames] = useState<string[]>([]);
    

    useEffect(() => {
        userId && getUserDisplayPicture(userId as number);
    },[userId])
    
    useEffect(() => {
        setNames(prev => {
            const names = name?.split(" ") || ["", ""]
            return [names[0] || '', names[1] || ""]
        })
    }, [name])

    if (!names[0] && !names[1])
        return null

    return (
        <div className={`dp-chip ${size} ${name.split(" ")[0]?.charAt(0)?.toLowerCase()}`}>
            {displayPictureUrl
                ? <img src={displayPictureUrl || userPlaceholder} alt="" />
                : url 
                ? <img src={url || userPlaceholder} alt="" />
                : <span>
                    {(names[0]?.charAt(0) || "") || ""}{(names[1]?.charAt(0) || "") || ""}
                </span>}
        </div>
    )
}

export default DpChip