
/* Auth Routes */
export const AUTH = "/auth"
export const REGISTER = AUTH + "/register"
export const LOGIN = AUTH + "/login"
export const FORGOT_PASSWORD = AUTH + "/forgot-password"
export const RESET_PASSWORD = AUTH + "/reset-password"

/* Dashboard Route */
export const DASHBOARD = "/dashboard"

/* Hospital routes */
export const HOSPITALS = "/hospitals"
export const SHOW_HOSPITAL = HOSPITALS + "/:id"
export const HOSPITAL_LOCATIONS = SHOW_HOSPITAL + "/locations";

/* Patient routes */
export const PATIENTS = "/patients"
export const SHOW_PATIENT = "/hospitals/:hospitalId/patients/:id";

/* Staff routes */
export const STAFFS = "/staffs"
export const SHOW_STAFF = STAFFS + "/:id";

/* JourneyList routes */
export const JOURNEYS = "/journeys";
export const JOURNEY_FORM = JOURNEYS + "/:id";
export const JOURNEY_DETAILS = JOURNEYS + "/:id/info";

/* Article routes */
export const ARTICLES = "/content"
export const ARTICLE_FORM = "/content/:id"
export const ARTICLE_VIEW = "/content/:id/view"

/* Faq routes */
export const FAQ = "/faqs"
export const FAQ_FORM = "/faqs/:categoryId/question/:id"

/* JourneyList template routes */
export const JOURNEY_TEMPLATES = "/journey-templates";
export const SHOW_JOURNEY_TEMPLATES = JOURNEY_TEMPLATES + "/:id";

/*patients and caretakers*/
export const SHOW_CARETAKER = "/hospitals/:hospitalId/caretakers/:id";

/*Chats*/
export const CHATS = '/chats';

/*Calendar*/
export const CALENDAR = '/calendar';
