import {useState} from "react";
import {Location} from "../../models/Hospital/location.model";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {deserialize, serialize} from "serializr";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {generatePath} from "react-router-dom";
import {PaginationModel} from "../../models/pagination.model";
import {MetaModel} from "../../models/meta.model";

const LocationService = () => {

    const [locationList, setLocationList] = useState<Array<Location>>([]);

    const [locationsMeta, setLocationsMeta] = useState<Array<MetaModel>>([]);

    const [locationTableLoading, setLocationTableLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentLocationPage, setCurrentLocationPage] = useState<number>(1);

    const [totalLocationItems, setTotalLocationItems] = useState<number>(0);

    const fetchLocations = (hospitalId:string, search?:string, page?:number, pageSize?:number, isMeta?:boolean) => {
        setLocationTableLoading(true);
        let apiUrl = generatePath(ApiRoutes.HOSPITAL_LOCATIONS,{id:hospitalId}) +
            `?${search ? `search=${search}&` : ''}${page ? `page=${page}&page_size=${pageSize}`:''}`;
        return axiosInstance
            .get(apiUrl)
            .then((response) => {
                const locations:any = !isMeta ?  deserialize(Location, response.data['results'])
                                        : deserialize(MetaModel,response.data['results']);
                const paginationData = deserialize(PaginationModel,response.data);
                if(!isMeta) setLocationList(locations)
                else setLocationsMeta([...locationsMeta,...locations]);
                setCurrentLocationPage(paginationData?.page);
                setTotalLocationItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setLocationTableLoading(false)
            });
    }

    const createLocation = (hospitalId:string,data:Location) => {
        setButtonLoading(true);
        const locationData = serialize(Location,data);
        return axiosInstance
            .post(generatePath(ApiRoutes.HOSPITAL_LOCATIONS,{id:hospitalId}),locationData)
            .then((response) => {
                const location = deserialize(Location, response.data);
                setLocationList([...locationList,location]);
                Notification({
                    message: "Success",
                    description: "Location has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const updateLocation = (hospitalId:string,data:Location,id?:string) => {
        setButtonLoading(true);
        const locationData = serialize(Location,data);
        return axiosInstance
            .put(generatePath(ApiRoutes.HOSPITAL_LOCATIONS,{id:hospitalId})+`/${id}`,locationData)
            .then(() => {
                Notification({
                    message: "Success",
                    description: "Location has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const deleteLocation = (hospitalId:string,id?:string) => {
        setButtonLoading(true);
        return axiosInstance
            .delete(generatePath(ApiRoutes.HOSPITAL_LOCATIONS,{id:hospitalId})+`/${id}`)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Location has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    return{
        locationList,
        locationTableLoading,
        buttonLoading,
        fetchLocations,
        createLocation,
        updateLocation,
        deleteLocation,
        currentLocationPage,
        totalLocationItems,
        locationsMeta
    }
}

export default LocationService;