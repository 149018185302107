import React, { Dispatch, FC, useEffect, useState } from "react";
import "../journeyTemplateHeader.scss";
import { validationSchema } from "./FormValidation";
import { Form, Formik } from "formik";
import InputField from "../../../../../shared/components/InputField";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import * as appRoutes from "../../../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router";
import JourneyTemplateService from "../../../../../services/JourneyTemplateService/journeyTemplate.service";
import { JourneyTemplate } from "../../../../../models/JourneyTemplate/journeyTemplate.model";
import { generatePath, useParams } from "react-router-dom";
import DropdownField from "../../../../../shared/components/DropdownField";
import { staffStatusMeta } from "../../../../../shared/utils/statusMetas";
import MetaService from "../../../../../services/MetaService/meta.service";
import { DropdownModeEnum } from "../../../../../enums/dropdownMode.enum";
import { useJourneyContext } from "../../../../../context/JourneyContext";
import * as AppRoutes from "../../../../../routes/routeConstants/appRoutes";

interface JourneyTemplateFormProps {
  setEditMode: Dispatch<boolean>;
  template: JourneyTemplate;
  refreshTemplate: () => void
}

const JourneyTemplateForm: FC<JourneyTemplateFormProps> = (props) => {
  const { setEditMode, template, refreshTemplate } = props;

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [initialValue, setInitialValue] = useState<JourneyTemplate>(
    new JourneyTemplate()
  );

  const { setActiveJourneyCategory, setActiveJourneyCategoryStep } =
    useJourneyContext();

  const {
    createJourneyTemplate,
    buttonLoading,
    // journeyTemplate,
    updateJourneyTemplate,
    // showJourneyTemplate,
  } = JourneyTemplateService();

  const { tagsMeta, fetchTags } = MetaService();

  useEffect(() => {
    fetchTags();
    setActiveJourneyCategory(undefined);
    setActiveJourneyCategoryStep(undefined);
  }, []);

  const onSubmit = (values: JourneyTemplate) => {
    if (!template?.id) {
      createJourneyTemplate(values).then((res: any) => {
        setEditMode(false);
        res?.id && history.push({
          pathname: generatePath(AppRoutes.SHOW_JOURNEY_TEMPLATES, { id: res?.id }),
          state: "edit",
        });
      });
    } else
      updateJourneyTemplate(values, template?.id).then(() => {
        setEditMode(false);
        history.push({
          pathname: AppRoutes.JOURNEY_TEMPLATES
        });
      });
  };

  useEffect(() => {
    setInitialValue({ ...template });
    if (template?.id)
      history.push(
        generatePath(appRoutes.SHOW_JOURNEY_TEMPLATES, {
          id: template?.id,
        }),
        "edit"
      );
  }, [template]);

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, errors, isValid, dirty, setFieldValue, setFieldTouched }) => (
        <Form>
          <div className="journey-template-header-box">
          <div className="journey-template-header__field">
            <div className="form-label">Plan Name</div>
            <InputField type="text" name="name" placeholder="Enter plan name" />
          </div>

          <div className="journey-template-header__field">
            <div className="form-label">Tags</div>
            <DropdownField
              name="tagIds"
              options={tagsMeta}
              onChange={(value) => {
                setFieldValue("tagIds", value);
              }}
              mode={DropdownModeEnum.MULTIPLE}
              placeHolder="Select tags"
              showArrow={true}
              showSearch={true}
              value={values?.tagIds || values?.tags?.map((tag) => tag.label)}
            />
          </div>
        {template?.id && (
            <div className="journey-template-header__field">
              <div className="form-label">Status</div>
              <DropdownField
                name="status"
                onChange={(value) => {
                  setFieldValue("status", value);
                }}
                options={staffStatusMeta}
                placeHolder="Select status"
                showArrow={true}
                value={values?.status}
              />
            </div>
          )}
          </div>
          <div className="journey-template-header__actions">
            <ButtonComponent
              className="journey-template-header__action"
              type="text"
              text="Cancel"
              onClick={() => {
                if (template?.id) {
                  setEditMode(false);
                  history.push({
                    pathname: history.location.pathname,
                    state: "",
                  });
                } else {
                  history.push(appRoutes.JOURNEY_TEMPLATES);
                }
              }}
            />
            <ButtonComponent
              htmlType="submit"
              loading={buttonLoading}
              className="journey-template-header__action"
              type="primary"
              text="Save journey details"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default JourneyTemplateForm;
