import { Col, Form, Row } from "antd";
import { Formik } from "formik";
import { values } from "lodash";
import React, { FC, Fragment } from "react";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import JourneyStepService from "../../../../../../services/JourneyService/journeyStep.service";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import EmptyState from "../../../../../../shared/components/EmptyState";
import RichTextField from "../../../../../../shared/components/RichTextField";
import RichTextViewer from "../../../../../../shared/components/RichTextViewer";
import AuthContainer from "../../../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../../../store/reducers/authReducer";
import "./journeyDoctorNotes.scss";

interface JourneyDoctorNotesProps extends AuthReducerProps {
  event: JourneyTemplateStep;
  onUpdate: () => void
}

const JourneyDoctorNotes: FC<JourneyDoctorNotesProps> = (props) => {
  const { event, user, onUpdate } = props;
  const {
    stepButtonLoading,
    updateJourneyStep,
  } = JourneyStepService();

  const updateStepHandler = async (values: { doctorNotes: string }) => {
    if (!event.journeyCategoryId || !event.id) return
    const response = await updateJourneyStep(event.journeyCategoryId, event.id, { ...event, doctorNotes: values.doctorNotes })
    response && onUpdate()
  }

  return (
    <div className="journey-doctor-notes">
      {user?.role === "Clinical Staff"
        ? <Formik onSubmit={updateStepHandler} initialValues={{ doctorNotes: event.doctorNotes || "" }}>
          {({ submitForm, setFieldValue, values }) => <Form>
            <Row justify="end" gutter={[0, 16]}>
              <Col span={24}>
                <RichTextField name={"doctorNotes"} editorData={values.doctorNotes} setFieldValue={setFieldValue} />
              </Col>
              <Col >
                <ButtonComponent
                  onClick={submitForm}
                  htmlType="submit"
                  type="primary"
                  text="Save"
                  loading={stepButtonLoading} />
              </Col>
            </Row>
          </Form>}
        </Formik>
        : event?.doctorNotes
          ? <Fragment>
            <div className="journey-doctor-notes__label">Clinical Notes</div>
            {/* <div className="journey-doctor-notes__description">
              {event?.doctorNotes}
            </div> */}
            <RichTextViewer content={event?.doctorNotes} height={300} />
          </Fragment>
          : <EmptyState />}
    </div>
  );
};

export default AuthContainer(JourneyDoctorNotes);
