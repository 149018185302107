import React, { FC, useEffect, useState } from "react";
import "./patientsList.scss";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { Dropdown, Menu, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import PatientForm from "./PatientForm";
import moment from "moment";
import { Patient } from "../../../models/Patient/patient.model";
import PatientService from "../../../services/PatientService/patient.service";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import TableComponent from "../../../shared/components/TableComponent";
import { getAgeText } from "../../../shared/utils/dateHelpers";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getFullName } from "../../../shared/utils/dataFormatConverter";

interface PatientsListProps {
  fetchPatients: Function;
  tableLoading: boolean;
  patientList: Array<Patient>;
  currentPage?: number;
  totalItems?: number;
  handlePageChange?: (page: number, pageSize?: number) => void;
  hospitalId: string | number;
}

const PatientsList: FC<PatientsListProps> = (props) => {
  const {
    tableLoading,
    hospitalId: id,
    fetchPatients,
    patientList,
    currentPage,
    totalItems,
    handlePageChange,
  } = props;

  const history = useHistory();

  const { deletePatient, patientButtonLoading } = PatientService();

  const [showPatientForm, setShowPatientForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [currentPatient, setCurrentPatient] = useState<any>({});

  const columns = [
    {
      title: "NAME",
      key: "name",
      width: 180,
      ellipsis: true,
      render: (text: string, record: Patient) => (
        <>
          {/* <img src={record?.displayPictureUrl ?? userPlaceholder} alt=''/> */}
          <span className="text-capitalize">
          {getFullName(record)}
          </span>
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 150,
      ellipsis: true,
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      className: "white-nowrap",
      width: 120,
      ellipsis: true,
      render: (text: string, record: Patient) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text)
    },
    {
      title: "SEX",
      dataIndex: "gender",
      key: "gender",
      width: 60,
      ellipsis: true,
      render: (text: string) => <span className="text-capitalize">{text}</span>,
    },
    {
      title: "AGE",
      key: "dob",
      width: 60,
      dataIndex: "dob",
      render: (text: string) => getAgeText(text),
    },
    {
      title: null,
      key: "action",
      width: 50,
      render: (text: string, record: any) => (
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          trigger={["click"]}
          className="manage-groups__details--action"
          onVisibleChange={(visible) => {
            setCurrentPatient(visible ? record : {});
          }}
        >
          <EllipsisOutlined
            className="table-menu"
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setShowPatientForm(true);
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const deleteHandler = async () => {
    if (!id || !currentPatient.id) return;
    await deletePatient(id, currentPatient.id);
  };

  return (
    <div className="staffs patients-list">
      <TableComponent
        loading={tableLoading}
        data={patientList}
        columns={columns}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        total={totalItems}
        handleRowClick={(record: Patient) =>
          record?.id &&
          history.push({
            pathname:   generatePath(appRoutes.SHOW_PATIENT, {
              hospitalId: id,
              id: record?.id,
            }),
            state: {
              currentPage: currentPage
            },
            hash: "patient",
          }
          
          )
        }
      />

      <PatientForm
        showForm={showPatientForm}
        setShowForm={setShowPatientForm}
        patient={currentPatient}
        hospitalId={id}
        refreshList={fetchPatients}
      />

      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        setShowConfirmation={setShowDeleteConfirmation}
        entityName={"patient"}
        handleDelete={deleteHandler}
        buttonLoading={patientButtonLoading}
        refresh={fetchPatients}
      />
    </div>
  );
};

export default PatientsList;
