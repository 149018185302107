import {serializable, alias, primitive} from 'serializr';

export class PaginationModel {
    @serializable(alias('page', primitive()))
    page: number = 1;

    @serializable(alias('page_size', primitive()))
    pageSize?: number;

    @serializable(alias('total', primitive()))
    total: number = 0;
}
