import React, { useEffect, useState } from "react";
import { Menu, Popover } from "antd";
import "./appSidebar.scss";
import { generatePath, useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import sideBarLogo from "../../../assets/sidebar-logo.png";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import UserService from "../../../services/AuthService/auth.service";
import { SHOW_HOSPITAL } from "../../../routes/routeConstants/appRoutes";
import StaffBasicDetails from "../StaffBasicDetails";
import DpChip from "../DpChip";
import HospitalService from "../../../services/HospitalService/hospital.service";

interface AppSidebarProps extends AuthReducerProps { }

function AppSidebar({ authenticated, user }: AppSidebarProps) {
  const history = useHistory();

  const { logoutUser } = UserService();

  const {
    showHospital,
    hospital,
  } = HospitalService()

  const [pathname, setPathname] = useState<string>(history.location.pathname);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  
  useEffect(() => {
    user?.hospitalId && showHospital(user?.hospitalId)
  }, [user?.hospitalId])

  useEffect(() => {
    return history.listen((location) => {
      setPathname("/" + location?.pathname?.split("/")[1]);
    });
  }, [history]);

  const handleClick = ({ key }: any) => {
    if (key !== "profile" && key !== AppRoutes.DASHBOARD) history.push(key);
  };

  const profileContent = (
    <div className="profile-menu">
      <div
        className="profile-menu__item"
        onClick={() => setShowDetailsModal(true)}
      >
        <i className="icon-my-account" />
        <span className="app-sidebar__menu-title">My Account</span>
      </div>
      {user?.role === "Hospital Admin" && (
        <div
          className="profile-menu__item"
          onClick={() => {
            history.push(generatePath(SHOW_HOSPITAL, { id: user?.hospitalId }));
          }}
        >
          <i className="icon-hospitals" />
          <span className="app-sidebar__menu-title">Hospital profile</span>
        </div>
      )}
      <div className="profile-menu__item" onClick={logoutUser}>
        <i className="icon-log-out" />
        <span className="app-sidebar__menu-title">Log out</span>
      </div>
    </div>
  );

  return !history?.location?.pathname?.includes("auth") && authenticated ? (
    <div className="app-sidebar">
      {user && <StaffBasicDetails
        staff={user}
        setShowDetails={setShowDetailsModal}
        showDetails={showDetailsModal}
        setCurrentStaff={() => { }}
        enablePasswordReset={true}
      />}
      <div className="app-sidebar__menubar__logo">
        <img src={sideBarLogo} alt="" />
      </div>
      <Menu
        className="app-sidebar__menubar"
        onClick={handleClick}
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={[pathname]}
        mode="inline"
      >
        {/* <Menu.Item key={AppRoutes.DASHBOARD} className="disabled">
          <i
            className={`icon-Union-14 ${pathname.includes("dashboard") ? "active-icon" : ""
              }`}
          />
          <div className="app-sidebar__menu-title">Dashboard</div>
        </Menu.Item> */}

        {user?.role === "Hospital Admin" && (
          <Menu.Item key={AppRoutes.PATIENTS}>
            <i
              className={`icon-my-account ${pathname.includes("patient") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Patient</div>
          </Menu.Item>
        )}

{user?.role === "Hospital Admin" && (
          <Menu.Item key={AppRoutes.STAFFS}>
            <i
              className={`icon-members ${pathname.includes("staff") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Teams</div>
          </Menu.Item>
        )}

        <Menu.Item key={AppRoutes.JOURNEYS}>
          <i
            className={`icon-journey ${pathname.includes("journeys") ? "active-icon" : ""
              }`}
          />
          <div className="app-sidebar__menu-title">Journey</div>
        </Menu.Item>

        {user?.role !== "Hospital Admin" && (
          <Menu.Item key={AppRoutes.CHATS}>
            <i
              className={`icon-chat ${pathname.includes("chat") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Chat</div>
          </Menu.Item>
        )}

        <Menu.Item key={AppRoutes.ARTICLES}>
          <i
            className={`icon-articles ${pathname.includes("content") ? "active-icon" : ""
              }`}
          />
          <div className="app-sidebar__menu-title">Content</div>
        </Menu.Item>

        <Menu.Item key={AppRoutes.FAQ}>
          <i
            className={`icon-faq ${pathname.includes("faq") ? "active-icon" : ""
              }`}
          />
          <div className="app-sidebar__menu-title">FAQ</div>
        </Menu.Item>

        <Menu.Item key={AppRoutes.JOURNEY_TEMPLATES}>
          <i
            className={`icon-journey-template ${pathname.includes("journey-templates") ? "active-icon" : ""
              }`}
          />
          <div className="app-sidebar__menu-title">Journey Template</div>
        </Menu.Item>

        {/*  // will be used later in phase 2
         {user?.role !== "Hospital Admin" && (
          <Menu.Item key={AppRoutes.CALENDAR}>
            <i
              className={`icon-Calander ${pathname.includes("calendar") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Calendar</div>
          </Menu.Item>
        )} */}
      </Menu>
      <Popover
                placement="right"
                title={null}
                content={profileContent}
                trigger="hover"
              >
      <div className="app-sidebar__profile-container">
        <div className="app-sidebar__profile" key="profile">
          <div>
            <DpChip name={user?.name ?? user?.firstName + " " + user?.lastName}
              size="large"
              url={user?.displayPictureUrl}
              userId = {user?.id}
            />
            {/* <img
                src={avatarPlaceholder}
                className="app-sidebar__profile-img"
                alt="User Placeholder"
              /> */}
          </div>
          <div className="app-sidebar__profile-info">
            <div className="app-sidebar__profile-submenu">
             
                <div className="app-sidebar__username text-capitalize">
                  {user?.title + " "+ user?.name ?? user?.firstName + " " + user?.lastName}
                </div>
              <p className="app-sidebar__hospital text-capitalize">
                {hospital.name}
              </p>
            </div>
            <span className="app-badge">
              {user?.role}
            </span>
          </div>
        </div>
      </div>
      </Popover>
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);
