import { serializable, alias, primitive } from 'serializr';

export class Hospital {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('id', primitive()))
    key?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('location', primitive()))
    location?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('phone_number', primitive()))
    phone?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('extension_code', primitive()))
    extCode?: string;


    @serializable(alias('no_of_employees', primitive()))
    noOfEmployees?: number;

    @serializable(alias('website', primitive()))
    website?: string;

    @serializable(alias('bio', primitive()))
    bio?: string;

}
