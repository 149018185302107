import React, { FC, useEffect, useState } from "react";
import "./journeyTemplateChecklists.scss";
import { ReactSortable, Sortable } from "react-sortablejs";
import { Checkbox } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import JourneyTemplateChecklistService from "../../../../../services/JourneyTemplateService/journeyTemplateChecklist.service";
import { JourneyTemplateChecklist } from "../../../../../models/JourneyTemplate/journeyTemplateChecklist.model";
import JourneyChecklistService from "../../../../../services/JourneyService/journeyStepChecklist.service";
import { list } from "serializr";
import SkeletonLoader from "../../../../../shared/components/SkeletonLoader";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import { CheckListStatus } from "../../../../../enums/checkListStatus.enum";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";

interface JourneyTemplateChecklistsProps {
  step: JourneyTemplateStep;
  fromJourney?: boolean;
}

const JourneyTemplateChecklists: FC<JourneyTemplateChecklistsProps> = (
  props
) => {
  const { step, fromJourney } = props;

  const {
    fetchJourneyTemplateChecklists,
    journeyTemplateChecklistItems,
    listLoading,
  } = JourneyTemplateChecklistService();
  const {
    fetchJourneyChecklists,
    journeyChecklistItems,
    updateJourneyChecklistOrder,
    checklistLoading,
    updateJourneyChecklist,
  } = JourneyChecklistService();

  const [checklist, setChecklist] = useState<any>([]);

  useEffect(() => {
    setChecklist([]);
    if (step?.id)
      fromJourney
        ? fetchJourneyChecklists(step?.id)
        : fetchJourneyTemplateChecklists(step?.id);
  }, [step]);

  useEffect(() => {
    setChecklist(
      fromJourney
        ? [...journeyChecklistItems]
        : [...journeyTemplateChecklistItems]
    );
  }, [journeyTemplateChecklistItems, journeyChecklistItems]);

  const handleReorder = (e: any) => {
    const data = {
      order: e.newIndex + 1,
    };

    if (fromJourney)
      updateJourneyChecklistOrder(step?.id, e.item.dataset.id, data).then(() =>
        fetchJourneyChecklists(step?.id)
      );
  };

  const handleChecklistStatusChange = (checklist: JourneyTemplateChecklist, status: CheckListStatus) => {
    updateJourneyChecklist(step.id, checklist.id, {
      ...checklist,
      status
    }).then(res => {
      fetchJourneyChecklists(step?.id)
    })
  }

  return (
    <div className="journey-template-checklists">
      {!listLoading && !checklistLoading && checklist.length > 0 && (
        <>
          <div className="journey-template-step__label">Checklist</div>
          <ReactSortable
            list={checklist}
            setList={setChecklist}
            onSort={handleReorder}
            disabled={!fromJourney}
          >
            {checklist.map((item: JourneyTemplateChecklist) => (
              <div key={item.id} className={`journey-template-checklists__item ${fromJourney && item.status === CheckListStatus.COMPLETED && "extended"}`}>
                <Checkbox
                  key={"check__" + item.id}
                  disabled
                  className={item.status}
                  checked={!!item.status}
                />
                <span className="journey-template-checklists__name">
                  {item.content}
                </span>
                {fromJourney && <EllipsisOutlined title="Reorder handler" />}
                {fromJourney && item.status === CheckListStatus.COMPLETED && <>
                  <ButtonComponent
                    type="text"
                    text="Approve"
                    className="approve"
                    onClick={() => handleChecklistStatusChange(item, CheckListStatus.CONFIRMED)} />
                  <ButtonComponent
                    type="text"
                    text="Reject"
                    className="reject"
                    onClick={() => handleChecklistStatusChange(item, CheckListStatus.PENDING)} />
                </>}
              </div>
            ))}
          </ReactSortable>
        </>
      )}
      {(listLoading || checklistLoading) &&
        [...Array(3)].map((x, i) => (
          <div key={i} className="journey-template-checklists__item">
            <SkeletonLoader rows={0} />
          </div>
        ))}
    </div>
  );
};

export default JourneyTemplateChecklists;
