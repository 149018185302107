import React, { useState } from "react";
import { JourneyTemplateChecklist } from "../../../../../../models/JourneyTemplate/journeyTemplateChecklist.model";
import { EllipsisOutlined } from "@ant-design/icons";
import { TextAreaField } from "../../../../../../shared/components/InputField";

interface JourneyTemplateChecklistItemProps {
  handleChecklistItemBlur: Function;
  handleDeleteItem: Function;
  x: any;
  i: number;
  checklistItems: JourneyTemplateChecklist[];
  customizeJourney?: boolean;
}

function JourneyTemplateChecklistItem({
  handleChecklistItemBlur,
  handleDeleteItem,
  i,
  x,
  checklistItems,
  customizeJourney,
}: JourneyTemplateChecklistItemProps) {
  const [value, setValue] = useState(x?.content ?? "");

  return (
    <div className="journey-template-checklist-form__field">
      <TextAreaField
        type={"text"}
        placeholder={"Enter checklist item"}
        className={"journey-template-checklist-form__input"}
        onChange={(e: any) => setValue(e.target.value)}
        onBlur={(event: any) => {
          if (!!event.target.value) {
            handleChecklistItemBlur(x, event, i);
            if (!x?.id) setValue("");
          }
        }}
        value={value}
      />
      {customizeJourney && (
        <div className="journey-template-step__drag-icon">
          <EllipsisOutlined />
        </div>
      )}
      <div
        className="journey-template-step__remove-icon"
        onClick={() => handleDeleteItem(x)}
      >
        X
      </div>
    </div>
  );
}

export default JourneyTemplateChecklistItem;
