import React, { FC, useRef, useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import Error from "../Error";
import "./inputField.scss";

interface InputFieldProps {
  type: string;
  name: string;
  placeholder: string;
  rows?: number;
  min?: string;
  onBlur?: Function;
}

const { TextArea } = Input;

const InputField: FC<InputFieldProps> = (props) => {
  const { name, type } = props;

  return (
    <div className="input-field">
      {type === "textarea" ? (
        <Field as={TextAreaField} {...props} />
      ) : (
        <Field as={Input} {...props} />
      )}
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </div>
  );
};

export const TextAreaField = ({ ...props }) => {
  const textAreaRef = useRef<any>(null);

  const [textAreaHeight, setTextAreaHeight] = useState("auto");

  const [parentHeight, setParentHeight] = useState("auto");

  const [text, setText] = useState("");

  useEffect(() => {
    setParentHeight(
      `${textAreaRef.current?.resizableTextArea?.textArea?.scrollHeight}px`
    );
    setTextAreaHeight(
      `${textAreaRef.current?.resizableTextArea?.textArea?.scrollHeight}px`
    );
  }, [text]);

  const onChangeHandler = (event: any) => {
    setTextAreaHeight("auto");
    setParentHeight(
      `${textAreaRef?.current?.resizableTextArea?.textArea?.scrollHeight}px`
    );

    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div
      style={{
        minHeight: parentHeight,
      }}
      className={props?.className}
    >
      <TextArea
        ref={textAreaRef}
        {...props}
        rows={2}
        style={{
          height: textAreaHeight,
        }}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default InputField;
