import React, { FC } from "react";
import "./journeyEventDetails.scss";
import JourneyTemplateMembers from "../../../../../JourneyTemplate/JourneyTemplateDetails/JourneyTemplateSteps/JourneyTemplateMembers";
import JourneyTemplateCarousels from "../../../../../JourneyTemplate/JourneyTemplateDetails/JourneyTemplateSteps/JourneyTemplateCarousels";
import JourneyTemplateChecklists from "../../../../../JourneyTemplate/JourneyTemplateDetails/JourneyTemplateSteps/JourneyTemplateChecklists";
import moment from "moment";
import { Journey } from "../../../../../../models/Journey/journey.model";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";

interface JourneyEventDetailsProps {
  event: JourneyTemplateStep;
  journey?: Journey;
}

const JourneyEventDetails: FC<JourneyEventDetailsProps> = (props) => {
  const { event, journey } = props;

  return (
    <div className="journey-event-details">
      <div className="journey-event-details__row">
        <div className="journey-event-details__column">
          <div className="journey-event-details__label">Start time</div>
          <div className="journey-event-details__content">
            {event?.stepTime
              ? moment(moment.utc(event.stepTime)).format("DD/MM/YYYY hh:mm a")
              : "-"}
          </div>
        </div>
        <div className="journey-event-details__column">
          <div className="journey-event-details__label">End time</div>
          <div className="journey-event-details__content">
            {event?.stepEndTime
              ? moment(moment.utc(event.stepEndTime)).format("DD/MM/YYYY hh:mm a")
              : "-"}
          </div>
        </div>
      </div>
      <div className="journey-event-details__row">
        <div className="journey-event-details__label">Description</div>
        <div
          className="journey-event-details__content"
          dangerouslySetInnerHTML={{ __html: event?.description ?? "" }}
        ></div>
      </div>
      <div className="journey-event-details__row">
        <div className="journey-event-details__column">
          <JourneyTemplateMembers
            step={event}
            hidePopover={true}
            setShowMembers={() => { }}
            showMembers={true}
          />
        </div>
        {/* <div className="journey-event-details__column">
          <div className="journey-event-details__label">
            Parent/Guardian/Caregiver
          </div>
          <img
            src={
              journey?.patient?.caretaker? ?? userPlaceHolder
            }
            alt=""
            className="journey-template-members__image"
            title={
              journey?.patient?.caretaker?.firstname +
              " " +
              journey?.patient?.caretaker?.lastname
            }
          />
        </div> */}
      </div>
      <div className="journey-event-details__row">
        {/* <div className="journey-event-details__label">Carousel</div> */}
        <JourneyTemplateCarousels step={event} fromJourney={true} />
      </div>
      <div className="journey-event-details__row">
        <JourneyTemplateChecklists step={event} fromJourney={true} />
      </div>
    </div>
  );
};

export default JourneyEventDetails;
