import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'
import { useHistory } from 'react-router-dom';
type Props = {
    route ?: RouteProps;
    showText ?: boolean
}

interface RouteProps {
    pathname :string;
    search?:string;
    hash?: string;
    state?:StateProps;
}

interface StateProps {
    id?: string|number;
    currentPage ?: string|number;
    search?: string;
}

function GoBack({route,showText}: Props) {
    const history = useHistory();
  return (
    <div
        className="go-back"
        onClick={() => route? history.push(route) : history.goBack() }
      >
        <ArrowLeftOutlined /> {showText && 'Back'}
      </div>
  )
}

export default GoBack