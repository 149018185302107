import React, { FC, useEffect, useRef, useState } from "react";
import "./articleForm.scss";
import { Form, Formik, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { useHistory } from "react-router";
import RichTextField from "../../../shared/components/RichTextField";
import { Col, Modal, Row } from "antd";
import DropdownField from "../../../shared/components/DropdownField";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { priorityMeta } from "../../../shared/utils/statusMetas";
import ArticleService from "../../../services/ArticleService/article.service";
import { ArticleModel } from "../../../models/Article/article.model";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import { articleValidationSchema } from "./articleFormValidation";
import MetaService from "../../../services/MetaService/meta.service";
import SpinnerLoader from "../../../shared/components/SpinnerLoader";
import CropperModal from "../../../shared/components/CropperModal";
import { dataURItoFile } from "../../../shared/utils/dataFormatConverter";
import { useIdleTimer } from "react-idle-timer";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import { NotificationTypes } from "../../../enums/notificationTypes";
import Notification from "../../../shared/components/Notification";
import GoBack from "../../../shared/components/GoBack";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface ArticleFormProps { }

const ArticleForm: FC<ArticleFormProps> = (props) => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const {state}  = useLocation<{currentPage: string | number, update: boolean}>();
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [draftIndicator,setDraftIndicator] = useState(false)
 
  const ref = useRef<any>();

  const {
    createArticle,
    showArticle,
    article,
    updateArticle,
    tableLoading,
    buttonLoading,
    removeCoverPicture,
    deleteArticle,
  } = ArticleService();

  const { tagsMeta, totalTagsItems, currentTagsPage, fetchTags } =
    MetaService();

  const [publishLoader, setPublishLoader] = useState<boolean>(false);

  const [draftLoader, setDraftLoader] = useState<boolean>(false);

  const [coverPicture, setCoverPicture] = useState<{
    fileName: string;
    data: string;
  }>();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<ArticleModel>({
    bannerImage: undefined,
    id: undefined,
    title: "",
    authorName: "",
    content: "",
    tagIds: [],
    priorityId: undefined,
    isPublished: false,
  });

  const formikRef = useRef<FormikProps<ArticleModel>>(null)

  const idleTimer = useIdleTimer({
    timeout: 5000,
    onActive : () => idleTimer.reset(),
    onIdle: () => handleAutoSave(),
  })

  useEffect(() => {
    fetchTags().then();
    if (id !== "new") showArticle(id).then();
  }, [id]);

  useEffect(() => {
    if (article)
      setInitialValues({
        bannerImage: undefined,
        id: article?.id,
        title: article?.title,
        authorName: article?.authorName,
        content: article?.content,
        tagIds: article?.tags?.map((o) => o.label ?? ""),
        priorityId: article?.priorityId,
        isPublished: article?.isPublished,
      });
  }, [article]);

  const convertTagIdsToLabel = (labels:any) => {
    return labels.map((label:any) => /^\d+$/.test(label) ? tagsMeta.find(tagOption => tagOption?.value?.toString() == label.toString())?.label: label  )
  }

  const handleSubmit = (
    values: ArticleModel,
    formProps: { resetForm: () => void }
  ) => {
    const loader = values.isPublished ? setPublishLoader: setDraftLoader;
    loader(true);
    if (values?.bannerImageBase64Url && coverPicture) {
      values.bannerImage = dataURItoFile(
        values?.bannerImageBase64Url,
        coverPicture?.fileName
      );
    }
    if (id === "new") {
      createArticle(values)
        .then((response) => {
          response&&
            response?.id &&
            draftIndicator
            ? history.push(appRoutes.ARTICLES)
            : history.push({pathname: generatePath(appRoutes.ARTICLE_FORM, { id: Number((response as ArticleModel)?.id) }), state: {...state}})
          formProps.resetForm();
        })
        .finally(() => {
          fetchTags();
          loader(false);
        });
    } else
      updateArticle(values, id)
        .then(() => {
          formProps.resetForm();
        })
        .finally(() => {
          fetchTags();
          loader(false);
          draftIndicator
          ? history.push({pathname:appRoutes.ARTICLES, state: {...state}, search:`&page=${state?.currentPage || 1}`})
          : history.push({pathname:generatePath(appRoutes.ARTICLE_FORM, { id }), state: {...state}})
        });
  };

  const handleAutoSave = async () => {
    idleTimer.pause()
    if (!formikRef.current?.dirty
      || !formikRef.current?.isValid || !formikRef.current) return;

    const values = Object.assign(new ArticleModel(), formikRef.current?.values)

    values.isPublished = values.isPublished || false;
    const loader = values.isPublished ? setPublishLoader : setDraftLoader
    loader(true);
    if (values?.bannerImageBase64Url && coverPicture) {
      values.bannerImage = dataURItoFile(
        values?.bannerImageBase64Url,
        coverPicture?.fileName
      );
    }

    const response = id === "new"
      ? await createArticle(values, true)
      : await updateArticle(values, id, true);
    if (response) {
      id === "new" &&
        response?.id &&
        history.push(generatePath(appRoutes.ARTICLE_FORM, { id: response?.id }))

      setInitialValues({...response, tagIds: response.tags?.map((tag) => tag.label as string)})

    }
    Notification({
      message: "Autosaved",
      description: "",
      type: NotificationTypes.SUCCESS,
  });
  fetchTags();
  loader(false);
  idleTimer.reset();
  }

  const handleTagsScroll = (event: any) => {
    let target = event.target;
    if (
      tagsMeta.length < totalTagsItems &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      fetchTags({ page: currentTagsPage + 1 }).then();
    }
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { files } = e.target;
    if (files && files?.length > 0) {
      if (files[0].size > 15000000) {
        Notification({
          type: NotificationTypes.ERROR,
          message: "Image size is too large",
          description: "Maximum file size limit is 15 MB"
        })
        return
      }
      const reader = new FileReader();
      reader.onload = () => {
        setCoverPicture({
          fileName: files[0].name,
          data: reader.result as string,
        });
        ref.current.value = "";
      };
      reader.readAsDataURL(files[0]);
      setShowCropperModal(true);
    }
  };

  const handleDeleteBanner = () => {
    if (id === "new") {
    } else {
      Modal.confirm({
        icon: null,
        width: "40%",
        className: "ant-confirm-modal",
        title: "Delete Banner image",
        content: <div>Do you wish to remove the image?</div>,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          removeCoverPicture(+id).then(() => showArticle(id));
        },
        onCancel: () => { },
      });
    }
  };

  return (
    <div className="app-wrapper article-form article-details">
     {state?.update ? <GoBack showText/> : <GoBack showText route={{pathname: appRoutes.ARTICLES}}/>}
      {tableLoading ? (
        <SpinnerLoader />
      ) : (
        <div className={"article-form__wrapper"}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formikRef}
            validationSchema={articleValidationSchema}
          >
            {({ setFieldValue, isValid, values, resetForm }) => {
              return (
                <Form>
                  <div className={"article-form__banner-image"}>
                    {!article?.bannerImageUrl &&
                      !values?.bannerImageBase64Url ? (
                      <React.Fragment>
                        <input
                          type="file"
                          ref={ref}
                          accept="image/jpeg,image/jpg,image/png"
                          className="article-form__image-upload"
                          onChange={handleChangeImage}
                        />
                        <div className="article-form__banner-image__placeholder">
                          <i className={"icon-image-placeholder"} />
                          <div className="article-form__banner-image-label">
                            <i className={"icon-upload"} /> Upload cover picture
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="article-form__banner-image__view">
                        <div className={"article-form__banner-image__action"}>
                          <input
                            type="file"
                            ref={ref}
                            accept="image/jpeg,image/jpg,image/png"
                            className="article-form__banner-image-change"
                            onChange={handleChangeImage}
                          />
                          <span>
                            <i className={"icon-camera"} />
                            Change image
                          </span>
                          <span
                            onClick={() => {
                              if (values?.bannerImageBase64Url) {
                                setFieldValue("bannerImageBase64Url", "");
                              } else {
                                handleDeleteBanner();
                              }
                            }}
                          >
                            <i className={"icon-delete"} />
                            Remove image
                          </span>
                        </div>
                        <img
                          src={
                            values?.bannerImageBase64Url
                              ? values?.bannerImageBase64Url
                              : article?.bannerImageUrl
                                ? article?.bannerImageUrl
                                : ""
                          }
                          alt=""
                          className="uploaded-image"
                        />
                      </div>
                    )}
                  </div>

                  <div className={"faq-form__wrapper"}>
                    <div className="faq-form__field">
                      <div className="label">Title</div>
                      <InputField
                        type="textarea"
                        rows={3}
                        name="title"
                        placeholder="Content Title"
                      />
                    </div>

                    <div className="faq-form__field">
                      <Row>
                        <Col span={11}>
                          <div className="label">Author name</div>
                          <InputField
                            type="text"
                            name="authorName"
                            placeholder="Type author name"
                          />
                        </Col>
                        <Col span={11} offset={2}>
                          <div className="label">Priority</div>
                          <DropdownField
                            name="priorityId"
                            options={priorityMeta}
                            placeHolder="Select priority"
                            showArrow={true}
                            value={values?.priorityId}
                            className="statusId__dropdown"
                            onChange={(value) => {
                              setFieldValue("priorityId", value);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="faq-form__field">
                      <RichTextField
                        name={"content"}
                        editorData={values?.content || ""}
                        setFieldValue={setFieldValue}
                        idleTimer = {idleTimer}
                      />
                    </div>

                    <div className="faq-form__field">
                      <div className="label">Tags</div>
                      <DropdownField
                        name="tagIds"
                        options={tagsMeta}
                        placeHolder="Select or type tag names"
                        showArrow={true}
                        value={values?.tagIds}
                        className="statusId__dropdown"
                        onChange={(labels) => {
                          labels = labels.map((label:string) => label.toString().trim()).filter((label:string|number) => label.toString().trim() !== '');   
                          setFieldValue("tagIds", convertTagIdsToLabel(labels));
                        }}
                        mode={DropdownModeEnum.TAGS}
                        showSearch
                        onOptionsScroll={(event) => handleTagsScroll(event)}
                      />
                    </div>

                    <div className="faq-form__actions text-right">
                      {formikRef.current?.values?.id
                        ? <ButtonComponent
                          key="article-cancel"
                          type="text"
                          text={"Delete"}
                          onClick={() => setShowDelete(true)}
                        />
                        : <ButtonComponent
                          key="article-cancel"
                          type="text"
                          text={"Cancel"}
                          onClick={() => {
                            history.goBack();
                          }}
                        />
                      }
                      <ButtonComponent
                        key="article-draft"
                        // type={id !== "new" ? "text" : "primary"}
                        onClick={() => {
                          setFieldValue("isPublished",false)
                          setDraftIndicator(false)
                        }}
                        type="text"
                        htmlType="submit"
                        loading={draftLoader}
                        text={"Save to draft"}
                        disabled={publishLoader}
                      />
                      {/* {id !== "new" && ( */}
                      <ButtonComponent
                        key="article-save"
                        onClick={() => {
                          setFieldValue("isPublished",true)
                          setDraftIndicator(true)
                      }}
                        type="primary"
                        htmlType="submit"
                        loading={publishLoader}
                        text={"Publish"}
                        disabled={draftLoader}
                      />
                      {/* )} */}
                    </div>
                  </div>
                  {showCropperModal && coverPicture && (
                    <CropperModal
                      image={coverPicture?.data}
                      setFieldValue={setFieldValue}
                      showModal={showCropperModal}
                      closeForm={() => setShowCropperModal(false)}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
          {formikRef.current?.values?.id && <DeleteConfirmation
            handleDelete={() => deleteArticle(formikRef.current?.values?.id)}
            setShowConfirmation={setShowDelete}
            showConfirmation={showDelete}
            entityName={"Content"}
            buttonLoading={buttonLoading}
            key="delete-confirmation"
            refresh={() => history.push(appRoutes.ARTICLES)
            }
          />}
        </div>
      )}
    </div>
  );
};

export default ArticleForm;
