import { serializable, alias, primitive, custom, deserialize, object } from 'serializr';
import { Staff } from "../Hospital/staff.model";
import { Patient } from "../Patient/patient.model";
import { Journey } from "../Journey/journey.model";

export class ChatMessagesModel {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('message', primitive()))
    message: string = '';

    @serializable(alias('message_type', primitive()))
    messageType?: string;

    @serializable(alias('attachment', custom(
        (file) => file,
        (json) => { }
    )))
    attachment?: File;

    @serializable(alias('attachment_url', primitive()))
    attachmentUrl?: string;

    @serializable(alias('attachment_name', primitive()))
    attachmentName?: string;

    @serializable(alias('journey_id', primitive()))
    journeyId?: number;

    // @serializable(alias('journey', object(Journey)))
    // journey?: Journey;

    @serializable(alias('sender', custom(
        () => { },
        (value, context, oldValue, callback) =>
            value.class_name === 'Employee' ?
                deserialize(Staff, value, (err, res) => callback(null, { staff: res }))
                : deserialize(Patient, value, (err, res) => callback(null, { patient: res }))
    )))
    sender?: { staff: Staff, patient: Patient };

    @serializable(alias('created_at', primitive()))
    createdAt?: string;
}
