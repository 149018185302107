import React, { FC } from "react";
import "./patientDetails.scss";
import { Modal } from "antd";
import userPlaceholder from "../../../assets/userPlaceholder.png";
import { Patient } from "../../../models/Patient/patient.model";
import moment from "moment";
import { getAgeText } from "../../../shared/utils/dateHelpers";
import DpChip from "../../../shared/components/DpChip";

interface PatientDetailsProps {
  showDetails: boolean;
  setShowDetails: Function;
  patient: Patient;
}

const PatientDetails: FC<PatientDetailsProps> = (props) => {
  const { patient, showDetails, setShowDetails } = props;

  return (
    <Modal
      title={null}
      visible={showDetails}
      onCancel={() => setShowDetails(false)}
      footer={null}
      maskClosable = {false}
      className="hospital-form patient-details"
    >
      <div className="patient-details__header">
        <div className="patient-details__profile-info">
          <div className="patient-details__image">
            {/* <div className="patient-details__image">
            <img src={ ?? userPlaceholder} alt="" />
          </div> */}
            <DpChip name={patient?.fullname || ""}
              url={patient?.displayPictureUrl} />
          </div>
          <div className="patient-details__basic-info">
            <div className="patient-details__name text-capitalize">{(patient?.fullname) || (patient?.firstname + " " + patient?.lastname)}</div>
            <div className="patient-details__age">
              {patient?.dob ? getAgeText(patient?.dob) : "-"},{" "}
              {patient?.gender ?? "-"}
            </div>
            {patient?.languagesKnown && (
              <div className="patient-details__languages">
                <span className="icon icon-language" />{" "}
                {patient?.languagesKnown?.join(", ")}
              </div>
            )}
          </div>
        </div>
        <div className="patient-details__row">
          <div className="patient-details__label about">About</div>
          <div className="patient-details__content about">
            {patient?.bio ?? "Bio not available"}
          </div>
        </div>
      </div>

      <div className="patient-details__medical-info">
        <div className="patient-details__row">
          <div className="patient-details__label">Allergies</div>
          <div className="patient-details__content">
            {patient?.allergies}
            {/*<ul>
                                <li>Curabitur ac ligula et purus cursus vulputate accumsan.</li>
                                <li>Sit amet erat vestibulum ac mauris ut nisl maximus.</li>
                            </ul>*/}
          </div>
        </div>
        <div className="patient-details__row">
          <div className="patient-details__label">Past Medical Problems</div>
          <div className="patient-details__content">
            {patient?.pastMedicalProblems}
          </div>
        </div>
        <div className="patient-details__row">
          <div className="patient-details__label">Previous Surgeries</div>
          <div className="patient-details__content">
            {patient?.previousSurgeries}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PatientDetails;
