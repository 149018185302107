import React, { FC, useEffect, useState } from "react";
import "./journeyTemplateSteps.scss";
import JourneyTemplateStepDetails from "./JourneyTemplateStepDetails";
import { useHistory } from "react-router";
import { templateStepDropdownOptions } from "../../../../shared/utils/templateStepDropdownOptions";
import JourneyTemplateStepService from "../../../../services/JourneyTemplateService/journeyTemplateStep.service";
import DeleteConfirmation from "../../../../shared/components/DeleteConfirmation";
import JourneyStepService from "../../../../services/JourneyService/journeyStep.service";
import SkeletonLoader from "../../../../shared/components/SkeletonLoader";
import moment from "moment";
import JourneyCategories from "../../../../shared/components/JourneyCategories";
import { JourneyTemplateStep } from "../../../../models/JourneyTemplate/journeyTemplateStep.model";
import { useJourneyContext } from "../../../../context/JourneyContext";

interface JourneyTemplateStepsProps {
  steps?: Array<JourneyTemplateStep>;
  refreshSteps?: Function;
  customizeJourney?: boolean;
  loading?: boolean;
  editMode: boolean;
}

const JourneyTemplateSteps: FC<JourneyTemplateStepsProps> = (props) => {
  const { customizeJourney, loading, editMode } = props;

  const [showForm, setShowForm] = useState<boolean>(true);

  const {
    journeyDetails: { activeJourneyCategory },
  } = useJourneyContext();

  return (
    <div className="journey-template-steps">
      {loading ? (
        <div className="journey-template-steps__parent">
          {[...Array(3)].map((x, i) => (
            <div className="journey-template-step" key={i}>
              <SkeletonLoader rows={1} />
            </div>
          ))}
        </div>
      ) : (
        <JourneyCategories
          editMode={editMode}
          customizeJourney={customizeJourney} />
      )}
      {
        showForm && (
          <JourneyTemplateStepDetails customizeJourney={customizeJourney} />
        )
      }
    </div >
  );
};

export default JourneyTemplateSteps;
