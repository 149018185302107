import React, { FC, useEffect, useRef } from "react";
import "./richTextField.scss";
import { ErrorMessage, Field, useField } from "formik";
import Error from "../Error";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import axiosInstance, { getHeaders } from "../../../interceptor/axiosInstance";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";

import { Editor } from '@tinymce/tinymce-react';
import { IIdleTimer } from "react-idle-timer";
import { convertJSONToFormData } from "../../utils/dataFormatConverter";

interface RichTextFieldProps {
  name: string;
  editorData: string;
  setFieldValue: Function;
  idleTimer?: IIdleTimer;
}

const RichTextField: FC<RichTextFieldProps> = (props) => {
  const { name, editorData, setFieldValue, idleTimer } = props;

  const editorRef = useRef<any>(null);

  const richtextStyles = `
  body { 
    font-family:Nunito,sans-serif;
    font-size:14px 
  }
 
 .img-fluid {
      width: 100%;
      height: auto;
      max-width: 640px;
      min-width: 280px;
  }
`
  return (
    <div className="rich-text-field">
      <Field name={name}>
        {() => (
          <><Editor
            apiKey='hn69kd77i4pbxercu646hirzqfr26gyhr7fn2wf6gwvhschf'
            onInit={(evt, editor) => editorRef.current = editor}
            value={editorData || ""}
            onEditorChange={(data: string) => {
              idleTimer?.reset();
              setFieldValue(name, data)
            }}
            init={{
              height: 400,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | image media table ',
              // | help'
              content_style: richtextStyles,
              image_description: false,
              image_uploadtab: true,
              image_dimensions: false,
              image_class_list: [
                { title: 'Responsive', value: 'img-fluid' },
                { title: 'None', value: '' }
              ],
              media_live_embeds: true,
              table_use_colgroups: false,
              extended_valid_elements: "iframe[class|src|loading|frameborder=0|allow|allowfullscreen|width|height]",
              iframe_template_callback: (data: any) => `<iframe class="callback" width=${data.width} height=${data.height} src=${data.source} loading='lazy' frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>`,
              images_upload_handler: async (blob, progress) => {
                const payload = convertJSONToFormData({ richtext_image: new File([blob.blob()], blob.filename()) })
                const response = await axiosInstance.post(ApiRoutes.BASE_URL + ApiRoutes.RICH_TEXT_UPLOAD_IMAGE, payload)
                const { id, richtext_image_url } = response.data;
                const img = document.createElement('img');
                img.src = richtext_image_url;
                img.alt = `$${id}$`;
                img.setAttribute("class", "img-fluid")
                editorRef.current.insertContent(img.outerHTML);
                editorRef.current.windowManager.close();
                return '';
              },
            }}
          />
            <ErrorMessage name={name}>
              {(message: string) => <Error message={message} />}
            </ErrorMessage>
          </>
        )}
      </Field>
    </div>
  );
};

export default RichTextField;
