import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router-dom";
import { JourneyTemplateCategory } from "../../models/JourneyTemplate/journeyTemplateCategory.model";

const JourneyCategoryService = () => {
  const [journeyCategoryList, setJourneyCategoryList] = useState<
    JourneyTemplateCategory[]
  >([]);

  const fetchJourneyCategories = (id: number, search?: string) => {
    return axiosInstance
      .get(generatePath(ApiRoutes.JOURNEY_CATEGORIES, { id })+
      `${search ? "?search=" + search : ""}`)
      .then((response) => {
        const journeyCategories: any = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        setJourneyCategoryList(journeyCategories);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const createJourneyCategory = (
    id: number,
    data: JourneyTemplateCategory,
    onSuccess?: (category: JourneyTemplateCategory) => void
  ) => {
    const serializedData = serialize(JourneyTemplateCategory, data);
    return axiosInstance
      .post(generatePath(ApiRoutes.JOURNEY_CATEGORIES, { id }), serializedData)
      .then((response) => {
        const journeyCategory = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        setJourneyCategoryList([journeyCategory, ...journeyCategoryList]);
        Notification({
          message: "Success",
          description: "Journey category has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess && onSuccess(journeyCategory);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const updateJourneyCategory = (id: number, data: JourneyTemplateCategory) => {
    const serializedData = serialize(JourneyTemplateCategory, data);
    return axiosInstance
      .put(
        generatePath(ApiRoutes.JOURNEY_CATEGORIES, { id }) + "/" + data.id,
        serializedData
      )
      .then((response) => {
        const journeyCategory = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        Notification({
          message: "Success",
          description: "Journey category has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const updateJourneyCategoryOrder = (
    id: number,
    jounreyCategoryId: number,
    data: JourneyTemplateCategory
  ) => {
    const serializedData = serialize(JourneyTemplateCategory, data);
    return axiosInstance
      .put(
        generatePath(ApiRoutes.JOURNEY_CATEGORIES_ORDER, {
          id,
          jounreyCategoryId,
        }),
        serializedData
      )
      .then((response) => {
        const journeyCategory = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        Notification({
          message: "Success",
          description: "Journey category order has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const deleteJourneyCategory = (id: number, journeyCategoryId: number) => {
    return axiosInstance
      .delete(
        generatePath(ApiRoutes.JOURNEY_CATEGORIES, { id }) +
        "/" +
        journeyCategoryId
      )
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey category has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => { });
  };

  return {
    journeyCategoryList,
    fetchJourneyCategories,
    createJourneyCategory,
    deleteJourneyCategory,
    updateJourneyCategory,
    updateJourneyCategoryOrder,
  };
};

export default JourneyCategoryService;
