import React, { FC, useEffect, useRef, useState } from "react";
import "./faqSidebar.scss";
import { Divider, Dropdown, Menu } from "antd";
import FaqService from "../../../services/FaqService/faq.service";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import { MetaModel } from "../../../models/meta.model";
import { EllipsisOutlined } from "@ant-design/icons";
import FaqCategoryForm from "../FaqCategoryForm";
import { generatePath, useHistory } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

interface FaqSidebarProps {
  setActiveCategoryId: Function;
  activeCategoryId?: string;
  categoryList: MetaModel[];
  fetchCategories: Function;
}

const FaqSidebar: FC<FaqSidebarProps> = ({
  setActiveCategoryId,
  activeCategoryId,
  categoryList,
  fetchCategories
}) => {
  const {
    buttonLoading,
    deleteCategory,
    categoryLoading,
  } = FaqService();

  const [categoryFormVisible, setCategoryFormVisible] = useState<boolean>(false);

  const [activeCategory, setActiveCategory] = useState<MetaModel>();

  const [showDelete, setShowDelete] = useState(false);

  const selectedFaqRef = useRef<null | HTMLDivElement>(null);
  
  const history = useHistory();

  useEffect(() => {
    categoryList?.sort((a:MetaModel,b:MetaModel) => (a.label as string).localeCompare(b.label as string))
    !activeCategoryId && !activeCategory && categoryList?.length && categoryList[0] &&  setActiveCategoryId(categoryList[0].value);
    setTimeout(scrollToFaq,100)
  }, [categoryList])

  const scrollToFaq = () => {
    if(selectedFaqRef.current === null) { return }
    else{
      selectedFaqRef!.current!.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end"
      })

    }
  }

  const closeHandler = (response?: boolean) => {
    setCategoryFormVisible(false);
    response && fetchCategories();
  };

  const handleRefresh = () => {
    fetchCategories()
    const nextIndex = activeCategory === categoryList[0].value? 0: 1;
    setActiveCategoryId(categoryList[activeCategory === categoryList[nextIndex].value? 0: 1].value);
    history.push({pathname: generatePath(appRoutes.FAQ), hash:categoryList[nextIndex].value?.toString()});
  }

  return (
    <div className="faq-sidebar"
    //  ref={rootRef}
    >
      <div className="faq-sidebar__title add-new" key="add-new">
        <div
          className={`faq-title`}
          onClick={() => {
            setActiveCategory(undefined);
            setCategoryFormVisible(true);
          }}
        >
          + Add new category
        </div>
        <Divider />
      </div>
      {categoryList?.map((category, i) => {
       const categoryProps = category.value == activeCategoryId? {ref : selectedFaqRef} : {};
       return (
        <div className="faq-sidebar__title" key={i} id={category?.value} {...categoryProps}>
          <div
            className={`faq-title ${category?.value == activeCategoryId ? "active" : " "
              }`}
            onClick={() => setActiveCategoryId(category?.value)}
          >
            <span className="text-capitalize">{category.label}</span>
            <span>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => {
                        setActiveCategory(category);
                        setCategoryFormVisible(true);
                      }}
                    >
                      Edit
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={() => {
                        setActiveCategory(category);
                        setShowDelete(true);
                      }}
                    >
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomLeft"
                trigger={["click"]}
                className="manage-groups__details--action"
              >
                <EllipsisOutlined
                  className="table-menu"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </span>
          </div>
          <Divider />
        </div>
      )})}
      {categoryLoading
        //  || categoryCurrentPage * 10 < categoryTotalItems)
        && [...Array(5)].map((x) => (
          <div
            // ref={sentryRef}
            className={"chat__list__head"}>
            <SkeletonLoader avatar={false} rows={1} />
          </div>
        ))}
      <FaqCategoryForm
        activeCategory={activeCategory}
        visible={categoryFormVisible}
        closeHandler={(response) => closeHandler(response)}
        setActiveCategoryId={setActiveCategoryId}
      />

      <DeleteConfirmation
        handleDelete={() => activeCategory && deleteCategory(activeCategory)}
        setShowConfirmation={setShowDelete}
        showConfirmation={showDelete}
        entityName={"category"}
        buttonLoading={buttonLoading}
        key="delete-confirmation"
        refresh={handleRefresh}
      />
    </div>
  );
};

export default FaqSidebar;
