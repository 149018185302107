import React, {FC} from 'react';
import ButtonComponent from "../ButtonComponent";
import "./appHeader.scss";
import SearchComponent from "../SearchComponent";
import GoBack from '../GoBack';

interface AppHeaderProps {
    showGoBack?: boolean;
    iconClassName?: string;
    title: string;
    isSearchEnabled?: boolean;
    buttonText?: string;
    buttonHandler?: ()=>void;
    searchValue?: string;
    setSearchValue?: Function;
    buttonActionText?:string;
    handleSearch?: Function;
}

const AppHeader: FC<AppHeaderProps> = (props) => {
    const { iconClassName, title, isSearchEnabled, buttonText, buttonHandler, handleSearch, showGoBack, searchValue, setSearchValue, buttonActionText } = props;


    return (
        <div className="app-header">
            <div className="app-header__left">
                {showGoBack && <GoBack />}
                {iconClassName && <div className={`${iconClassName} app-header__icon`}/>}
                <div className="app-header__title">{title}</div>
            </div>
            <div className="app-header__right">
                {isSearchEnabled && <div className="app-header__search">
                <SearchComponent 
                    onSearch={handleSearch ? handleSearch : ()=>{}} 
                    searchValue={searchValue?? ""} 
                    setSearchValue={setSearchValue as Function}/>
                </div>}
                {buttonText && <div className="app-header__action">
                    <ButtonComponent text={`${buttonActionText ?? 'Add'} ${buttonText}`}
                                     className="app-header__button"
                                     key={"header-button"}
                                     type="primary"
                                     onClick={buttonHandler}
                    />
                </div>}
            </div>
        </div>
    )
}

export default AppHeader;