import React, { FC, useEffect, useRef, useState } from "react";
import "./chat.scss";
import { Col, Row, Divider } from "antd";
import SearchComponent from "../../shared/components/SearchComponent";
import userPlaceholder from "../../assets/userPlaceholder.png";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ChatMessages from "./ChatMessages";
import JourneyService from "../../services/JourneyService/journey.service";
import { Journey } from "../../models/Journey/journey.model";
import moment from "moment";
import { deserialize } from "serializr";
import { ChatMessagesModel } from "../../models/Chat/chat.model";
import SkeletonLoader from "../../shared/components/SkeletonLoader";
import Pusher from "pusher-js";
import { getAgeText } from "../../shared/utils/dateHelpers";
import DpChip from "../../shared/components/DpChip";
import { useHistory } from "react-router-dom";

interface ChatProps { }

const Chat: FC<ChatProps> = (props) => {
  const { fetchJourneys, tableLoading, journeyList, currentPage, totalItems } =
    JourneyService();

  const history = useHistory();
  const currentChatId = history.location.hash.replace('#','');
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [chatList, setChatList] = useState<Journey[]>([]);
  const [currentChat, setCurrentChat] = useState<Journey>();
  const [chatsWithNewMessages, setChatsWithNewMessages] = useState<number[]>(
    []
  );
  const [socketMessage, setSocketMessage] = useState<ChatMessagesModel>();
  const [searchText, setSearchText] = useState<string>("");
  const currentUser = JSON.parse(localStorage.getItem("user") || "");
  // will be used in future for push notification
  // const socketBaseUrl = `${process.env["REACT_APP_SOCKET_BASE_URL"]}/ws/notification/notification_employee_${currentUser?.id}/`;
  // const headWebsocketRef = useRef<any>(null);

  useEffect(() => {
    fetchJourneys({ sort_by: "last_message_at" }, 1, 10).then();

    // headWebsocketRef.current = new WebSocket(socketBaseUrl+`?token=${currentUser?.accessToken ?? ''}`);
    // headWebsocketRef.current.onopen = () => console.log("ws opened");
    // headWebsocketRef.current.onmessage = (e:any) => {
    //     const socketData = JSON.parse(e.data);
    //     setSocketMessage(deserialize(ChatMessagesModel,socketData['message']));
    // };

    // headWebsocketRef.current.onclose = (w:any) => console.log("ws closed",w);
    const pusher = new Pusher("41366f66303a6eadfbca", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe(
      `chat_notification_employee${currentUser?.id}`
    );
    channel.bind("message", (data: any) => {
      setSocketMessage(deserialize(ChatMessagesModel, data));
    });
    return (() => pusher.unsubscribe(`chat_notification_employee${currentUser?.id}`))
  }, []);

  useEffect(() => {
    setChatList([...chatList, ...journeyList].filter(chat => chat.status !== "draft"));
  }, [journeyList]);

  useEffect(() => {
    // to open selected chat or first chat on initial load
    if (currentPage === 1 && totalItems > 0 && journeyList.length)
    setCurrentChat(journeyList.filter(a => a.id?.toString() === currentChatId)[0] || chatList[0]);
    setHasNextPage(currentPage * 10 < totalItems);
  }, [journeyList, currentPage, totalItems]);

  useEffect(() => {
    // logic to remove the current chat id from chatsWithNewMessages
    currentChat && history.replace({
      pathname: history.location.pathname,
      hash: currentChat.id?.toString()
    })
    if (currentChat?.id)
      setChatsWithNewMessages(
        chatsWithNewMessages.filter((val) => val !== currentChat.id)
      );
  }, [currentChat]);

  useEffect(() => {
    if (socketMessage?.journeyId) {
      // to set the indicator if not from current chat. current chat will be listened in a separate socket
      if (socketMessage?.journeyId !== currentChat?.id) {
        setChatsWithNewMessages(
          Array.from(
            new Set([...chatsWithNewMessages, socketMessage?.journeyId])
          )
        );
      }

      // to reorder or include the chat to the list
      const chatIndex = chatList.findIndex(
        (chat) => chat.id === socketMessage?.journeyId
      );
      chatIndex > -1
        ? setChatList([...chatList.splice(chatIndex, 1), ...chatList])
        : setChatList([
          // socketMessage?.journey, 
          ...chatList]);
    }
  }, [socketMessage]);

  const loadMore = () => {
    fetchJourneys({ sort_by: "last_message_at" }, currentPage + 1, 10).then();
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: tableLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: "0px 0px 20px 0px",
  });

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    setChatList([]);
    fetchJourneys(
      { search: searchText, sort_by: "last_message_at" },
      1,
      10
    ).then();
  };

  return (
    <div className="app-wrapper chat">
      <Row>
        <Col span={7}>
          <div className={"chat__list"}>
            <div className={"chat__list__header"}>
              <i className={"icon-chat"} /> ALL JOURNEYS ({totalItems})
            </div>
            <div className={"chat__list__search"}>
              <SearchComponent onSearch={handleSearch} searchValue={searchText} setSearchValue={setSearchText} />
            </div>
            <div className={"chat__list__head-container"} ref={rootRef}>
              {chatList.map((chat, i) => (
                <>
                  <div
                    className={`chat__list__head ${chat?.id === currentChat?.id && "active"}`}
                    key={i + 1}
                    onClick={() => {
                      history.push({pathname: history.location.pathname, hash: chat.id?.toString()})
                      setCurrentChat(chat)
                    }}
                  >
                    <div className={"chat__head__patient-image"}>
                      <DpChip name={chat?.patient?.name || ""}
                        size="small"
                        url={chat?.patient?.displayPictureUrl} />
                    </div>
                    <div className={"chat__head__patient-info"}>
                      <div className={"chat__head__patient-name text-capitalize"}>
                        {chat?.patient?.name || ""}
                      </div>
                      <div className={"chat__head__patient-age"}>
                        <section>{chat?.patient?.dob
                          ? getAgeText(chat?.patient?.dob)
                          : "-"}</section>
                        <section className="text-capitalize">{chat?.patient?.gender ?? "-"}</section>
                      </div>
                    </div>
                    <div className={"chat__head__patient-surgery text-capitalize"}>
                      {chat?.name}
                    </div>
                  </div>
                  <Divider />
                </>
              ))}
              {(tableLoading || hasNextPage) &&
                [...Array(5)].map((x) => (
                  <div ref={sentryRef} className={"chat__list__head"}>
                    <SkeletonLoader avatar={true} rows={1} />
                  </div>
                ))}
            </div>
          </div>
        </Col>
        <Col span={17}>
          {currentChat && <ChatMessages currentChat={currentChat} />}
        </Col>
      </Row>
    </div>
  );
};

export default Chat;
