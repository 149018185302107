import React, {FC, useEffect, useState} from 'react'
import "./locationForm.scss"
import {Modal} from "antd";
import {Form, Formik} from "formik";
import {locationValidationSchema} from "./locationFormValidation";
import InputField from "../../../../../shared/components/InputField";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import {Location} from "../../../../../models/Hospital/location.model";
import DropdownField from "../../../../../shared/components/DropdownField";
import LocationService from "../../../../../services/HospitalService/location.service";
import {useParams} from "react-router-dom";

interface LocationFormProps {
    showForm: boolean;
    setShowForm: (showForm:boolean)=>void;
    location: Location;
    statusMeta: { value:string, label:string }[];
    refreshList?:Function;
}

const LocationForm: FC<LocationFormProps> = (props) => {
    const { showForm, setShowForm, location, statusMeta, refreshList } = props;

    const {id} = useParams();

    const {createLocation, buttonLoading, updateLocation} = LocationService();

    const [initialValues,setInitialValues] = useState<Location>(
        {
            name:"",
            location:"",
            status:""
        }
    );

    useEffect(()=>{
        setInitialValues(
            {
                name: location?.name,
                location: location?.location,
                status: location?.status,
            }
        )
    },[location])

    const onSubmit = (values: Location, formProps: { resetForm: () => void; }) => {
        if(Object.keys(location).length){
            updateLocation(id,values,location?.id).then(()=>{
                setShowForm(false);
                formProps.resetForm();
                if(refreshList) refreshList(id);
            });
        }else{
            createLocation(id,values).then(()=>{
                setShowForm(false);
                formProps.resetForm();
                if(refreshList) refreshList(id);
            });
        }
    };

    return (
        <Modal title={null}
               visible={showForm}
               onCancel={()=>setShowForm(false)}
               footer={null}
               maskClosable = {false}
               className="location-form hospital-form"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={locationValidationSchema}
                enableReinitialize
            >
                {({
                      values,
                      errors,
                      isValid,
                      dirty,
                      setFieldValue,
                      setFieldTouched,
                  }) =>
                {
                    return <Form>
                        <div className="hospital-form__header">
                            {Object.keys(location).length ? "Edit" : "New"} Location
                        </div>

                        <div className="hospital-form__row">
                            <div className="hospital-form__field">
                                <div className="form-label">Name</div>
                                <InputField type="text" name="name" placeholder="Enter name"/>
                            </div>
                        </div>

                        <div className="hospital-form__row">
                            <div className="hospital-form__field">
                                <div className="form-label">Location</div>
                                <InputField type="text" name="location" placeholder="Enter Location"/>
                            </div>
                        </div>

                        <div className="hospital-form__row">
                            <div className="hospital-form__field">
                                <div className="form-label">Status</div>
                                <DropdownField name="status"
                                               options={statusMeta}
                                               placeHolder="Select status"
                                               showArrow={true}
                                               value={values?.status}
                                               className="statusId__dropdown"
                                               onChange={(value)=>{setFieldValue("status",value)}}
                                />
                            </div>
                        </div>

                        <div className="hospital-form__actions">
                            <ButtonComponent className='hospital-form__action'
                                             type="text"
                                             text="Cancel"
                                             onClick={()=>setShowForm(false)}
                            />
                            <ButtonComponent htmlType="submit"
                                             loading={buttonLoading}
                                             className='hospital-form__action'
                                             type="primary"
                                             text={Object.keys(location).length ? "Update" : "Save"}
                                             disabled={!isValid}
                            />
                        </div>
                    </Form>
                }
                }
            </Formik>
        </Modal>
    )
}

export default LocationForm;