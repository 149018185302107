import React, { FC, useEffect, useRef, useState } from "react";
import "./chatMessages.scss";
import ChatHeader from "./ChatHeader";
import ChatPanel from "./ChatPanel";
import { Journey } from "../../../models/Journey/journey.model";
import { deserialize } from "serializr";
import { ChatMessagesModel } from "../../../models/Chat/chat.model";
import Pusher from "pusher-js";
interface ChatMessagesProps {
  currentChat: Journey;
}

const ChatMessages: FC<ChatMessagesProps> = (props) => {
  const { currentChat } = props;

  const [newMessage, setNewMessage] = useState<ChatMessagesModel>();

  //   const socketBaseUrl = `${process.env["REACT_APP_SOCKET_BASE_URL"]}/ws/chat/journey_chat_${currentChat?.id}/`;
  //   const currentUser = JSON.parse(localStorage.getItem("user") || "");
  //   const chatWebsocketRef = useRef<any>(null);

  useEffect(() => {
    if (currentChat?.id) {
      //   chatWebsocketRef?.current?.close();
      //   chatWebsocketRef.current = new WebSocket(
      //     socketBaseUrl + `?token=${currentUser?.accessToken ?? ""}`
      //   );
      //   chatWebsocketRef.current.onopen = () => console.log("ws opened");
      //   chatWebsocketRef.current.onclose = (w: any) =>
      //     console.log("ws closed", w);
      //   chatWebsocketRef.current.onerror = (w: any) =>
      //     console.log("wss error", w);
      //   chatWebsocketRef.current.onmessage = (e: any) => {
      //     const socketData = JSON.parse(e.data);
      //     setNewMessage(deserialize(ChatMessagesModel, socketData["message"]));
      //   };
      if (currentChat?.id) {
        const pusher = new Pusher(process.env.REACT_APP_SOCKET_PUSHER_TOKEN as string, {
          cluster: "ap2",
        });
        const channel = pusher.subscribe(`chat_room_${currentChat?.id}`);
        channel.bind("message", (data: any) => {
          setNewMessage(deserialize(ChatMessagesModel, data));
        });
      return (() => pusher.unsubscribe(`chat_room_${currentChat?.id}`))
      }
    }
  }, [currentChat]);

  return (
    <div className="chat-messages">
      <ChatHeader currentChat={currentChat} />
      <ChatPanel {...{ currentChat, newMessage }} />
    </div>
  );
};

export default ChatMessages;
