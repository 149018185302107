import React, { FC, useEffect, useState } from "react";
import "./patientForm.scss";
import { Form, Formik } from "formik";
import { patientValidationSchema } from "./patientFormValidation";
import { Modal } from "antd";
import { parsePhoneNumber } from "react-phone-number-input";
import moment from "moment";
import { Patient } from "../../../../models/Patient/patient.model";
import PatientService from "../../../../services/PatientService/patient.service";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import DatepickerField from "../../../../shared/components/DatepickerField";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import PhoneNumberInput from "../../../../shared/components/PhoneNumberInput";
import { genders } from "../../../../shared/utils/genderDropdownOptions";
import { useHistory, useLocation } from "react-router-dom";

interface PatientFormProps {
  showForm: boolean;
  hospitalId: string | number;
  setShowForm: (showForm: boolean) => void;
  patient: Patient;
  refreshList?: Function;
  refreshDetails?: Function;
}

const PatientForm: FC<PatientFormProps> = (props) => {
  const { showForm, setShowForm, patient, refreshList, hospitalId, refreshDetails } = props;
  const metaPageSize = 10;
  const location = useLocation();
  const { createPatient, updatePatient, patientButtonLoading } =
    PatientService();

  const [initialValue, setInitialValue] = useState<Patient>(new Patient());

  const history = useHistory();

  useEffect(() => {
    setInitialValue({
      id: patient?.id,
      firstname: patient?.firstname,
      lastname: patient?.lastname,
      gender: patient?.gender,
      dob: patient?.dob,
      email: patient?.email,
      phone: patient?.phone ? patient.isdCode + patient?.phone : "",
    });
  }, [patient]);

  const onSubmit = async (
    data: Patient,
    formProps: { resetForm: () => void }
  ) => {
    const values = { ...data };
    if (values.phone) {
      values.isdCode = "+" + parsePhoneNumber(values.phone)
        ?.countryCallingCode as any;
      values.phone = parsePhoneNumber(values.phone)?.nationalNumber as any;
    }
    const response = values.id
      ? await updatePatient(hospitalId, values)
      : await createPatient(hospitalId, values);
    if (response) {
      formProps.resetForm()
      values.id ?? Modal.confirm({
        title: null,
        icon: null,
        width: "40%",
        className: "ant-confirm-modal",
        content: (
          <div style={{ fontWeight: "normal", fontSize: "1.2rem" }}>
            Patient has been created successfully. Do you wish to create a
            caregiver?
          </div>
        ),
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => {
          setShowForm(false);
          history.push({
            pathname: history.location.pathname,
            search: "?page=1",
            hash: "caretaker",
            state: {
              showForm: true,
              selectedTab: "caretaker"
            },
          });
        },
        onCancel: () => {
          formProps.resetForm();
          refreshList && refreshList(hospitalId);
          refreshDetails && refreshDetails(hospitalId,response?.id)
        },
      });
      setShowForm(false);
      refreshList && refreshList(hospitalId);
      refreshDetails && refreshDetails(hospitalId, values.id)
    } else return;
  };

  return (
    <Modal
      title={null}
      visible={showForm}
      onCancel={() => {
        setShowForm(false);
      }}
      maskClosable = {false}
      footer={null}
      className="hospital-form caretaker-form"
      destroyOnClose
    >
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={patientValidationSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          resetForm,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className="hospital-form__header">
              {Object.keys(patient).length ? "Edit" : "New"} Patient
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">First Name</div>
                <InputField
                  type="text"
                  name="firstname"
                  placeholder="Enter first name"
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Last Name</div>
                <InputField
                  type="text"
                  name="lastname"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">Date of birth</div>
                <DatepickerField
                  name="dob"
                  showTime={false}
                  format={"YYYY-MM-DD"}
                  disableDates='future'
                  placeholder="Select date of birth"
                  value={values?.dob ? moment(values?.dob) : null}
                  onChange={(value, dateString) => {
                    setFieldValue("dob", dateString);
                  }}
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Sex</div>
                <DropdownField
                  name="gender"
                  options={genders}
                  placeHolder="Select gender"
                  showArrow={true}
                  value={values?.gender}
                  className="statusId__dropdown"
                  onChange={(value) => {
                    setFieldValue("gender", value);
                  }}
                />
              </div>
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">Email</div>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Enter email"
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Contact Number</div>
                <PhoneNumberInput
                  name={"phone"}
                  value={values.phone}
                  onChange={(value) => {
                    setFieldValue("phone", value);
                  }}
                />
              </div>
            </div>

            <div className="hospital-form__actions">
              <ButtonComponent
                className="hospital-form__action"
                type="text"
                text="Cancel"
                onClick={() => {
                  resetForm()
                  setShowForm(false);
                }}
              />
              <ButtonComponent
                htmlType="submit"
                loading={patientButtonLoading}
                className="hospital-form__action"
                type="primary"
                text={Object.keys(patient).length ? "Update" : "Create"}
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PatientForm;
