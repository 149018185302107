import React, { FC, useEffect, useState } from 'react'
import "./assignedPatients.scss"
import AppHeader from "../../../../shared/components/AppHeader";
import PatientService from "../../../../services/PatientService/patient.service";
import { Patient } from "../../../../models/Patient/patient.model";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import { generatePath, useParams } from "react-router-dom";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import { Menu, Tag } from "antd";
import { useHistory, useLocation } from "react-router";
import TableComponent from "../../../../shared/components/TableComponent";
import moment from "moment";
import AuthContainer from '../../../../store/container/AuthContainer';
import { AuthReducerProps } from '../../../../store/reducers/authReducer';
import { getFullName } from '../../../../shared/utils/dataFormatConverter';

interface AssignedPatientsProps extends AuthReducerProps {

}

const AssignedPatients: FC<AssignedPatientsProps> = (props) => {
    const { user, } = props;

    const {id }= useParams<{id: string, staffId: string}>()

    const history = useHistory();
    const location = useLocation<any>();
    const urlParams = new URLSearchParams(location.search);
    const page = parseInt(urlParams.get('page') || '1');
    const search = urlParams.get('search') || '';
    const { patientTableLoading, fetchStaffPatients, patientList, patientTotalItems } = PatientService();
    const [searchText, setSearchText] = useState<string>(search);

    useEffect(() => {
        id && 
        fetchStaffPatients(id ,{
            search: searchText
        }, page, 10).then();
    }, [location])

    const handlePageChange = (page: number, pageSize?: number) =>
        history.push({...location, search: `?page=${page}&search=${searchText}`});

    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
        history.push({...location, search: `?page=1&search=${searchText}`});
    }

    const columns = [
        {
            title: 'PATIENT NAME',
            key: 'name',
            render: (text: string, record: Patient) => (
                <>
                    <span className="text-capitalize">
                        {getFullName(record)}
                    </span>
                </>
            )
        },
        {
            title: 'SEX',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'DOB',
            dataIndex: 'dob',
            key: 'dob',
            render: (text: string, record: Patient) => record?.dob ? moment(record?.dob).format('MMMM DD,yyyy') : ''
        },
        {
            title: 'STATUS',
            key: 'status',
            dataIndex: 'status',
            render: (text: string, record: any) => (
                <Tag key={record?.id}
                    className={`app-table-tags ${record?.status ? "green" : "lime"}`}
                >
                    {record?.status ? 'Accepted' : 'Idle'}
                </Tag>
            )
        },
    ];

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => {
                //setShowPatientForm(true)
            }}
            >
                Edit
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="assigned-patients">
            <AppHeader title={"Assigned patients"}
                isSearchEnabled={true}
                handleSearch={handleSearch}
                searchValue={searchText}
                setSearchValue={setSearchText}
            />

            <TableComponent loading={patientTableLoading}
                data={patientList}
                columns={columns}
                onPageChange={handlePageChange}
                currentPage={page}
                total={patientTotalItems}
                handleRowClick={
                    (record: Patient) =>
                        history.push(generatePath(appRoutes.SHOW_PATIENT, { id: Number(record?.id) , hospitalId: Number(user?.hospitalId)}))
                }
            />
        </div>
    )
}

export default AuthContainer(AssignedPatients);