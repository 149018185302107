import React, { useEffect, useState } from "react";
import "./journeyFaq.scss";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import JourneyStepService from "../../../../../../services/JourneyService/journeyStep.service";
import { FaqModel } from "../../../../../../models/Faq/faq.model";
import RichTextViewer from "../../../../../../shared/components/RichTextViewer";
import { generatePath, useHistory } from "react-router-dom";
import * as appRoutes from "../../../../../../routes/routeConstants/appRoutes";
import EmptyState from "../../../../../../shared/components/EmptyState";

interface JourneyFAQProps {
  step?: JourneyTemplateStep;
}

function JourneyFAQ({ step }: JourneyFAQProps) {
  const history = useHistory();
  const [activeCategoryId, setActiveCategoryId] = useState<string>();
  const [filteredQuestions, setFilteredQuestions] = useState<FaqModel[]>([]);
  const {
    fetchJourneyFAQCategories,
    fetchJourneyQuestions,
    journeyFAQCategories,
    journeyQuestions,
    updateJourneyStep,
  } = JourneyStepService();

  useEffect(() => {
    if (!activeCategoryId && journeyFAQCategories?.length > 0) {
      setActiveCategoryId(journeyFAQCategories[0].value);
    }
  }, [journeyFAQCategories]);

  useEffect(() => {
    if (activeCategoryId) {
      setFilteredQuestions(
        journeyQuestions
          ? journeyQuestions.filter(
            (question) => question.categoryId === Number(activeCategoryId)
          )
          : []
      );
    }
  }, [activeCategoryId, journeyQuestions]);

  useEffect(() => {
    fetchJourneyFAQCategories(step?.id);
    fetchJourneyQuestions(step?.id);
  }, []);

  return (
    <div className="journey-faq">
      <div className={`journey-faq__categories ${!journeyFAQCategories?.length && 'no-shadow'}`}>
        {journeyFAQCategories?.length > 0
          ? journeyFAQCategories.map((category, i) => (
            <div
              className={`journey-faq__category-tile ${category.value === activeCategoryId ? "active" : ""
                }`}
              key={i}
              onClick={() => setActiveCategoryId(category.value)}
            >
              {category?.label}
            </div>
          ))
          : <EmptyState text="No FAQs found" />}
      </div>
      <div className="journey-faq__questions">
        <Collapse
          defaultActiveKey={[0]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition={"right"}
        >
          {filteredQuestions.map((faq, i) => (
            <Collapse.Panel header={faq?.title} key={i}>
              <div className="journey-faq__question-tags">
                {faq?.tags?.map((tag, i) => (
                  <span
                    className={`journey-faq__question-tag-item type-${(i % 3) + 1
                      }`}
                    key={i}
                  >
                    {tag?.label}
                  </span>
                ))}
              </div>
              <RichTextViewer borderless height={200} content={faq?.content} />
              <div className={"journey-faq__actions"}>
                <i
                  className={"icon-edit"}
                  onClick={() =>
                    faq?.categoryId && faq?.id && history.push(
                      generatePath(appRoutes.FAQ_FORM, {
                        categoryId: faq.categoryId,
                        id: faq.id,
                      })
                    )
                  }
                />
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}

export default JourneyFAQ;
