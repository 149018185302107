import { Col, Row } from 'antd'
import moment from 'moment'
import React, { FC } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { generatePath, useHistory } from 'react-router-dom'
import { Patient } from '../../../models/Patient/patient.model'
import * as  AppRoutes from '../../../routes/routeConstants/appRoutes'
import { getAgeText } from '../../utils/dateHelpers'
import DpChip from '../DpChip'
import "./JourneyPatientCard.scss"

interface JourneyPatientCardProps {
    patient: Patient;
    hospitalId: number | string;
    allowRedirect?: boolean;
}

const JourneyPatientCard: FC<JourneyPatientCardProps> = (props) => {

    const history = useHistory()

    const {
        patient,
        hospitalId,
        allowRedirect
    } = props

    const redirectToProfile = () => !allowRedirect && patient.id && history.push(generatePath(AppRoutes.SHOW_PATIENT, { hospitalId, id: patient.id }))

    return (
        <div className="journey-patient-card text-capitalize" onClick={redirectToProfile}>
            <DpChip
                url={patient.displayPictureUrl}
                size="mini"
                name={(patient.firstname || "") + " " + (patient.lastname || "")} />
            <div>
                <span>
                    <span className="journey-patient-card--title text-capitalize">
                        {(patient.firstname || "") + " " + (patient.lastname || "")}</span>,
                        <span className="journey-patient-card--age">{patient?.dob ? getAgeText(patient?.dob, true) : "-"},{" "}{patient?.gender?.substring(0,1) ?? "-"} </span>
                    <p className="journey-patient-card--dob">
                        DOB: {moment(patient?.dob).format("DD/MM/YYYY")},{" "}
                    </p>
                    <p className="journey-patient-card--mobile">
                        Tel # {formatPhoneNumberIntl((patient?.isdCode ? patient.isdCode : "") + patient.phone)}
                    </p>
                </span>
                
           
               
            </div>
        </div>
    )
}

export default JourneyPatientCard