import React, { FC, useEffect, useState } from "react";
import "./departments.scss";
import { Dropdown, Menu, Pagination } from "antd";
import { Department } from "../../../../models/Hospital/department.model";
import { EllipsisOutlined } from "@ant-design/icons";
import avatarPlaceholder from "../../../../assets/userPlaceholder.png";
import DepartmentForm from "./DepartmentForm";
import DeleteConfirmation from "../../../../shared/components/DeleteConfirmation";
import DepartmentDetails from "./DepartmentDetails";
import DepartmentService from "../../../../services/HospitalService/department.service";
import { useParams } from "react-router-dom";

interface DepartmentsProps {
  departmentList: Array<Department>;
  refreshDepartments: Function;
  currentDepartmentPage: number;
  totalDepartmentItems: number;
  handlePageChange: (a: number, b?: number) => void;
}

const Departments: FC<DepartmentsProps> = (props) => {
  const {
    handlePageChange,
    departmentList,
    refreshDepartments,
    currentDepartmentPage,
    totalDepartmentItems,
  } = props;

  const { id } = useParams();

  const { deleteDepartment, buttonLoading } = DepartmentService();

  useEffect(() => {
    refreshDepartments();
  }, []);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [currentDepartment, setCurrentDepartment] = useState<Department>({});
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const departmentPageSize = 9;

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setShowForm(true)}>
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="departments">
      <DepartmentDetails
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        departmentId={currentDepartment?.id}
      />

      <DepartmentForm
        showForm={showForm}
        setShowForm={setShowForm}
        department={currentDepartment}
        refreshList={refreshDepartments}
      />

      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        setShowConfirmation={setShowDeleteConfirmation}
        entityName={"Department"}
        handleDelete={() => deleteDepartment(id, currentDepartment?.id)}
        buttonLoading={buttonLoading}
        refresh={refreshDepartments}
      />

      {departmentList?.map((department: Department, i: number) => (
        <div
          className="manage-groups__details-card"
          key={i}
          onClick={() => {
            setCurrentDepartment(department);
            setShowDetails(true);
          }}
        >
          <div onClick={(event) => event?.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={["click"]}
              className="manage-groups__details--action"
              onVisibleChange={(visible) => {
                setCurrentDepartment(visible ? department : new Department());
              }}
            >
              <EllipsisOutlined />
            </Dropdown>
          </div>
          <div>
            <div className="manage-groups__details-card--name">
              {department.name}
            </div>
            <div className="manage-groups__details-card--location text-capitalize">
              <span className="icon-location" />
              {department?.location ?? "-"}
            </div>
          </div>
        </div>
      ))}
      {departmentList?.length > 0 && (
        <div className="departments__pagination">
          <Pagination
            onChange={handlePageChange}
            current={currentDepartmentPage}
            total={totalDepartmentItems}
            hideOnSinglePage={true}
            pageSize={departmentPageSize}
          />
        </div>
      )}
    </div>
  );
};

export default Departments;
