import React, { FC, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from '../../../store/container/AuthContainer';
import NotAuthorized from "../../../views/NotAuthorized";

const requireAuth = (Component: any, allowedRoles: any = []) => {
    const Authentication = (props: any) => {
        useEffect(() => {
            const { authenticated, history } = props;
            if (!authenticated && history.location.pathname !== appRoutes.LOGIN) {
                return history.push(appRoutes.LOGIN);
            }
        }, [props]);

        if (allowedRoles.length) {
            const { user } = props;
            return allowedRoles.includes(user?.role) ? <Component {...props} /> : <NotAuthorized />;
        }
        return <Component {...props} />
    }
    return withRouter(AuthContainer(Authentication));
};

export const isAuthenticated = (component: FC, allowedRoles?: Array<string>) => {
    return requireAuth(component, allowedRoles);
};

export default isAuthenticated;