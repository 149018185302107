import React, {useEffect} from 'react'
import "./customToolbar.scss"
import moment from "moment";
import AppHeader from "../../../shared/components/AppHeader";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {DatePicker} from "antd";

const CustomToolbar = (props: any) => {
    const { setViewState, setSelectedMonth, viewState, selectedMonth } = props;

    const calendarFormat = () => viewState === 'month' ? 'MMMM, YYYY' : 'w - MMMM, YYYY';

    const goToWeekView = () => {
        setViewState("week");
    };

    const goToMonthView = () => {
        setViewState("month");
    };

    useEffect(()=>{
        props.onView(viewState);
    },[viewState])

    const goToBack = () => {
        let view = viewState;
        let mDate = props.date;
        let newDate;
        if (view === "month") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );
        }
        props.onNavigate("prev", newDate);
        setSelectedMonth(moment(newDate))
    };

    const goToNext = () => {
        let view = viewState;
        let mDate = props.date;
        let newDate;
        if (view === "month") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
            );
        }
        props.onNavigate("next", newDate);
        setSelectedMonth(moment(newDate))
    };

    const goToToday = () => {
        const now = new Date();
        props.date.setMonth(now.getMonth());
        props.date.setYear(now.getFullYear());
        props.date.setDate(now.getDate());
        props.onNavigate("current");
        setSelectedMonth(moment());
    };

    return (
        <div className="custom-toolbar">
            <AppHeader iconClassName="icon-Calander"
                       title="My calendar"
            />

            <div className={"custom-toolbar__month"}>
                <DatePicker onChange={
                                (value:any, dateString:string) => {
                                    setSelectedMonth(value);
                                    props.onNavigate(dateString, new Date(value));
                                }
                            }
                            picker={viewState}
                            value={moment(selectedMonth,calendarFormat())}
                            allowClear={false}
                            format={calendarFormat()}
                            suffixIcon={null}
                />
            </div>

            <div className={"custom-toolbar__navigation"}>
                <span className="custom-toolbar__icon" id="prev-btn-icon" onClick={goToBack}>
                  <LeftOutlined />
                </span>

                <span className="custom-toolbar__icon" id="next-btn-icon" onClick={goToNext}>
                  <RightOutlined />
                </span>

                <ButtonComponent text={"Today"}
                                 onClick={goToToday}
                                 type={"text"}
                                 className={"custom-toolbar__view-today"}
                />
            </div>

            <div className="custom-toolbar__view-control">
                <ButtonComponent text={"Week"}
                                 onClick={goToWeekView}
                                 type={viewState === "week" ? "primary" : "text"}
                                 className={"custom-toolbar__view-toggle"}
                />
                <ButtonComponent text={"Month"}
                                 onClick={goToMonthView}
                                 type={viewState === "month" ? "primary" : "text"}
                                 className={"custom-toolbar__view-toggle"}
                />
            </div>
        </div>
    );
}

export default CustomToolbar;