import {useState} from "react";
import {Staff} from "../../models/Hospital/staff.model";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {deserialize, serialize} from "serializr";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {convertJSONToFormData} from "../../shared/utils/dataFormatConverter";
import {PaginationModel} from "../../models/pagination.model";
import { generatePath } from "react-router-dom";

const StaffService = () => {

    const [staff, setStaff] = useState<Staff>({});

    const [staffList, setStaffList] = useState<Array<Staff>>([]);

    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [totalItems, setTotalItems] = useState<number>(0);
    const [validEmail, setValidEmail] = useState<boolean>(false);

    const fetchStaffs = (queryParams:any = {}, page?:number, pageSize?:number,isMeta?:boolean) => {
        setTableLoading(true);
        let apiUrl = ApiRoutes.EMPLOYEES +
            `?${Object.keys(queryParams).length?`${Object.keys(queryParams).map(o => o+"="+queryParams[o]).join('&')}&`:''}${page ? `page=${page}&page_size=${pageSize}`:''}`;
        return axiosInstance
            .get(apiUrl)
            .then((response) => {
                const employees:any = deserialize(Staff, isMeta? response.data : response.data['results']);
                const paginationData = deserialize(PaginationModel,response.data);
                setStaffList(employees);
                setCurrentPage(paginationData?.page);
                setTotalItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const showStaff = (id?:string) => {
        setTableLoading(true)
        return axiosInstance
            .get(ApiRoutes.EMPLOYEES+`/${id}`)
            .then((response) => {
                const employee:Staff = deserialize(Staff, response.data);
                setStaff(employee)
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const createStaff = (data:Staff) => {
        setButtonLoading(true);
        const staffFormData = convertJSONToFormData(data);
        return axiosInstance
            .post(ApiRoutes.EMPLOYEES,staffFormData)
            .then((response) => {
                const staff = deserialize(Staff, response.data);
                setStaffList([...staffList,staff]);
                Notification({
                    message: "Success",
                    description: "Staff has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const updateStaff = (data:Staff,id?:string) => {
        setButtonLoading(true);
        const staffFormData = convertJSONToFormData(data);
        return axiosInstance
            .put(ApiRoutes.EMPLOYEES+`/${id}`,staffFormData)
            .then(() => {
                Notification({
                    message: "Success",
                    description: "Staff has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const deleteStaff = (id?:string) => {
        setButtonLoading(true);
        return axiosInstance
            .delete(ApiRoutes.EMPLOYEES+`/${id}`)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Employee has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const checkEmail = (id: string, email: string) => {
        const apiUrl = generatePath(ApiRoutes.CHECK_EMAIL, { hospitalId: id }) + `?email=${email}`;
        return axiosInstance
            .get(apiUrl)
            .then(res => {
                const validity = res.data.message? false: true;
                !validity && Notification({
                    message: "Email is already Taken!",
                    description: "",
                    type: NotificationTypes.ERROR});
                setValidEmail(validity);
            })
    }


    return{
        staffList,
        staff,
        tableLoading,
        buttonLoading,
        fetchStaffs,
        showStaff,
        createStaff,
        updateStaff,
        deleteStaff,
        currentPage,
        totalItems,
        checkEmail,
        validEmail
    }
}

export default StaffService;