import React, { Dispatch, FC } from "react";
import "./journeyTemplateHeader.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import JourneyTemplateForm from "./JourneyTemplateForm";
import SkeletonLoader from "../../../../shared/components/SkeletonLoader";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { JourneyTemplate } from "../../../../models/JourneyTemplate/journeyTemplate.model";
import { useLocation } from "react-router-dom";
import GoBack from "../../../../shared/components/GoBack";

interface JourneyTemplateHeaderProps {
  template: JourneyTemplate;
  loading: boolean;
  editMode: boolean;
  setEditMode: Dispatch<boolean>;
  refreshTemplate: () => void
}

const JourneyTemplateHeader: FC<JourneyTemplateHeaderProps> = (props) => {
  const { template, loading, editMode, setEditMode, refreshTemplate } = props;

  const history = useHistory();
  const {state} = useLocation<{currentPage: number, search: string}>();
  const page = state? state.currentPage : 1;
  const search = state? state.search: "";
  return (
    <div className="journey-template-header">
      {loading ? (
        <SkeletonLoader rows={2} />
      ) : (
        <>
          {!editMode ? (
            <>
              <div className="journey-template-header__name text-capitalize">
              <GoBack route={{pathname: AppRoutes.JOURNEY_TEMPLATES, search: `?page=${page}&${search !== ''? `search=${search}`: ''}`, state: {currentPage: page, search: search}}}/>
                <div className="name text-capitalize">{template?.name}</div>
                <div className="edit-btn" onClick={() => setEditMode(true)}>
                  <i className="icon-edit-1"></i>
                </div>
              </div>
              <div className="journey-template-header__details">
                <div className="journey-template-header__numbers">
                  {template?.noOfSteps} Steps
                </div>
                {!!template.tags?.length && <div className="journey-template-header__numbers">
                  {template.tags?.map((tag) => tag.label)?.join(", ")}
                </div>}
              </div>
            </>
          ) : (
            <JourneyTemplateForm
              template={template}
              refreshTemplate={refreshTemplate}
              setEditMode={setEditMode} />
          )}
        </>
      )}
    </div>
  );
};

export default JourneyTemplateHeader;
