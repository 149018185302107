import {useState} from "react";
import {Journey} from "../../models/Journey/journey.model";
import axiosInstance from "../../interceptor/axiosInstance";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import {deserialize, serialize} from "serializr";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {PaginationModel} from "../../models/pagination.model";
import {useHistory} from "react-router";
import {generatePath} from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import {convertJSONToFormData} from "../../shared/utils/dataFormatConverter";
import {ChatMessagesModel} from "../../models/Chat/chat.model";

const ChatService = () => {

    const [messagesLoading, setMessagesLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [totalItems, setTotalItems] = useState<number>(0);

    const fetchMessages = (journeyId: number, params:{} = {}) => {
        setMessagesLoading(true);
        let apiUrl = generatePath(ApiRoutes.CHAT_MESSAGES,{id:journeyId});
        return axiosInstance
            .get(apiUrl,{params: params})
            .then((response) => {
                const paginationData = deserialize(PaginationModel,response.data);
                setMessagesLoading(false);
                setCurrentPage(paginationData?.page ?? 1);
                setTotalItems(paginationData?.total);
                const messagesList:any =  deserialize(ChatMessagesModel, response.data['results']);
                return messagesList;
            })
            .catch((error) => {
                Notification({
                    message: "Failed",
                    description: error,
                    type: NotificationTypes.ERROR,
                });
            })
            .finally(() => {
                setMessagesLoading(false)
            });
    }

    const createMessage = (journeyId: number, data:ChatMessagesModel) => {
        setButtonLoading(true);
        let messageData = serialize(ChatMessagesModel,data);
        messageData = Object.fromEntries(Object.entries(messageData).filter(([_, v]) => !!v));
        const messageFormData = convertJSONToFormData(messageData)
        return axiosInstance
            .post(generatePath(ApiRoutes.CHAT_MESSAGES,{id:journeyId}),messageFormData)
            .then(() => { /* on success, message will be received using the socket */})
            .catch((error) => {
                Notification({
                    message: "Failed",
                    description: error,
                    type: NotificationTypes.ERROR,
                });
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    return{
        messagesLoading,
        buttonLoading,
        fetchMessages,
        createMessage,
        currentPage,
        totalItems
    }
}

export default ChatService;