import React, { FC } from 'react'
import "./notAuthorized.scss"
import appLogo from "../../assets/sidebar-logo.png";

interface NotAuthorizedProps {

}

const NotAuthorized: FC<NotAuthorizedProps> = ({}) => {
    return (
        <div className="app-wrapper not-authorized">
            <div className="not-authorized__content">
                <img src={appLogo} alt="logo-"/>
                <div>
                    You are not authorized to view this page
                </div>
            </div>
        </div>
    )
}

export default NotAuthorized;