import { useEffect, useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { generatePath } from "react-router-dom";
import { FaqModel } from "../../models/Faq/faq.model";
import { MetaModel } from "../../models/meta.model";
import { useHistory } from "react-router";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { removeDuplicates } from "../../shared/utils/dataFormatConverter";

const FaqService = () => {
  const history = useHistory();

  const [faq, setFaq] = useState<FaqModel>();

  const [faqList, setFaqList] = useState<Array<FaqModel>>([]);

  const [categoryList, setCategoryList] = useState<Array<MetaModel>>([]);

  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);

  const [faqLoading, setFaqLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  // const [categoryCurrentPage, setCategoryCurrentPage] = useState<number>(0);

  // const [categoryTotalItems, setCategoryTotalItems] = useState<number>(0);

  const [faqCurrentPage, setFaqCurrentPage] = useState<number>(0);

  const [faqTotalItems, setFaqTotalItems] = useState<number>(0);

  const fetchCategories = (params: {} = {}) => {
    setCategoryLoading(true);
    return axiosInstance
      .get(ApiRoutes.FAQ_CATEGORIES, { params: params })
      .then((response) => {
        const categories: any = deserialize(
          MetaModel,
          response.data["categories"]
        );
        setCategoryList(categories);
      })
      .catch((error) => { })
      .finally(() => {
        setCategoryLoading(false);
      });
  };

  const fetchFaqs = (
    categoryId?: string,
    params: {} = {},
    refresh?: boolean
  ) => {
    setFaqLoading(true);
    return axiosInstance
      .get(generatePath(ApiRoutes.FAQS, { categoryId }), { params: params })
      .then((response) => {
        const faqs: any = deserialize(FaqModel, response.data["results"]);
        const paginationData = deserialize(PaginationModel, response.data);
        setFaqList(refresh ? [...faqs] : [...faqList, ...faqs]);
        setFaqCurrentPage(paginationData?.page);
        setFaqTotalItems(paginationData?.total);
      })
      .catch((error) => { })
      .finally(() => {
        setFaqLoading(false);
      });
  };

  const fetchSearchFaqs = ( categoryId?: string, params: {} = {}, refresh?: boolean) => {
      setFaqLoading(true);
      return axiosInstance
        .get(generatePath(ApiRoutes.FAQ_SEARCH), { params: params })
        .then(res => {
          const questions = deserialize(FaqModel, res.data)
          const categories = (questions as Array<FaqModel>).map(a => {
            return {value: a.categoryId?.toString(), label : a.categoryName}})
          const faqs = (questions as Array<FaqModel>).filter(a => a.categoryId?.toString() === categoryId?.toString());
          if(categories.length){
            setCategoryList(removeDuplicates(categories))
            setFaqList(refresh ? [...faqs] : [...faqList, ...faqs]);
            }
            else{
                Notification({
                    message: "Fail",
                    description: "No Question exists with this Keyword!",
                    type: NotificationTypes.ERROR,
                });
                setFaqList([])
            }
        })
        .catch((error) => { })
      .finally(() => {
        setFaqLoading(false);
      });
  }

  const createFaq = (data: FaqModel, categoryId: string, resetForm: () => void, disableNotification?: boolean) => {
    setButtonLoading(true);
    const faqData = serialize(FaqModel, data);
    return axiosInstance
      .post(generatePath(ApiRoutes.FAQS, { categoryId }), faqData)
      .then((response) => {
        const faq = deserialize(FaqModel, response.data);
        setFaqList([...faqList, faq]);
        !disableNotification && Notification({
          message: "Success",
          description: "FAQ has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        return faq;
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const showFaq = (categoryId: string, id: string) => {
    setFaqLoading(true);
    return axiosInstance
      .get(generatePath(ApiRoutes.FAQS, { categoryId }) + `/${id}`)
      .then((response) => {
        const faq = deserialize(FaqModel, response.data);
        setFaq(faq);
      })
      .catch((error) => { })
      .finally(() => {
        setFaqLoading(false);
      });
  };

  const updateFaq = (data: FaqModel, categoryId: string, id: string, resetForm: () => void, disableNotification?: boolean) => {
    setButtonLoading(true);
    const faqData = serialize(FaqModel, data);
    return axiosInstance
      .put(generatePath(ApiRoutes.FAQS, { categoryId }) + `/${id}`, faqData)
      .then(() => {
        !disableNotification && Notification({
          message: "Success",
          description: "FAQ has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return faq;
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const deleteFaq = (categoryId?: string, id?: number) => {
    setButtonLoading(true);
    return axiosInstance
      .delete(generatePath(ApiRoutes.FAQS, { categoryId }) + `/${id}`)
      .then(() => {
        Notification({
          message: "Success",
          description: "FAQ has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
        setFaqList([...faqList.filter((faq) => faq.id !== id)]);
      })
      .catch(() => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const updateFaqOrder = (
    data: FaqModel,
    categoryId: string,
    questionId: string
  ) => {
    setFaqLoading(true);
    return axiosInstance
      .put(generatePath(ApiRoutes.FAQS_ORDER, { categoryId, questionId }), data)
      .then((res) => {
        Notification({
          message: "Success",
          description: "Faq order has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return res;
      })
      .finally(() => setFaqLoading(false));
  };

  const createCategories = (name: string) => {
    setButtonLoading(true);
    return axiosInstance
      .post(ApiRoutes.FAQ_CATEGORIES, { name })
      .then((response) => {
        Notification({
          message: "Success",
          description: "Category has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        return response.data
      })
      .catch((error) => {
      })
      .finally(() => {
        setButtonLoading(false)
      });
  }

  const updateCategories = (category: MetaModel) => {
    const categoryJSON = serialize(category);
    setButtonLoading(true);
    return axiosInstance
      .put(ApiRoutes.FAQ_CATEGORIES + "/" + category?.value, categoryJSON)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Category has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return response.data
      })
      .catch((error) => {
      })
      .finally(() => {
        setButtonLoading(false)
      });
  }

  const deleteCategory = (category: MetaModel) => {
    setButtonLoading(true);
    return axiosInstance
      .delete(ApiRoutes.FAQ_CATEGORIES + "/" + category?.value)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Category has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
        return response.data
      })
      .catch((error) => {
      })
      .finally(() => {
        setButtonLoading(false)
      });
  }

  return {
    categoryList,
    fetchCategories,
    categoryLoading,
    fetchSearchFaqs,
    fetchFaqs,
    faqList,
    faqLoading,
    faqCurrentPage,
    faqTotalItems,
    createFaq,
    buttonLoading,
    showFaq,
    faq,
    updateFaq,
    deleteFaq,
    createCategories,
    updateCategories,
    updateFaqOrder,
    deleteCategory,
  };
};

export default FaqService;
