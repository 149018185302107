import React, { FC, useEffect, useState } from 'react'
import "./patientsAndCaretakers.scss"
import { Tabs } from 'antd';
import PatientsList from "./PatientsList";
import CareTakerForm from "./CaretakerList/CaretakerForm";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import CaretakerList from './CaretakerList';
import PatientForm from './PatientsList/PatientForm';
import CaretakerService from '../../services/CaretakerService/caretaker.service';
import StaffService from '../../services/HospitalService/staff.service';
import PatientService from '../../services/PatientService/patient.service';
import ButtonComponent from '../../shared/components/ButtonComponent';
import SearchComponent from '../../shared/components/SearchComponent';
import AuthContainer from '../../store/container/AuthContainer';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import AppHeader from '../../shared/components/AppHeader';

const { TabPane } = Tabs;

interface PatientsAndCaretakersProps extends AuthReducerProps {

}

const PatientsAndCaretakers: FC<PatientsAndCaretakersProps> = (props) => {
    const { user, } = props;

    const history = useHistory()
    
    const location = useLocation<any>();
    const urlParams = new URLSearchParams(location.search)
    const page = parseInt(urlParams.get('page')|| '1');
    const key = location?.state?.selectedKey || location.hash.substring(1) || "patient";
    const search = urlParams.get('search') || '';
    const showForm = location?.state?.showForm;

    const { fetchPatients, patientTableLoading, patientList, patientTotalItems } = PatientService();
    const { fetchCaretakers, caretakerTableLoading, caretakerList, caretakerCurrentPage, caretakerTotalItems } = CaretakerService();

    const [selectedKey, setSelectedKey] = useState<string>(key);
    const [showPatientForm, setShowPatientForm] = useState<boolean>(false);
    const [showCatetakerForm, setShowCatetakerForm] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>(search);

    useEffect(() => {
        history.location.hash ?? setSelectedKey(location.hash.replace("#", ""));
        if (selectedKey === "caretaker")
            fetchCaretakers(user?.hospitalId as string, {
                search: searchText,
                page: page,
                pageSize: 10
        });
      else
            fetchPatients(user?.hospitalId as string, {
                search: searchText,   
                page: page,
                pageSize: 10
        });
      },[location])

      useEffect(() => {
        setShowCatetakerForm(!!showForm)
      }, [showForm])

    const handleTabChange = (key: string) => {
        setSelectedKey(key);
        setSearchText('');
        history.push({
            pathname: history.location.pathname,
            hash: key,
            search: "?page=1",
            state: {currentPage: 1}
        })
    }

    const handleSearch = (searchText: string) => {
        if (!user?.hospitalId) return

        setSearchText(searchText);
        history.push({...location, search: `?page=1&search=${searchText}`});
       
    }

    const handlePageChange = (page: number, pageSize?: number) => {
        if (!user?.hospitalId) return
        history.push({pathname:history.location.pathname, search: `?page=${page}`, hash: selectedKey,state: {currentPage: page}})
    }

    return (
        <div className="app-wrapper staffs-and-patients">
            <AppHeader iconClassName="icon-my-account"
                title={selectedKey === 'patient' ? "Patient" : "Caregiver"}
                isSearchEnabled={true}
                buttonText={selectedKey === 'patient' ? "Patient" : "Caregiver"}
                buttonHandler={() => {
                    selectedKey === "caretaker" ? setShowCatetakerForm(true)
                        : setShowPatientForm(true)
                }}
                searchValue={searchText}
                setSearchValue={setSearchText}
                handleSearch={handleSearch}
                showGoBack={false}
            />

            {/* <div className="staffs-and-patients__action">
                <SearchComponent onSearch={handleSearch} />
                <ButtonComponent type="primary"
                    text={`Add ${selectedKey === "staff" ? "staff" : selectedKey === "caretaker" ? "caregiver" : "patient"}`}
                    onClick={() => {
                        selectedKey === "caretaker" ? setShowCatetakerForm(true)
                            : setShowPatientForm(true)
                    }}
                    className="staffs-and-patients__add"
                />
            </div> */}

            <Tabs activeKey={selectedKey} onChange={handleTabChange} destroyInactiveTabPane>
                <TabPane tab="Patient" key="patient">
                    <PatientsList fetchPatients={() => user?.hospitalId && fetchPatients(user?.hospitalId)}
                        tableLoading={patientTableLoading}
                        patientList={patientList}
                        handlePageChange={handlePageChange}
                        currentPage={page}
                        totalItems={patientTotalItems}
                        hospitalId={user?.hospitalId || ''}
                    />
                </TabPane>
                <TabPane tab="Caregiver" key="caretaker">
                    <CaretakerList fetchCaretakers={() => user?.hospitalId && fetchCaretakers(user?.hospitalId)}
                        tableLoading={caretakerTableLoading}
                        caretakerList={caretakerList}
                        handlePageChange={handlePageChange}
                        currentPage={caretakerCurrentPage}
                        totalItems={caretakerTotalItems}
                        hospitalId={user?.hospitalId || ''}
                    />
                </TabPane>
            </Tabs>

            <CareTakerForm showForm={showCatetakerForm}
                setShowForm={setShowCatetakerForm}
                caretaker={{}}
                hospitalId={user?.hospitalId || ''}
                refreshList={() => user?.hospitalId && fetchCaretakers(user?.hospitalId)}
            />

            <PatientForm showForm={showPatientForm}
                setShowForm={setShowPatientForm}
                patient={{}}
                hospitalId={user?.hospitalId || ''}
                refreshList={() => user?.hospitalId && fetchPatients(user?.hospitalId)}
            />
        </div>
    )
}

export default AuthContainer(PatientsAndCaretakers);