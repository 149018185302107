import React, { FC, useEffect, useState } from "react";
import "./faqAccordions.scss";
import { Collapse, Divider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import FaqService from "../../../services/FaqService/faq.service";
import RichTextViewer from "../../../shared/components/RichTextViewer";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import { FaqModel } from "../../../models/Faq/faq.model";
import { ReactSortable } from "react-sortablejs";

const { Panel } = Collapse;

interface FaqAccordionsProps {
  activeCategoryId?: string;
  fetchFaqs: Function;
  faqList: FaqModel[];
  faqLoading: boolean;
  faqTotalItems: number;
  faqCurrentPage: number;
  searchText?: string;
  deleteFaq: Function;
  buttonLoading: boolean;
}

const FaqAccordions: FC<FaqAccordionsProps> = ({
  activeCategoryId,
  fetchFaqs,
  faqList = [],
  faqLoading = false,
  faqTotalItems = 0,
  faqCurrentPage = 0,
  deleteFaq,
  searchText,
  buttonLoading = false,
}) => {
  const history = useHistory();

  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [activePanelId, setActivePanelId] = useState<string | string[]>("");

  const [faqOrderedList, setFaqOrderedList] = useState<FaqModel[]>([]);

  const [deleteFaqId, setDeleteFaqId] = useState<number>();

  const { updateFaqOrder } = FaqService();

  useEffect(() => {
    deleteFaqId && setShowDelete(true);
  }, [deleteFaqId]);

  useEffect(() => {
    !showDelete && setDeleteFaqId(undefined);
  }, [showDelete]);

  const handleReorder = (e: any) => {
    const { order } = faqList[e.newIndex];
    const data: FaqModel = Object.assign(new FaqModel(), {
      order: order ?? 1,
    });
    if (activeCategoryId)
      updateFaqOrder(data, activeCategoryId, e.item.dataset.id).then(() => {
        fetchFaqs(activeCategoryId, {}, true);
      });
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: faqLoading,
    hasNextPage: faqCurrentPage * 10 < faqTotalItems,
    onLoadMore: () =>
      fetchFaqs(activeCategoryId, { page: faqCurrentPage + 1 }).then(),
    disabled: false,
    rootMargin: "0px 0px 20px 0px",
  });

  return (
    <div className="faq-accordions">
      <DeleteConfirmation
        showConfirmation={showDelete}
        setShowConfirmation={setShowDelete}
        entityName={"FAQ"}
        handleDelete={() =>
          deleteFaq(activeCategoryId, deleteFaqId).then(() =>
            setActivePanelId("")
          )
        }
        buttonLoading={buttonLoading}
      />
      <div ref={rootRef}>
        <ReactSortable
          list={faqList as any[]}
          setList={setFaqOrderedList as any}
          onSort={handleReorder}
        >
          {!faqLoading &&
            faqList?.map((faq, i) => (
              <div key={faq?.id}>
                <Collapse
                  key={faq.id}
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  expandIconPosition={"right"}
                  className="site-collapse-custom-collapse"
                  accordion
                >
                  <Panel
                    header={faq.title}
                    key={i + 1}
                    className="site-collapse-custom-panel"
                  >
                    <div className="faq-accordion__answer">
                      <div className="faq-accordion__tags">
                        {faq?.tags?.map((tag, i) => (
                          <span
                            className={`faq-accordion__tag type-${(i % 3) + 1} text-capitalize`}
                            key={i}
                          >
                            {tag.label}
                          </span>
                        ))}
                      </div>
                      <div className={"faq-accordion__content"}>
                        <RichTextViewer content={faq?.content} height={300} />
                      </div>
                    </div>
                    <div className={"faq-accordion__actions"}>
                      <i
                        className={"icon-edit"}
                        onClick={() =>
                          history.push({
                            pathname:generatePath(appRoutes.FAQ_FORM, {
                              categoryId: faq.categoryId as number,
                              id: Number(faq.id),
                            }),
                            state:{
                              search: searchText
                            }
                          }
                          )
                        }
                      />
                      <i
                        className={"icon-rejected"}
                        onClick={() => setDeleteFaqId(faq?.id)}
                      />
                    </div>
                  </Panel>
                  <Divider />
                </Collapse>
              </div>
            ))}
        </ReactSortable>
        {(faqLoading || faqCurrentPage * 10 < faqTotalItems) &&
          [...Array(2)].map((x) => (
            <div ref={sentryRef} className={"chat__list__head"}>
              <SkeletonLoader avatar={false} rows={1} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FaqAccordions;
