import * as Yup from "yup";

export const departmentValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required!'),
    email: Yup.string()
        .nullable()
        .email('E-mail is not valid!'),
    phone: Yup.string()
        .required('Phone is required!')
        .min(6, "Enter a valid Phone Number!"),
    location: Yup.string()
        .required('Location is required!'),
    /*  clinicalTeamIds: Yup.array()
          .required('Clinical team is required!'),*/
    description: Yup.string()
        .required('Description is required!'),
})
