import {useState} from "react";
import {JourneyTemplateAttachment} from "../../models/JourneyTemplate/journeyTemplateAttachment.model";
import axiosInstance from "../../interceptor/axiosInstance";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import {deserialize, serialize} from "serializr";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {generatePath} from "react-router-dom";
import {convertJSONToFormData} from "../../shared/utils/dataFormatConverter";

const JourneyStepAttachmentService = () => {

    const [journeyStepAttachment, setJourneyStepAttachment] = useState<JourneyTemplateAttachment>(new JourneyTemplateAttachment());

    const [journeyStepAttachmentList, setJourneyStepAttachmentList] = useState<Array<JourneyTemplateAttachment>>([]);

    const [journeyAttachmentButtonLoading, setJourneyAttachmentButtonLoading] = useState<boolean>(false);

    const fetchJourneyStepAttachments = (templateStepId?: number, attachmentType?:string) => {
        let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .get(apiUrl+`?step_attachment_type=${attachmentType}`)
            .then((response) => {
                const journeyStepAttachments:any = deserialize(JourneyTemplateAttachment, response.data);
                setJourneyStepAttachmentList(journeyStepAttachments);
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    const createJourneyStepAttachment = (data:JourneyTemplateAttachment,templateStepId?:number) => {
        const serializedData = serialize(JourneyTemplateAttachment,data);
        const attachmentFormData = convertJSONToFormData(serializedData);
        let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .post(apiUrl,attachmentFormData)
            .then((response) => {
                const journeyStepAttachment = deserialize(JourneyTemplateAttachment, response.data);
                setJourneyStepAttachmentList([...journeyStepAttachmentList,journeyStepAttachment]);
                setJourneyStepAttachment(journeyStepAttachment);
                Notification({
                    message: "Success",
                    description: "Added successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    const updateJourneyStepAttachment = (templateStepId?: number, attachmentId?:number, data?:JourneyTemplateAttachment) => {
        setJourneyAttachmentButtonLoading(true);
        const serializedData = serialize(JourneyTemplateAttachment,data);
        let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .put(apiUrl+`/${attachmentId}`,serializedData)
            .then((response) => {
                const journeyStepAttachment = deserialize(JourneyTemplateAttachment, response.data);
                setJourneyStepAttachment(journeyStepAttachment);
                Notification({
                    message: "Success",
                    description: "Updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setJourneyAttachmentButtonLoading(false)
            });
    }

    const deleteJourneyStepAttachment= (templateStepId?:number, attachmentId?:number) => {
        setJourneyAttachmentButtonLoading(true);
        let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .delete(apiUrl+`/${attachmentId}`)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setJourneyAttachmentButtonLoading(false)
            });
    }

    return{
        journeyStepAttachment,
        journeyStepAttachmentList,
        setJourneyAttachmentButtonLoading,
        journeyAttachmentButtonLoading,
        fetchJourneyStepAttachments,
        createJourneyStepAttachment,
        deleteJourneyStepAttachment,
        updateJourneyStepAttachment
    }
}

export default JourneyStepAttachmentService;