import {serializable, alias, primitive} from 'serializr';

export class User {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('password', primitive()))
    password?: string;

    @serializable(alias('access_token', primitive()))
    accessToken?: string;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias('role', primitive()))
    role?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('hospital_id', primitive()))
    hospitalId?: string;

    @serializable(alias('display_picture_url', primitive()))
    displayPictureUrl?: string ;

    @serializable(alias('new_password', primitive()))
    newPassword?: string;
}


export class UserDisplayPicture {
    @serializable(alias('display_picture_url', primitive()))
    displayPictureUrl?: string ;
}