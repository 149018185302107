import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { Caretaker, Patient } from "../../models/Patient/patient.model";
import { Staff } from "../../models/Hospital/staff.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { Hospital } from "../../models/Hospital/hospital.model";
import { generatePath } from "react-router-dom";

const CaretakerService = () => {

    const [caretaker, setCaretaker] = useState<Caretaker>({});

    const [caretakerList, setCaretakerList] = useState<Array<Caretaker>>([]);

    const [caretakerTableLoading, setCaretakerTableLoading] = useState<boolean>(false);

    const [caretakerButtonLoading, setCaretakerButtonLoading] = useState<boolean>(false);

    const [caretakerCurrentPage, setCaretakerCurrentPage] = useState<number>(1);

    const [caretakerTotalItems, setCaretakerTotalItems] = useState<number>(0);

    const [existingCaretaker, setExistingCaretaker] = useState<Caretaker>();

    const [caretakerCheck, setCaretakerCheck] = useState<boolean>(false);

    const showCaretaker = (hospitalId: string | number, id?: string) => {
        setCaretakerTableLoading(true)
        return axiosInstance
            .get(generatePath(ApiRoutes.CARETAKERS, { hospitalId }) + `/${id}`)
            .then((response) => {
                const caretaker: Caretaker = deserialize(Caretaker, response.data);
                setCaretaker(caretaker)
            })
            .catch((error) => {
            })
            .finally(() => {
                setCaretakerTableLoading(false)
            });
    };

    const fetchCaretakers = (hospitalId: string | number, queryParams: any = {}, page?: number, pageSize?: number, isMeta?:boolean) => {
        setCaretakerTableLoading(true)
        return axiosInstance
            .get(generatePath(ApiRoutes.CARETAKERS, { hospitalId }) +
    `?${Object.keys(queryParams).length ? `${Object.keys(queryParams).map(o => o + "=" + queryParams[o]).join('&')}&` : ''}${page ? `page=${page}&page_size=${pageSize}` : ''}`)
            .then((response) => {
                const caretakers: Caretaker[] = deserialize(Caretaker, isMeta? response.data : response.data["results"]) as Caretaker[];
                const paginationData = deserialize(PaginationModel, response.data);
                setCaretakerList(caretakers);
                setCaretakerCurrentPage(paginationData?.page);
                setCaretakerTotalItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setCaretakerTableLoading(false)
            });
    };

    const createCaretaker = (hospitalId: string | number, data: Caretaker) => {
        setCaretakerButtonLoading(true);
        const caretakerData = serialize(Caretaker, data);
        return axiosInstance
            .post(generatePath(ApiRoutes.CARETAKERS, { hospitalId }), caretakerData)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Caregiver has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return response.data
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setCaretakerButtonLoading(false)
            });
    }

    const updateCaretaker = (hospitalId: string | number, data: Caretaker) => {
        setCaretakerButtonLoading(true);
        const caretakerData = serialize(Caretaker, data);
        return axiosInstance
            .put(generatePath(ApiRoutes.CARETAKERS_SPECIFIC, { hospitalId, guardianId: data.id }), caretakerData)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Caregiver has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return response.data
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setCaretakerButtonLoading(false)
            });
    }


    const updateExistingCaregiver = (hospitalId:string|number, data:Caretaker) => {
        setCaretakerButtonLoading(true);
        const payload = serialize(Caretaker,data)
        return axiosInstance
            .put(generatePath(ApiRoutes.EDIT_CARETAKER,{id:data.id})+ `?hospital_id=${hospitalId}`,payload)
            .then((res) =>  res)
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setCaretakerButtonLoading(false)
            });
    }

    const deleteCaretaker = (hospitalId: string | number, guardianId: string | number) => {
        setCaretakerButtonLoading(true);
        return axiosInstance
            .delete(generatePath(ApiRoutes.CARETAKERS_SPECIFIC, { hospitalId, guardianId }))
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Caregiver has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return true
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setCaretakerButtonLoading(false)
            });
    }

    const checkExistingCaretaker = (phoneNumber: string) => {
        setCaretakerButtonLoading(true);
        return axiosInstance
            .get(ApiRoutes.CHECK_CARETAKER+ `?phone_number=${phoneNumber}`)
            .then((response) => {
                const caretaker: Caretaker = response.data && deserialize(Caretaker, response.data);
                caretaker &&setExistingCaretaker(caretaker)
                caretaker ? setCaretakerCheck(true): setCaretakerCheck(false);
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setCaretakerButtonLoading(false)
            });
    }

    return {
        caretakerList,
        caretaker,
        caretakerTableLoading,
        caretakerButtonLoading,
        showCaretaker,
        fetchCaretakers,
        deleteCaretaker,
        updateCaretaker,
        createCaretaker,
        caretakerCurrentPage,
        caretakerTotalItems,
        caretakerCheck,
        checkExistingCaretaker,
        existingCaretaker,
        updateExistingCaregiver
    }
}

export default CaretakerService;