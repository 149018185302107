import { REQUEST_LOGOUT } from './../definitions/authConstants';
import { AUTHENTICATED, UNAUTHENTICATED } from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { User } from "../../models/user.model";
import { ActionProps } from "../../shared/types/action.type";

export interface AuthState {
    authenticated: boolean;
    user?: User;
}

export interface AuthReducerProps extends AuthState {
    setAuthenticated: (user: User) => ActionProps;
    setUnAuthenticated: () => ActionProps;
}

const initState: AuthState = {
    authenticated: !!localStorage.getItem("access-token") || false,
    user: !!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')||'') : undefined
};

const authReducer = CreateReducer(initState, {
    [AUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
        const { authenticated, user } = action?.payload;
        return {
            ...state,
            authenticated,
            user:user,
        };
    },
    [UNAUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
        const { authenticated } = action?.payload;
        return { ...state, authenticated };
    },
    [REQUEST_LOGOUT](state: AuthState, action: ActionProps): AuthState {
        localStorage.clear()
        return { ...state, authenticated: false };
    }
});

export default authReducer;



