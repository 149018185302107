import React, { FC } from "react";
import "./patientJourneyHistory.scss";
import { Journey } from "../../../models/Journey/journey.model";
import SkeletonLoader from "../SkeletonLoader";
import { Col, Row, Collapse, Skeleton } from "antd";
import EmptyState from "../EmptyState";
import PatientJourneyCard from "../PatientJourneyCard";
import { CaretDownFilled } from "@ant-design/icons";

const { Panel } = Collapse
interface PatientJourneyHistoryProps {
  journeyList: Array<Journey>;
  cardType: "patient" | "caretaker";
  loading: boolean;
}

const PatientJourneyHistory: FC<PatientJourneyHistoryProps> = (props) => {
  const { journeyList, cardType, loading } = props;

  const loadingState = <Row gutter={[8, 8]}>
    {[...Array(3)].map((x, i) => (
      <Col>
        <div className={`patient-journey-card`}>
          <Skeleton avatar={false} paragraph={{ rows: 5 }} active />
          {/* <SkeletonLoader avatar={false} rows={6} /> */}
        </div>
      </Col>
    ))}
  </Row>

  return (
    <Row className="patients__journey-list">
      <Col span={24} className="patients__journey-list__column">
        <div className="patients__journey-list__title">Active</div>
        {loading
          ? loadingState
          : journeyList?.filter((o) => o.status === "active").length
            ? <Row gutter={[8, 8]}>
              {journeyList?.filter((o) => o.status === "active")
                .map((journey) => (
                  <Col key={journey.id}>
                    <PatientJourneyCard
                      status={"ongoing"}
                      cardType={cardType}
                      journey={journey}
                    />
                  </Col>
                ))}
            </Row>
            : <EmptyState />
        }
      </Col>
      <Col span={24}>
        <Collapse expandIcon={({ isActive }) => <CaretDownFilled className={isActive ? "rotate" : ""} />}>
          <Panel
            key={"draft"}
            header={"Draft"}>
            <Row>
              <Col span={24} className="patients__journey-list__column mt-5">
                {loading
                  ? loadingState
                  : journeyList?.filter((o) => o.status === "draft").length
                    ? <Row gutter={[8, 8]}>
                      {journeyList?.filter((o) => o.status === "draft")
                        .map((journey) => (
                          <Col key={journey.id}>
                            <PatientJourneyCard
                              status={"draft"}
                              cardType={cardType}
                              journey={journey}
                            />
                          </Col>
                        ))}
                    </Row>
                    : <EmptyState />}
              </Col>
            </Row>
          </Panel>
          <Panel
            key={"completed"}
            header={"Completed"}>
            <Row>
              <Col span={24} className="patients__journey-list__column mt-5">
                {loading
                  ? loadingState
                  : journeyList?.filter((o) => o.status === "completed").length
                    ? <Row gutter={[8, 8]}>
                      {journeyList?.filter((o) => o.status === "completed")
                        .map((journey) => (
                          <Col key={journey.id}>
                            <PatientJourneyCard
                              status={"completed"}
                              cardType={cardType}
                              journey={journey}
                            />
                          </Col>
                        ))}
                    </Row>
                    : <EmptyState />}
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default PatientJourneyHistory;
