import * as Yup from "yup";
import axiosInstance from "../../../../interceptor/axiosInstance";
import { ApiRoutes } from "../../../../routes/routeConstants/apiRoutes";
import { parsePhoneNumber } from "react-phone-number-input";

const validationRequest = (params: {}) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(ApiRoutes.JOURNEY_VALIDATION, { params })
      .then((response) => {
        resolve(response.data?.data);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const journeyBasicFormValidationSchema = Yup.object().shape({
  patientId: Yup.string()
    .typeError("Patient is required!")
    .required("Patient is required!"),
  // hospitalId: Yup.string().required("Hospital is required!"),
  name: Yup.string().required("Name is required!"),
  // journeyTemplateId: Yup.string().required("Journey template is required!"),
  staffIds: Yup.array().required("Clinical team is required!"),
  // description: Yup.string().required("Description is required!"),
  surgeryDate: Yup.string().nullable(),
  caretakerId: Yup.string()
    .nullable()
    .when("createCaregiver", {
      is: false,
      then: (field: any) =>
        field
          .typeError("Caregiver is required!")
          .required("Caregiver is required!"),
    }),
  email: Yup.string()
    .nullable()
    .when("createCaregiver", {
      is: true,
      then: (field: any) =>
        field
          .typeError("Email is required!")
          .required("Email is required!")
          .email("E-mail is not valid!")
          .test("email", "Email is already taken!", function (email?: string) {
            if (!email) return true;
            return validationRequest({
              type: "email",
              email,
            });
          }),
    }),
  phoneNumber: Yup.string()
    .nullable()
    .when("createCaregiver", {
      is: true,
      then: (field: any) =>
        field
          .typeError("Phone number is required!")
          .required("Phone number is required!")
          .test(
            "phoneNumber",
            "Phone number is already taken!",
            function (value?: string) {
              if (!value) return true;

              return validationRequest({
                type: "phone_number",
                isd_code: parsePhoneNumber(value)?.countryCallingCode as any,
                phone_number: parsePhoneNumber(value)?.nationalNumber as any,
              });
            }
          ),
    }),
});
