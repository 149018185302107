import React, { FC, useState, useEffect, useRef } from "react";
import "./chatPanel.scss";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import moment from "moment";
import ChatInput from "../ChatInput";
import { Journey } from "../../../../models/Journey/journey.model";
import { DownOutlined } from "@ant-design/icons";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthState } from "../../../../store/reducers/authReducer";
import ChatService from "../../../../services/ChatService/chat.service";
import { ChatMessagesModel } from "../../../../models/Chat/chat.model";
import { Divider, message } from "antd";
import SpinnerLoader from "../../../../shared/components/SpinnerLoader";
import AttachmentViewer from "../../../../shared/components/AttachmentViewer";
import { convertTextToUrl } from "../../../../shared/utils/convertTextToUrl";

interface ChatPanelProps extends AuthState {
  currentChat: Journey;
  newMessage: ChatMessagesModel;
}

const ChatPanel: FC<ChatPanelProps> = (props) => {
  const { currentChat, user, newMessage } = props;

  const { fetchMessages, messagesLoading, currentPage, totalItems } =
    ChatService();

  const [showScrollTrigger, setShowScrollTrigger] = useState<boolean>(false);
  const [messagesList, setMessagesList] = useState<Array<ChatMessagesModel>>(
    []
  );


  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    setShowScrollTrigger(false);
    if (messagesEndRef?.current)
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setMessagesList([]);
    if (currentChat?.id)
      fetchMessages(currentChat?.id).then((response) => {
        setMessagesList(response.reverse());
        response?.length && scrollToBottom();
      });

  }, [currentChat]);

  useEffect(() => {
    if (newMessage) {
      setMessagesList(
        messagesList?.length ? [...messagesList, newMessage] : [newMessage]
      );
      setTimeout(scrollToBottom, 100);
    }

  }, [newMessage]);

  const handleScroll = ({ target }: any) => {
    if (target.scrollTop === 0 && messagesList.length) {
      if (currentChat?.id && totalItems > currentPage * 10)
        fetchMessages(currentChat?.id, { page: currentPage + 1 }).then(
          (response) => {
            response = response?.reverse();
            response &&
              setMessagesList(
                messagesList?.length
                  ? [...response, ...messagesList]
                  : [...response]
              );
          }
        );
    }

    // + 100 to hide before reaching the last message
    const reachedBottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 100;
    setShowScrollTrigger(!reachedBottom);
  };

  return (
    <div className="chat-panel">
      {messagesLoading && <SpinnerLoader />}
      <div className="chat-panel__messages" onScroll={handleScroll}>
        {messagesList?.map((messageItem: ChatMessagesModel, i: number) => {
          return (
            <div key={i} id= {`${messageItem.id}`}>
              {moment(messagesList[i - 1]?.createdAt).format("MMM DD, YYYY") !==
                moment(messageItem?.createdAt).format("MMM DD, YYYY") && (
                  <Divider>
                    {moment(messageItem?.createdAt).format("MMM DD, YYYY")}
                  </Divider>
                )}
              <div
                className={`${messageItem?.sender?.staff?.id !== user?.id
                  ? "align-left"
                  : "align-right"
                  }`}
              >
                <div
                  className={`chat-panel__bubble ${messageItem.messageType} 
                                          ${messageItem?.sender?.staff?.id !==
                      user?.id
                      ? "left__bubble"
                      : "right__bubble"
                    }`}
                  key={messageItem.id}
                >
                  {((messageItem?.sender?.staff?.id !==
                    messagesList[i - 1]?.sender?.staff?.id) || i === 0 )&& (
                    
                      <div className={"sender-info"}>
                        <span
                          className={`sender-info__image 
                                                  ${messageItem?.sender?.staff
                              ?.id === user?.id
                              ? "image__right"
                              : " "
                            }`}
                        >
                          <img
                            src={
                              messageItem?.sender?.staff?.displayPictureUrl ??
                              messageItem?.sender?.patient?.displayPictureUrl ??
                              userPlaceholder
                            }
                            alt={""}
                          />
                        </span>
                        <span className={"sender-info__name text-capitalize"}>
                          {messageItem?.sender?.patient
                            ? messageItem?.sender?.patient?.firstname +
                            " " +
                            messageItem?.sender?.patient?.lastname
                            : ((messageItem?.sender?.staff?.title || "")+ " " ?? "")
                            + messageItem?.sender?.staff?.firstname +
                            " " +
                            messageItem?.sender?.staff?.lastname}
                        </span>
                      </div>
                    )}
                  <div className={"chat-panel__bubble__content"}>
                  {(messageItem?.attachmentName  || messageItem?.attachmentUrl) ? (
                   <AttachmentViewer
                        showPreview = { true }
                        attachmentName={messageItem?.attachmentName}
                        attachmentUrl={messageItem?.attachmentUrl} 
                      /> ) : (<div
                      dangerouslySetInnerHTML={{ __html: messageItem.messageType=== 'link' ? convertTextToUrl(messageItem.message) : messageItem.message }}
                    /> )}
                  </div>
                  <span
                    className={`sender-info__time ${messageItem?.sender?.staff?.id !== user?.id
                      ? "time-left"
                      : "time-right"
                      }`}
                  >
                    {moment(messageItem.createdAt).format("HH:mm a")}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
       
      
        <div ref={messagesEndRef} />
      </div>
      {showScrollTrigger && (
        <div
          className={"chat-panel__scroll-trigger"}
          title={"Go to latest"}
          onClick={scrollToBottom}
        >
          <DownOutlined />
        </div>
      )}
      <ChatInput currentChat={currentChat} scrollToBottom={scrollToBottom}/>
    </div>
  );
};

export default AuthContainer(ChatPanel);
