import React, { FC, useEffect, useState } from "react";
import "./myCalendar.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomToolbar from "./CustomToolbar";
import CalendarService from "../../services/CalendarService/calendar.service";
import JourneyEventModal from "../JourneyList/JourneyDetails/JourneyEvents/JourneyEventModal";
import SpinnerLoader from "../../shared/components/SpinnerLoader";
import { JourneyTemplateStep } from "../../models/JourneyTemplate/journeyTemplateStep.model";

const localizer = momentLocalizer(moment);

interface MyCalendarProps {}

const MyCalendar: FC<MyCalendarProps> = (props) => {
  const {} = props;

  const [events, setEvents] = useState<any>([]);
  const [viewState, setViewState] = useState<"month" | "week">("month");
  const [selectedMonth, setSelectedMonth] = useState<any>(moment());
  const [selectedEvent, setSelectedEvent] = useState<
    JourneyTemplateStep | undefined
  >();
  const [showEventModal, setShowEventModal] = useState<boolean>(false);

  const { fetchCalendarEvents, calendarEvents, calendarLoading } =
    CalendarService();

  useEffect(() => {
    if (viewState === "month")
      fetchCalendarEvents({
        month: moment(selectedMonth).format("MM"),
        year: moment(selectedMonth).year(),
      }).then();
    else
      fetchCalendarEvents({
        start_time: moment(selectedMonth)
          .startOf("week")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(selectedMonth)
          .endOf("week")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      }).then();
  }, [viewState, selectedMonth]);

  useEffect(() => {
    setEvents(
      calendarEvents.map((event, i) => ({
        id: event.id,
        allDay: false,
        start: moment(event.stepTime).toDate(),
        end: event.stepEndTime
          ? moment(event.stepEndTime).toDate()
          : moment(event.stepTime).add("30", "minutes").toDate(),
        title: (
          <div>
            <div
              className={`event-top-border ${moment(event.stepTime).format(
                "dddd"
              )}`}
            />
            <div
              className={`event-info ${moment(event.stepTime).format("dddd")}`}
            >
              <div className="event-info__patient-name">
                {event?.journey?.patient?.fullname}
              </div>
              <div className="event-info__time-slot">
                {`${moment(event.stepTime).format("HH:mm a")} ${
                  event.stepEndTime
                    ? "- " + moment(event.stepEndTime).format("HH:mm a")
                    : ""
                }`}
              </div>
            </div>
          </div>
        ),
      }))
    );
  }, [calendarEvents]);

  useEffect(() => {
    selectedEvent && setShowEventModal(true);
  }, [selectedEvent]);

  useEffect(() => {
    !showEventModal && setSelectedEvent(undefined);
  }, [showEventModal]);

  return (
    <div className="app-wrapper my-calendar">
      {calendarLoading && (
        <div className={"calendar-loader"}>
          <SpinnerLoader />
        </div>
      )}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "90vh" }}
        views={["month", "week"]}
        timeslots={2}
        step={15}
        min={moment(moment().startOf("day")).hour(8).toDate()}
        max={moment(moment().endOf("day")).hour(21).toDate()}
        formats={{
          timeGutterFormat: "HH:mm",
        }}
        components={{
          toolbar: (toolbarProps) => (
            <CustomToolbar
              {...{
                ...toolbarProps,
                setViewState,
                setSelectedMonth,
                viewState,
                selectedMonth,
              }}
            />
          ),
          week: {
            //@ts-ignore
            header: ({ date }) => (
              <div className={"week-view-header"}>
                <div className={"week-view-header__date"}>
                  {moment(date).format("D")}
                </div>
                {moment(date).format("ddd")}
              </div>
            ),
          },
        }}
        onSelectEvent={(event: any) => {
          setSelectedEvent(
            calendarEvents.find((calEvent) => calEvent.id === event?.id)
          );
        }}
        onShowMore={(events, date) => {
          setSelectedMonth(moment(date));
          setViewState("week");
        }}
      />

      <JourneyEventModal
        event={selectedEvent ?? new JourneyTemplateStep()}
        showDetails={showEventModal}
        setShowDetails={setShowEventModal}
        journey={selectedEvent?.journey}
        showJourneyRedirect={true}
      />
    </div>
  );
};

export default MyCalendar;
