import * as Yup from  "yup";

export const locationValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required!'),
    location: Yup.string()
        .required('Location is required!'),
    status: Yup.string()
        .required('Status is required!'),
})
