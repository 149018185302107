import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./LoginValidation"
import UserService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import '../auth.scss';
import {DASHBOARD, FORGOT_PASSWORD} from "../../../routes/routeConstants/appRoutes";
import {useHistory} from "react-router";
import ButtonComponent from "../../../shared/components/ButtonComponent";

interface User {
    email: string,
    password: string
}

const initialValue = {
    email: "",
    password: ""
}

const LoginForm = () => {

    const { loading, loginUser } = UserService();

    const history = useHistory();

    const onSubmit = (user: User) => {
        loginUser(user).then();
    }

    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className="auth-form__header">
                        Login <span className="auth-form__header__info">(Hospital)</span>
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">Username</div>
                        <InputField type="email" name="email" placeholder="Enter username" />
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">Password</div>
                        <InputField type="password" name="password" placeholder="Enter password" />
                    </div>
                    <div className="auth-form__forgot">
                        <div onClick={()=>history.push(FORGOT_PASSWORD)}>Forgot password?</div>
                    </div>
                    <ButtonComponent htmlType="submit"
                                     loading={loading}
                                     className='auth-form__action'
                                     type="primary"
                                     text="Login"
                     />
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(LoginForm);
