import React, { FC, useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { Department } from "../../../../../models/Hospital/department.model";
import DepartmentForm from "../DepartmentForm";
import userPlaceHolder from "../../../../../assets/userPlaceholder.png";
import "./departmentDetails.scss";
import DepartmentService from "../../../../../services/HospitalService/department.service";
import { useParams } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";

interface DepartmentDetailsProps {
  showDetails: boolean;
  setShowDetails: (showForm: boolean) => void;
  departmentId?: string;
}

const DepartmentDetails: FC<DepartmentDetailsProps> = (props) => {
  const { showDetails, setShowDetails, departmentId } = props;

  const { id } = useParams();

  const { department, showDepartment } = DepartmentService();

  const [showOverflow, setShowOverflow] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (descriptionRef.current){
        descriptionRef.current.innerHTML = department?.description?.replace(/\n/gm, '<br />') || ""
        descriptionRef.current.innerHTML = department?.description?.replace(/<p>&nbsp;<\/p>/i, ' ') || "";
        descriptionRef.current.innerHTML = department?.description?.replace(/<br>/gm, ' ') || "";
      }
    })
  }, [department?.description, descriptionRef.current])

  useEffect(() => {
    if (departmentId && showDetails) showDepartment(id, departmentId).then();
  }, [showDetails]);

  useEffect(() => {
    if (department)
      setShowOverflow(
        !!department?.description && department?.description?.length > 90
      );
  }, [department]);

  return (
    <>
      <DepartmentForm
        showForm={showForm}
        setShowForm={setShowForm}
        department={department}
        refreshDepartment={showDepartment}
      />
      <Modal
        title={null}
        visible={showDetails}
        onCancel={() => setShowDetails(false)}
        footer={null}
        maskClosable = {false}
        className="department-details"
      >
        <div className="department-details__basic-info">
          <span
            className="department-details__edit"
            onClick={() => setShowForm(true)}
          >
            <i className="icon-edit-1" />
          </span>
          <div className="department-details__name text-capitalize">
            {department?.name}
          </div>
          <div className="hospital-details__bio">
            <span
              ref={descriptionRef}
              className={showOverflow ? "text-overflow" : ""} />
            {department?.description && department?.description?.length > 90 && (
              <span
                className="view-more"
                onClick={() => setShowOverflow(!showOverflow)}
              >
                {showOverflow ? "View more" : "View less"}
              </span>
            )}
          </div>
          <div className="hospital-details__communication">
            <div className="hospital-details__contact">
              <span className="hospital-details__contact-icon">
                <i className="icon-email" />
              </span>
              <span
                className="hospital-details__contact-info cursor-pointer"
                onClick={(e) => {
                  window.location.href = `mailto:${department?.email}`;
                  e.preventDefault();
                }}
              >
                {department?.email}
              </span>
            </div>
            <div className="hospital-details__contact">
              <span className="hospital-details__contact-icon">
                <i className="icon-mobile" />
              </span>
              <span className="hospital-details__contact-info">
                {formatPhoneNumberIntl((department?.isdCode ? department.isdCode : "") + (department.phone || ""))}
              </span>
            </div>
            <div className="hospital-details__contact">
              <span className="hospital-details__contact-icon">
                <i className="icon-location" />
              </span>
              <span className="hospital-details__contact-info text-capitalize">
                {department?.location ?? "-"}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DepartmentDetails;
