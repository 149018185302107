import React, { FC, useEffect, useState } from 'react'
import "./hospitalForm.scss"
import { hospitalValidationSchema } from "./hospitalFormValidation";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import UserService from "../../../services/AuthService/auth.service";
import { Col, Modal, Row } from "antd";
import { Hospital } from "../../../models/Hospital/hospital.model";
import HospitalService from "../../../services/HospitalService/hospital.service";
import RichTextField from '../../../shared/components/RichTextField';
import PhoneNumberInput from '../../../shared/components/PhoneNumberInput';
import { parsePhoneNumber } from 'react-phone-number-input';

interface HospitalFormProps {
    showForm: boolean;
    setShowForm: (showForm: boolean) => void;
    hospital: Hospital;
    refreshList?: Function;
    refreshHospital?: Function;
}

const HospitalForm: FC<HospitalFormProps> = (props) => {
    const { showForm, setShowForm, hospital, refreshList, refreshHospital } = props;

    const { buttonLoading, createHospital, updateHospital } = HospitalService();

    const [initialValue, setInitialValue] = useState<Hospital>(
        {
            name: "",
            location: "",
            email: "",
            phone: "",
            noOfEmployees: undefined,
            website: "",
            extCode: "",
            bio: "",
        }
    )

    useEffect(() => {
        setInitialValue({
            name: hospital?.name,
            location: hospital?.location,
            email: hospital?.email,
            phone: hospital?.isdCode ? hospital.isdCode + hospital?.phone : (hospital?.phone || ""),
            noOfEmployees: hospital?.noOfEmployees,
            website: hospital?.website,
            bio: hospital?.bio,
            extCode: (!isNaN(parseInt(hospital.extCode || "")) ? hospital.extCode : undefined),
        })
    }, [hospital]);

    const onSubmit = (data: Hospital, formProps: { resetForm: () => void }) => {
        const values = { ...data }
        if (values.phone) {
            values.isdCode = "+" + parsePhoneNumber(values.phone)?.countryCallingCode as any;
            values.phone = parsePhoneNumber(values.phone)?.nationalNumber as any;
        }
        if (Object.keys(hospital).length) {
            updateHospital(values, hospital?.id).then(() => {
                setShowForm(false);
                formProps.resetForm();
                if (refreshList) refreshList();
                if (refreshHospital) refreshHospital(hospital?.id);
            });
        } else {
            createHospital(values).then(() => {
                setShowForm(false);
                formProps.resetForm();
                if (refreshList) refreshList();
            });
        }
    }

    return (
        <Modal title={null}
            visible={showForm}
            onCancel={() => setShowForm(false)}
            footer={null}
            destroyOnClose
            maskClosable = {false}
            className="hospital-form"
        >
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={hospitalValidationSchema}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    isValid,
                    dirty,
                    setFieldValue,
                    setFieldTouched,
                }) =>
                    <Form>
                        <div className="hospital-form__header">
                            {Object.keys(hospital).length ? "Edit" : "New"} Hospital
                        </div>

                        <div className="hospital-form__row">
                            <div className="hospital-form__field">
                                <div className="form-label">Name</div>
                                <InputField type="text" name="name" placeholder="Enter name" />
                            </div>
                            <div className="hospital-form__field">
                                <div className="form-label">Location</div>
                                <InputField type="text" name="location" placeholder="Enter Location" />
                            </div>
                        </div>

                        <div className="hospital-form__row">
                            <div className="hospital-form__field">
                                <div className="form-label">Email</div>
                                <InputField type="email" name="email" placeholder="Enter email" />
                            </div>
                            <div className="hospital-form__field">
                                <div className="form-label">Phone</div>
                                <Row>
                                    <Col span={18}>
                                        <PhoneNumberInput
                                            name={"phone"}
                                            value={values.phone}
                                            onChange={(value) => {
                                                setFieldValue("phone", value);
                                            }}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <InputField
                                            type="number"
                                            name="extCode"
                                            placeholder="XXXXX"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="hospital-form__row">
                            {/* <div className="hospital-form__field">
                                <div className="form-label">No of Employees</div>
                                <InputField type="number" name="noOfEmployees" placeholder="Enter employees count" />
                            </div> */}
                            <div className="hospital-form__field">
                                <div className="form-label">Website</div>
                                <InputField type="text" name="website" placeholder="Enter website" />
                            </div>
                        </div>
                        <div className="hospital-form__row">
                            <div className="hospital-form__field textarea">
                                <div className="form-label">Bio</div>
                                <RichTextField
                                    name={"bio"}
                                    editorData={values.bio || ""}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>

                        <div className="hospital-form__actions">
                            <ButtonComponent className="hospital-form__action"
                                type="text"
                                text="Cancel"
                                onClick={() => setShowForm(false)}
                            />
                            <ButtonComponent htmlType="submit"
                                loading={buttonLoading}
                                className="hospital-form__action"
                                type="primary"
                                text={Object.keys(hospital).length ? "Update" : "Save"}
                            />
                        </div>
                    </Form>
                }
            </Formik>
        </Modal>
    )
}

export default HospitalForm;