import React, { FC, useEffect } from "react";
import "./journeyDetails.scss";
import JourneyDetailHeader from "./JourneyDetailHeader";
import JourneyEvents from "./JourneyEvents";
import { useParams } from "react-router-dom";
import JourneyService from "../../../services/JourneyService/journey.service";
import SpinnerLoader from "../../../shared/components/SpinnerLoader";

interface JourneyDetailsProps { }

const JourneyDetails: FC<JourneyDetailsProps> = (props) => {
  const { } = props;

  const { id } = useParams<{ id: string }>();

  const { showJourney, journey, tableLoading } = JourneyService();

  useEffect(() => {
    if (id) showJourney(id);
  }, [id]);

  return (
    <div className="app-wrapper journey-details">
      <JourneyDetailHeader
        journey={journey}
        refreshJourey={() => id && showJourney(id)}
        tableLoading={tableLoading} />
      <JourneyEvents
        journeyId={+id}
        journey={journey}
        tableLoading={tableLoading}
      />
    </div>
  );
};

export default JourneyDetails;
