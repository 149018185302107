import React, { FC, useCallback, useEffect, useState } from "react";
import "./staffForm.scss";
import { Form, Formik } from "formik";
import { staffValidationSchema } from "./staffFormValidation";
import InputField from "../../../shared/components/InputField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { Col, Modal, Row, Select } from "antd";
import DropdownField from "../../../shared/components/DropdownField";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import uploadProfilePic from "../../../assets/uploadProfilePic.png";
import { Staff } from "../../../models/Hospital/staff.model";
import { MetaModel } from "../../../models/meta.model";
import StaffService from "../../../services/HospitalService/staff.service";
import MetaService from "../../../services/MetaService/meta.service";
import { serialize } from "serializr";
import DepartmentService from "../../../services/HospitalService/department.service";
import LocationService from "../../../services/HospitalService/location.service";
import { onSelectScroll } from "../../../shared/utils/dropdownLazyLoader";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthState } from "../../../store/reducers/authReducer";
import { staffStatusMeta } from "../../../shared/utils/statusMetas";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneNumberInput from "../../../shared/components/PhoneNumberInput";
import RichTextField from "../../../shared/components/RichTextField";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
const { Option } = Select;

interface StaffFormProps extends AuthState {
  showForm: boolean;
  setShowForm: (showForm: boolean) => void;
  staff: Staff;
  refreshList?: Function;
}

const StaffForm: FC<StaffFormProps> = (props) => {
  const { showForm, setShowForm, staff, refreshList, user } = props;

  const metaPageSize = 10;

  const { buttonLoading, createStaff, updateStaff, checkEmail, validEmail } = StaffService();
  const id = JSON.parse(localStorage.getItem('user') || '').hospitalId;
  const {
    fetchRoles,
    currentRolesPage,
    totalRoleItems,
    rolesMeta,
    fetchLanguages,
    currentLanguagesPage,
    totalLanguageItems,
    languagesMeta,
    fetchSpecialities,
    currentSpecialitiesPage,
    totalSpecialitiesItems,
    specialitiesMeta,
    deleteSpecialities,
    specialitiesMetaLoading,
  } = MetaService();
  const {
    fetchDepartments,
    currentDepartmentPage,
    totalDepartmentItems,
    departmentsMeta,
  } = DepartmentService();
  const {
    fetchLocations,
  } = LocationService();

  const [selectedSpeciality, setSelectedSpeciality] = useState<MetaModel>()

  const [initialValue, setInitialValue] = useState<Staff>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    roleId: "",
    departmentId: "",
    specialityIds: [],
    languagesKnownIds: [],
    location: "",
    link: "",
    extCode: "",
    bio: "",
    displayPicture: new File([], "new"),
    status: true,
  });

  useEffect(() => {
    if (user?.hospitalId) {
      fetchDepartments(user?.hospitalId, "", 1, metaPageSize, true).then();
      fetchLocations(user?.hospitalId, "", 1, metaPageSize, true).then();
      fetchRoles(user?.hospitalId, "", 1, metaPageSize, true).then();
      fetchLanguages(user?.hospitalId, "", 1, 100, true).then();
      fetchSpecialities(user?.hospitalId, "", 1, metaPageSize, true).then();
    }
  }, []);

  useEffect(() => {
    setInitialValue({
      title: staff?.title,
      firstname: staff?.firstname,
      lastname: staff?.lastname,
      email: staff?.email,
      roleId: staff?.role?.value,
      departmentId: staff?.department?.id,
      phone: staff?.isdCode ? staff.isdCode + staff?.phone : (staff?.phone || ""),
      specialityIds: staff?.specialities?.map((o: MetaModel) => o.value),
      languagesKnownIds: staff?.languagesKnown?.map((o: MetaModel) => o.value),
      location: staff?.location,
      link: staff?.link,
      bio: staff?.bio,
      extCode: (!isNaN(parseInt(staff.extCode || "")) ? staff.extCode : undefined),
      displayPicture: staff?.displayPicture,
      status: staff?.status ?? true,
    });
  }, [staff]);

  const convertSpecialitiesToStrings = (values: Staff) =>
    values?.specialityIds?.map(
      (specialityId: number | string) => {
        if (typeof (specialityId) == "number") {
          return specialitiesMeta.find(
            (specialitiesMetaItem) => specialitiesMetaItem.value?.toString() === specialityId.toString()
          )?.label ?? specialityId
        } else {
          return specialityId
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
      }

    );

  const onSubmit = (data: Staff, formProps: { resetForm: () => void }) => {
    const values = { ...data }
    values.hospitalId = user?.hospitalId;
    values.specialityIds = convertSpecialitiesToStrings(values);
    if (values.phone) {
      values.isdCode = "+" + parsePhoneNumber(values.phone)?.countryCallingCode as any;
      values.phone = parsePhoneNumber(values.phone)?.nationalNumber as any;
    }
    const staffData = serialize(Staff, values);
    if (!staffData?.display_picture) delete staffData.display_picture;

    if (Object.keys(staff).length) {
      updateStaff(staffData, staff?.id).then(() => {
        formProps.resetForm();
        setShowForm(false);
        if (refreshList) refreshList();
      });
    } else {
      validEmail && createStaff(staffData).then(() => {
        formProps.resetForm();
        setShowForm(false);
        if (refreshList) refreshList();
      });
    }
  };

  const handleRemoveOption = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: MetaModel) => {
    e.stopPropagation();
    setSelectedSpeciality(item)
  }

  const handleDeleteSpeciality = () => {
    return deleteSpecialities(selectedSpeciality?.value ?? "").then(() => {
      Notification({
        message: "Success",
        description: "Speciality has been deleted successfully",
        type: NotificationTypes.SUCCESS,
      });
    }).finally(() => {
      setSelectedSpeciality(undefined)
    })
  }

  const specialityMetaRender = useCallback((departmentIdList: string[]) => {

    const staffSpecialities = (staff && staff.specialities) ? staff.specialities : [];

    let optionItemsList = specialitiesMeta

    if (staffSpecialities.length > 0) {
      const metaList: string[] = optionItemsList.map((item) => item.value ?? "")
      let notExistedEntries: MetaModel[] = []
      staffSpecialities.forEach((item, index) => {
        if (!metaList.includes(item.value ?? "")) {
          notExistedEntries.push(item)
        }
      })
      optionItemsList = [
        ...optionItemsList,
        ...notExistedEntries
      ]
    }

    return <>
      {
        optionItemsList.map((item, index) => {
          const hasRemvoeIcon = !departmentIdList.includes(item.value ?? "")
          return <Option key={index} value={item.value ?? ""} label={item.label}>
            <div className="d-flex justify-content-between">
              <span>{item.label}</span>
              {
                hasRemvoeIcon && (
                  <DeleteOutlined
                    className="remove-icon text-danger"
                    onClick={(e) => handleRemoveOption(e, item)}
                  />
                )
              }
            </div>
          </Option>
        })
      }
    </>
  }
    , [specialitiesMeta, staff.specialities])


  return (
    <Modal
      title={null}
      visible={showForm}
      onCancel={() => {
        setShowForm(false);
      }}
      destroyOnClose
      footer={null}
      maskClosable={false}
      className="hospital-form staff-form"
    >
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={staffValidationSchema}
        enableReinitialize
      >
        {({
          values,
          isValid,
          setFieldValue,
        }) => (
          <Form>
            <div className="hospital-form__header">
              {Object.keys(staff).length ? "Edit" : "New"} Staff
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <input
                  type="file"
                  className="image-upload"
                  onChange={(e) => {
                    setFieldValue(
                      "displayPicture",
                      e.target?.files?.length ? e.target.files[0] : undefined
                    );
                  }}
                />
                {!staff.displayPictureUrl && !values?.displayPicture ? (
                  <>
                    <img
                      src={uploadProfilePic}
                      alt=""
                      className="form-uploader-image"
                    />
                    <span className="form-label">Upload profile pic</span>
                  </>
                ) : (
                  <img
                    src={
                      values?.displayPicture
                        ? URL.createObjectURL(values?.displayPicture)
                        : staff?.displayPictureUrl
                          ? staff?.displayPictureUrl
                          : ""
                    }
                    alt=""
                    className="uploaded-image"
                  />
                )}
              </div>
              <div className="hospital-form__field">
                <div className="hospital-form__field full-width">
                  <div className="form-label">Title (Optional)</div>
                  <InputField
                    type="text"
                    name="title"
                    placeholder="Enter Title"
                  />
                </div>
                <div className="hospital-form__field full-width">
                  <div className="form-label">First Name</div>
                  <InputField
                    type="text"
                    name="firstname"
                    placeholder="Enter first name"
                  />
                </div>

              </div>
            </div>

            <div className="hospital-form__row">

              <div className="hospital-form__field">
                <div className="form-label">Email</div>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onBlur={(e: any) => {
                    const email = e.target.value.trim();
                    (!(staff?.email === email) && email !== "") && (checkEmail(id, email));
                  }}
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Last Name</div>
                <InputField
                  type="text"
                  name="lastname"
                  placeholder="Enter last name"
                />
              </div>

            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">Phone</div>
                <Row>
                  <Col span={18}>
                    <PhoneNumberInput
                      name={"phone"}
                      value={values.phone}
                      onChange={(value) => {
                        setFieldValue("phone", value);
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      type="number"
                      name="extCode"
                      placeholder="XXXXX"
                    />
                  </Col>
                </Row>
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Department</div>
                <DropdownField
                  name="departmentId"
                  onChange={(value) => {
                    setFieldValue("departmentId", value);
                  }}
                  options={departmentsMeta}
                  placeHolder="Select department"
                  showArrow={true}
                  value={values?.departmentId}
                  onOptionsScroll={(event) =>
                    onSelectScroll(
                      event,
                      departmentsMeta,
                      currentDepartmentPage,
                      totalDepartmentItems,
                      fetchDepartments,
                      user?.hospitalId,
                      metaPageSize
                    )
                  }
                />
              </div>
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field staff-form__field">
                <div className="form-label">Role</div>
                <DropdownField
                  name="roleId"
                  options={rolesMeta}
                  placeHolder="Select role"
                  showArrow={true}
                  onChange={(value) => setFieldValue("roleId", value)}
                  value={values?.roleId}
                  onOptionsScroll={(event) =>
                    onSelectScroll(
                      event,
                      rolesMeta,
                      currentRolesPage,
                      totalRoleItems,
                      fetchRoles,
                      user?.hospitalId,
                      metaPageSize
                    )
                  }
                />
              </div>

              <div className="hospital-form__field">
                <div className="form-label">Languages known</div>
                <DropdownField
                  name="languagesKnownIds"
                  onChange={(value) => {
                    setFieldValue("languagesKnownIds", value);
                  }}
                  options={languagesMeta}
                  placeHolder="Select languages"
                  showArrow={true}
                  mode={DropdownModeEnum.MULTIPLE}
                  value={values?.languagesKnownIds}
                  onOptionsScroll={(event) =>
                    onSelectScroll(
                      event,
                      languagesMeta,
                      currentLanguagesPage,
                      totalLanguageItems,
                      fetchLanguages,
                      user?.hospitalId,
                      metaPageSize
                    )
                  }
                />
              </div>
            </div>
            <div className="hospital-form__row">
              <div className="hospital-form__field full-width">
                <div className="form-label">Speciality</div>
                <DropdownField
                  name="specialityIds"
                  onChange={(value) => {
                    setFieldValue("specialityIds", value);
                  }}
                  placeHolder="Select speciality"
                  showArrow={true}
                  showSearch
                  mode={DropdownModeEnum.TAGS}
                  optionLabelProp="label"
                  value={values?.specialityIds}
                  onOptionsScroll={(event) =>
                    onSelectScroll(
                      event,
                      specialitiesMeta,
                      currentSpecialitiesPage,
                      totalSpecialitiesItems,
                      fetchSpecialities,
                      user?.hospitalId,
                      metaPageSize
                    )
                  }
                >
                  {specialityMetaRender(values.specialityIds ?? [])}
                </DropdownField>
              </div>
            </div>
            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">Location</div>
                <InputField
                  type="text"
                  name="location"
                  placeholder="Enter location"
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Link</div>
                <InputField type="text" name="link" placeholder="Enter link" />
              </div>
            </div>
            <div className="hospital-form__row">
              <div className="hospital-form__field textarea">
                <div className="form-label">Bio</div>
                <RichTextField
                  name={"bio"}
                  editorData={values.bio || ""}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>

            {staff?.id && (
              <div className="hospital-form__row">
                <div className="hospital-form__field">
                  <div className="form-label">Status</div>
                  <DropdownField
                    name="status"
                    onChange={(value) => {
                      setFieldValue("status", value);
                    }}
                    options={staffStatusMeta}
                    placeHolder="Select status"
                    showArrow={true}
                    value={values?.status}
                  />
                </div>
              </div>
            )}

            <div className="hospital-form__actions">
              <ButtonComponent
                className="hospital-form__action"
                type="text"
                text="Cancel"
                onClick={() => {
                  setShowForm(false);
                }}
              />
              <ButtonComponent
                htmlType="submit"
                loading={buttonLoading}
                className="hospital-form__action"
                type="primary"
                text={Object.keys(staff).length ? "Update" : "Save"}
                disabled={
                  (!values.displayPicture || !staff?.displayPictureUrl) &&
                  !isValid
                }
              />
            </div>
          </Form>
        )}
      </Formik>
      <DeleteConfirmation
        showConfirmation={selectedSpeciality !== undefined}
        setShowConfirmation={() => setSelectedSpeciality(undefined)}
        entityName="Speciality"
        handleDelete={() => handleDeleteSpeciality()}
        subEntityName={selectedSpeciality?.label}
        buttonLoading={specialitiesMetaLoading}
      />
    </Modal>
  );
};

export default AuthContainer(StaffForm);
