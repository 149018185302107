import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router-dom";
import { JourneyTemplateStep } from "../../models/JourneyTemplate/journeyTemplateStep.model";
import { ArticleModel } from "../../models/Article/article.model";
import { MetaModel } from "../../models/meta.model";
import { FaqModel } from "../../models/Faq/faq.model";

const JourneyStepService = () => {
  const [journeyStep, setJourneyStep] = useState<JourneyTemplateStep>(
    new JourneyTemplateStep()
  );

  const [journeyStepList, setJourneyStepList] = useState<
    Array<JourneyTemplateStep>
  >([]);

  const [journeyArticles, setJourneyArticles] = useState<ArticleModel[]>([]);

  const [journeyFAQCategories, setJourneyFAQCategories] = useState<MetaModel[]>(
    []
  );

  const [journeyQuestions, setJourneyQuestions] = useState<FaqModel[]>([]);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [stepButtonLoading, setStepButtonLoading] = useState<boolean>(false);

  const fetchJourneySteps = (id?: number, search?: string) => {
    setTableLoading(true);
    let apiUrl =
      generatePath(ApiRoutes.JOURNEY_STEPS, { id: id }) +
      `${search ? "?search=" + search : ""}`;
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeySteps: any = deserialize(
          JourneyTemplateStep,
          response.data
        );
        setJourneyStepList(journeySteps);
      })
      .catch((error) => { })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const showJourneyStep = (id?: number, stepId?: number) => {
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEPS, { id: id });
    return axiosInstance
      .get(apiUrl + `/${stepId}`)
      .then((response) => {
        const journeyStep: any = deserialize(
          JourneyTemplateStep,
          response.data
        );
        setJourneyStep(journeyStep);
        return journeyStep
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const createJourneyStep = (
    id: number,
    data: JourneyTemplateStep,
    onSuccess?: (step: JourneyTemplateStep) => void
  ) => {
    setStepButtonLoading(true);
    const serializedData = serialize(JourneyTemplateStep, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEPS, { id: id });
    return axiosInstance
      .post(apiUrl, serializedData)
      .then((response) => {
        const journeyStep = deserialize(JourneyTemplateStep, response.data);
        setJourneyStepList([...journeyStepList, journeyStep]);
        setJourneyStep(journeyStep);
        Notification({
          message: "Success",
          description: "Journey step has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess && onSuccess(journeyStep);
      })
      .catch((error) => { })
      .finally(() => {
        setStepButtonLoading(false);
      });
  };

  const updateJourneyStep = (
    id: number,
    stepId?: number,
    data?: JourneyTemplateStep
  ) => {
    setStepButtonLoading(true);
    const serializedData = serialize(JourneyTemplateStep, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEPS, { id });
    return axiosInstance
      .put(apiUrl + `/${stepId}`, serializedData)
      .then((response) => {
        const journeyStep = deserialize(JourneyTemplateStep, response.data);
        setJourneyStep(journeyStep);
        Notification({
          message: "Success",
          description: "Journey step has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return journeyStep
      })
      .catch((error) => { })
      .finally(() => {
        setStepButtonLoading(false);
      });
  };

  const updateJourneyStepOrder = (
    id: number,
    stepId?: number,
    data?: JourneyTemplateStep
  ) => {
    setStepButtonLoading(true);
    const serializedData = serialize(JourneyTemplateStep, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEPS_ORDER, { id, stepId });
    return axiosInstance
      .put(apiUrl, serializedData)
      .then((response) => {
        const journeyStep = deserialize(JourneyTemplateStep, response.data);
        setJourneyStep(journeyStep);
        Notification({
          message: "Success",
          description: "Journey step has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return journeyStep
      })
      .catch((error) => { })
      .finally(() => {
        setStepButtonLoading(false);
      });
  };

  const deleteJourneyStep = (templateId?: number, templateStepId?: number) => {
    setStepButtonLoading(true);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEPS, { id: templateId });
    return axiosInstance
      .delete(apiUrl + `/${templateStepId}`)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey step has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setStepButtonLoading(false);
      });
  };

  const fetchJourneyArticles = (stepId?: number) => {
    setTableLoading(true)
    let apiUrl = generatePath(ApiRoutes.JOURNEY_ARTICLES, { stepId });
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyArticles: any = deserialize(
          ArticleModel,
          response.data.articles.results
        );
        setJourneyArticles(journeyArticles);
      })
      .catch((error) => { })
      .finally(() => {
        setTableLoading(false)
      });
  };

  const fetchJourneyFAQCategories = (stepId?: number) => {
    let apiUrl = generatePath(ApiRoutes.JOURNEY_FAQ_CATEGORIES, { stepId });
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyFAQCategories: any = deserialize(
          MetaModel,
          response.data.categories
        );
        setJourneyFAQCategories(journeyFAQCategories);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const fetchJourneyQuestions = (stepId?: number) => {
    let apiUrl = generatePath(ApiRoutes.JOURNEY_QUESTIONS, { stepId });
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyQuestions: any = deserialize(
          FaqModel,
          response.data.questions
        );
        setJourneyQuestions(journeyQuestions);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  return {
    journeyStep,
    journeyStepList,
    journeyArticles,
    journeyFAQCategories,
    journeyQuestions,
    tableLoading,
    stepButtonLoading,
    fetchJourneySteps,
    createJourneyStep,
    deleteJourneyStep,
    updateJourneyStep,
    updateJourneyStepOrder,
    showJourneyStep,
    fetchJourneyArticles,
    fetchJourneyFAQCategories,
    fetchJourneyQuestions,
  };
};

export default JourneyStepService;
