import React, { FC, useEffect } from 'react'
import "./caretakers.scss"
import PatientHeader from "../../shared/components/PatientHeader";
import { useParams } from "react-router-dom";
import CaretakerService from "../../services/CaretakerService/caretaker.service";
import PatientService from "../../services/PatientService/patient.service";
import JourneyService from "../../services/JourneyService/journey.service";
import PatientJourneyHistory from "../../shared/components/PatientJourneyHistory";
import AuthContainer from '../../store/container/AuthContainer';
import { AuthReducerProps } from '../../store/reducers/authReducer';

interface CaretakersProps extends AuthReducerProps {

}

const Caretakers: FC<CaretakersProps> = (props) => {
    const {
        user,
    } = props;

    const { id, hospitalId } = useParams<{ id: string, hospitalId: string }>();

    const { caretaker, showCaretaker, caretakerTableLoading } = CaretakerService();
    const { patientList, fetchPatients } = PatientService();
    const { fetchJourneys, journeyList, tableLoading } = JourneyService();

    useEffect(() => {
        if (id) {
            user?.hospitalId && showCaretaker(user?.hospitalId, id).then();
            user?.hospitalId && fetchPatients(user?.hospitalId, { guardian_id: id }).then();
            fetchJourneys({ guardian_id: id }).then();
        }
    }, [id])

    return (
        <div className="app-wrapper caretakers">
            <PatientHeader patient={caretaker}
                dependentType={'patient'}
                dependents={patientList}
                fetchPatient ={showCaretaker}
                loading={caretakerTableLoading}
                user={user}
            />
            <PatientJourneyHistory journeyList={journeyList}
                cardType={'caretaker'}
                loading={tableLoading}
            />
        </div>
    )
}

export default AuthContainer(Caretakers);