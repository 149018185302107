 import React, { FC, useEffect, useState } from "react";
import "./journeyTemplateStepForm.scss";
import { Form, Formik } from "formik";
import InputField from "../../../../../shared/components/InputField";
import DropdownField from "../../../../../shared/components/DropdownField";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import JourneyTemplateChecklistForm from "../JourneyTemplateChecklists/JourneyTemplateChecklistForm";
import JourneyTemplateCarouselForm from "../JourneyTemplateCarousels/JourneyTemplateCarouselForm";
import { Col, Input, Row } from "antd";
import { useParams } from "react-router-dom";
import JourneyTemplateStepService from "../../../../../services/JourneyTemplateService/journeyTemplateStep.service";
import { templateStepDropdownOptions } from "../../../../../shared/utils/templateStepDropdownOptions";
import JourneyTemplateAttachments from "../JourneyTemplateAttachments";
import { JourneyTemplateAttachment } from "../../../../../models/JourneyTemplate/journeyTemplateAttachment.model";
import JourneyTemplateAttachmentService from "../../../../../services/JourneyTemplateService/journeyTemplateAttachment.service";
import {
  journeyStepValidationSchema,
  templateStepValidationSchema,
} from "./templateStep.validation";
import JourneyTemplateMembers from "../JourneyTemplateMembers";
import { useHistory } from "react-router";
import JourneyStepService from "../../../../../services/JourneyService/journeyStep.service";
import JourneyStepAttachmentService from "../../../../../services/JourneyService/journeyStepAttachment.service";
import moment from "moment";
import DatepickerField from "../../../../../shared/components/DatepickerField";
import RichTextField from "../../../../../shared/components/RichTextField";
import {
  JourneyTemplateStep,
  JourneyTemplateStepEntityModel,
} from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import { useJourneyContext } from "../../../../../context/JourneyContext";
import JourneyTemplateCategoryService from "../../../../../services/JourneyTemplateService/journeyTemplateCategory.service";
import JourneyCategoryService from "../../../../../services/JourneyService/journeyCategory.service";
import JourneyTemplateStepEntity from "../JourneyTemplateStepEntitySelect";
import { JourneyStepEntityEnum } from "../../../../../enums/journeyStepEntity.enum";

interface JourneyTemplateStepFormProps {
  customizeJourney?: boolean;
}

const JourneyTemplateStepForm: FC<JourneyTemplateStepFormProps> = (props) => {
  const { customizeJourney } = props;

  const {
    journeyDetails: { activeJourneyCategoryStep, activeJourneyCategory },
    setActiveJourneyCategoryStep,
    setJourneyCategories,
  } = useJourneyContext();

  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const baseStepInitialValues = {
    id: 0,
    title: "",
    description: "",
  };

  const templateStepInitialValues = {
    scheduleDays: 0,
    schedule: "",
  };

  const journeyStepInitialValues = {
    stepTime: "",
    stepEndTime: "",
  };

  const [previousActiveTemplateStep ,  setPreviousActiveTemplateStep] = useState(activeJourneyCategoryStep);

  const [showChecklist, setShowChecklist] = useState<boolean>(false);

  const [showCarouselForm, setShowCarouselForm] = useState<boolean>(false);

  const [showMembers, setShowMembers] = useState<boolean>(false);

  const [showArticles, setShowArticles] = useState<boolean>(false);

  const [showFaq, setShowFaq] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<JourneyTemplateStep>({
    ...baseStepInitialValues,
    ...(customizeJourney
      ? journeyStepInitialValues
      : templateStepInitialValues),
  });

  const {
    createJourneyTemplateStep,
    updateJourneyTemplateStep,
    buttonLoading,
    journeyTemplateStep,
  } = JourneyTemplateStepService();

  const {
    createJourneyStep,
    updateJourneyStep,
    stepButtonLoading,
    journeyStep,
  } = JourneyStepService();

  const { fetchJourneyTemplateCategories, journeyTemplateCategoryList } =
    JourneyTemplateCategoryService();

  const { fetchJourneyCategories, journeyCategoryList } =
    JourneyCategoryService();

  const {
    createJourneyTemplateAttachment,
    journeyTemplateAttachmentList,
    fetchJourneyTemplateAttachments,
  } = JourneyTemplateAttachmentService();

  const {
    createJourneyStepAttachment,
    journeyStepAttachmentList,
    fetchJourneyStepAttachments,
  } = JourneyStepAttachmentService();

  useEffect(() => {
    if (activeJourneyCategoryStep?.id) {
     (activeJourneyCategoryStep.templateStepArticleList?.length || activeJourneyCategoryStep.stepArticleList?.length) ? setShowArticles(true): setShowArticles(false);
     (activeJourneyCategoryStep.templateStepQuestionList?.length || activeJourneyCategoryStep.stepQuestionList?.length) ? setShowFaq(true): setShowFaq(false);
      setPreviousActiveTemplateStep(activeJourneyCategoryStep)
      if (customizeJourney) {
        setInitialValues({
          id: activeJourneyCategoryStep?.id,
          title: activeJourneyCategoryStep?.title,
          stepTime: activeJourneyCategoryStep?.stepTime
            ? moment.utc(activeJourneyCategoryStep?.stepTime)
              .format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          stepEndTime: activeJourneyCategoryStep?.stepEndTime
            ? moment.utc(activeJourneyCategoryStep?.stepEndTime)
              .format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          description: activeJourneyCategoryStep?.description,
          stepArticles: activeJourneyCategoryStep?.stepArticleList?.map(
            (stepArticleItem) =>
              stepArticleItem.link ?? new JourneyTemplateStepEntityModel()
          ),
          stepQuestions: activeJourneyCategoryStep?.stepQuestionList?.map(
            (stepQuestionItem) =>
              stepQuestionItem.link ?? new JourneyTemplateStepEntityModel()
          ),
        });
        fetchJourneyStepAttachments(
          activeJourneyCategoryStep?.id,
          "attachment"
        );
      } else {
        const { templateStepArticleList = [], templateStepQuestionList = [] } =
          activeJourneyCategoryStep;
        setInitialValues({
          ...activeJourneyCategoryStep,
          templateStepArticles: templateStepArticleList,
          templateStepQuestions: templateStepQuestionList,
        });
        fetchJourneyTemplateAttachments(
          activeJourneyCategoryStep?.id,
          "attachment"
        );
      }
    } else {
      setInitialValues(new JourneyTemplateStep());
      setShowArticles(false);
      setShowFaq(false);
    }
  }, [activeJourneyCategoryStep]);

  const handleSelectAttachment = (event: any) => {
    if (event?.target?.files?.length) {
      const data: JourneyTemplateAttachment = {
        attachment: event?.target?.files[0],
        [customizeJourney ? "stepAttachmentType" : "templateAttachmentType"]:
          "attachment",
      };
      customizeJourney
        ? createJourneyStepAttachment(data, activeJourneyCategoryStep?.id)
        : createJourneyTemplateAttachment(data, activeJourneyCategoryStep?.id);
    }
  };

  const onSubmit = (values: JourneyTemplateStep) => {
    if (values.stepTime)
      values.stepTime = moment.utc(values.stepTime).format()
    if (values.stepEndTime)
      values.stepEndTime = moment.utc(values.stepEndTime).format()
    if (activeJourneyCategory?.id) {
      if (customizeJourney) {
        if (activeJourneyCategoryStep) {
          updateJourneyStep(
            activeJourneyCategory?.id,
            activeJourneyCategoryStep?.id,
            values
          ).then((step) => {
            step && setActiveJourneyCategoryStep(step);
            fetchJourneyCategories(+id);
          });
        } else {
          createJourneyStep(activeJourneyCategory.id, values, (step) => {
            setActiveJourneyCategoryStep(step);
            fetchJourneyCategories(+id);
          });
        }
      } else {
        if (activeJourneyCategoryStep) {
          updateJourneyTemplateStep(
            activeJourneyCategory?.id,
            activeJourneyCategoryStep?.id,
            values
          ).then((step) => {
            step && setActiveJourneyCategoryStep(step)
            fetchJourneyTemplateCategories(+id);
          });
        } else {
          createJourneyTemplateStep(
            activeJourneyCategory.id,
            values,
            (step) => {
              setActiveJourneyCategoryStep(step);
              fetchJourneyTemplateCategories(+id);
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    if (journeyTemplateCategoryList?.length > 0)
      setJourneyCategories(journeyTemplateCategoryList);
  }, [journeyTemplateCategoryList]);

  useEffect(() => {
    if (journeyCategoryList?.length > 0)
      setJourneyCategories(journeyCategoryList);
  }, [journeyCategoryList]);

  return activeJourneyCategory?.id ? (
    <>
      <div className="journey-template-step-form">
        <Formik
          onSubmit={onSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            customizeJourney
              ? journeyStepValidationSchema
              : templateStepValidationSchema
          }
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="journey-template-step__field">
                <div className="form-label">Title</div>
                <InputField
                  type="text"
                  name="title"
                  placeholder="Enter title"
                />
              </div>
              {customizeJourney ? (
                <Row className="journey-template-step__field date_row">
                  <Col span={11} >
                    <div className="form-label">Start date (Optional)</div>
                    <DatepickerField
                      name="stepTime"
                      disableDates="past"
                      placeholder="Select start date"
                      format="YYYY-MM-DD hh:mm A"
                      value={values?.stepTime ? moment.utc(values?.stepTime) : null}
                      onChange={(value, dateString) => {
                        setFieldValue("stepTime", dateString);
                        setFieldValue("stepEndTime", "");
                      }}
                    />
                  </Col>
                  <Col span={11} >
                    <div className="form-label">End date (Optional)</div>
                    <DatepickerField
                      disableDates="past"
                      name="stepEndTime"
                      placeholder="Select end date"
                      format="YYYY-MM-DD hh:mm A"
                      value={
                        values?.stepEndTime ? moment.utc(values?.stepEndTime) : null
                      }
                      onChange={(value, dateString) => {
                        setFieldValue("stepEndTime", dateString);
                      }}
                      startValue={values?.stepTime}
                    />
                  </Col>
                </Row>
              ) : true ?? (
                <div className="journey-template-step__field schedule-field">
                  <div className="form-label">Primary Date</div>
                  <InputField
                    type="number"
                    name="scheduleDays"
                    placeholder=""
                    min="0"
                  />
                  <DropdownField
                    name="schedule"
                    showSearch
                    options={templateStepDropdownOptions}
                    onChange={(value) => {
                      setFieldValue("schedule", value);
                    }}
                    value={values?.schedule}
                  />
                </div>
              )}
              <div className="journey-template-step__field">
                <div className="form-label">Description</div>
                <RichTextField
                  name={"description"}
                  editorData={values?.description || ""}
                  setFieldValue={setFieldValue}
                />
              </div>
              {activeJourneyCategoryStep?.id && (
                <div className="journey-template-step__actions">
                  <div className="journey-template-step__action">
                    <ButtonComponent
                      text={"Checklist"}
                      iconClass={"icon-accepted"}
                      onClick={() => {
                        setShowChecklist(true);
                      }}
                      loading={false}
                      type={"text"}
                      className={""}
                    />
                  </div>
                  <div className="journey-template-step__action">
                    <Input
                      type={"file"}
                      onChange={handleSelectAttachment}
                      className="journey-template-step__attachment-input"
                    />
                    <ButtonComponent
                      text={"Files"}
                      iconClass={"icon-attachments"}
                      onClick={() => { }}
                      loading={false}
                      type={"text"}
                      className={""}
                    />
                  </div>
                  <div className="journey-template-step__action">
                    <ButtonComponent
                      text={"Carousal"}
                      iconClass={"icon-carousal"}
                      onClick={() => {
                        setShowCarouselForm(true);
                      }}
                      loading={false}
                      type={"text"}
                      className={""}
                    />
                  </div>
                  {customizeJourney && (
                    <div className="journey-template-step__action">
                      <ButtonComponent
                        text={"Members"}
                        iconClass={"icon-members"}
                        onClick={() => {
                          setShowMembers(true);
                        }}
                        loading={false}
                        type={"text"}
                        className={""}
                      />
                    </div>
                  )}
                  <div className="journey-template-step__action">
                    <ButtonComponent
                      text={"Content"}
                      iconClass={"icon-articles"}
                      onClick={() => {
                        setShowArticles(true);
                      }}
                      loading={false}
                      type={"text"}
                      className={""}
                    />
                  </div>
                  <div className="journey-template-step__action">
                    <ButtonComponent
                      text={"FAQ"}
                      iconClass={"icon-faq"}
                      onClick={() => {
                        setShowFaq(true);
                      }}
                      loading={false}
                      type={"text"}
                      className={""}
                    />
                  </div>
                </div>
              )}
            <div className={`journey-template-step__disc ${activeJourneyCategoryStep?.id && "action_active"}`}>
              {(showChecklist ||
                showMembers ||
                showCarouselForm ||
                showArticles ||
                showFaq) && (
                  <div>
                    <div className="journey-template-step__title">
                      Additional Information
                    </div>
                    <div className="journey-template-step__description">
                      Note: All the data in this section will be auto saved on
                      making any changes
                    </div>
                  </div>
                )}
              <JourneyTemplateChecklistForm
                step={activeJourneyCategoryStep}
                showChecklist={showChecklist}
                setShowChecklist={setShowChecklist}
                customizeJourney={customizeJourney}
                setFieldValue={setFieldValue}
              />
              {customizeJourney && (
                <div className="journey-template-step__content">
                  <JourneyTemplateMembers
                    step={activeJourneyCategoryStep}
                    setShowMembers={setShowMembers}
                    showMembers={showMembers}
                  />
                </div>
              )}
              <JourneyTemplateCarouselForm
                step={activeJourneyCategoryStep}
                showCarousel={showCarouselForm}
                setShowCarousel={setShowCarouselForm}
                customizeJourney={customizeJourney}
              />
              <JourneyTemplateAttachments
                step={activeJourneyCategoryStep}
                disableEmptyState
                attachments={
                  customizeJourney
                    ? journeyStepAttachmentList
                    : journeyTemplateAttachmentList
                }
                refresh={
                  customizeJourney
                    ? fetchJourneyStepAttachments
                    : fetchJourneyTemplateAttachments
                }
                customizeJourney={customizeJourney}
                showDeleteIcon={true}
              />
              {showArticles && (
                <JourneyTemplateStepEntity
                  type={JourneyStepEntityEnum.ARTICLE}
                  fieldName={
                    customizeJourney ? "stepArticles" : "templateStepArticles"
                  }
                  stepEntities={
                    customizeJourney
                      ? values?.stepArticles
                      : values?.templateStepArticles
                  }
                  setFieldValue={setFieldValue}
                  showField = {showArticles}
                  setShowField = {setShowArticles}
                />
              )}
              {showFaq && (
                <JourneyTemplateStepEntity
                  type={JourneyStepEntityEnum.FAQ}
                  fieldName={
                    customizeJourney ? "stepQuestions" : "templateStepQuestions"
                  }
                  stepEntities={
                    customizeJourney
                      ? values?.stepQuestions
                      : values?.templateStepQuestions
                  }
                  setFieldValue={setFieldValue}
                  showField = {showFaq}
                  setShowField = {setShowFaq}
                />
              )}
              </div>
              <div className="journey-template-step-form__actions">
                <ButtonComponent
                  text={"Cancel"}
                  loading={false}
                  type={"text"}
                  onClick={() =>setActiveJourneyCategoryStep(previousActiveTemplateStep)}
                />
                <ButtonComponent
                  htmlType={"submit"}
                  text={"Save event details"}
                  loading={customizeJourney ? stepButtonLoading : buttonLoading}
                  type={"primary"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  ) : null;
};

export default JourneyTemplateStepForm;
