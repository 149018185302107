import React, { FC, useEffect, useState } from "react";
import "./journeyTemplateMembers.scss";
import userPlaceHolder from "../../../../../assets/userPlaceholder.png";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import { Popover, Select } from "antd";
import { DropdownModeEnum } from "../../../../../enums/dropdownMode.enum";
import JourneyService from "../../../../../services/JourneyService/journey.service";
import { useParams } from "react-router-dom";
import { Staff } from "../../../../../models/Hospital/staff.model";
import JourneyStepService from "../../../../../services/JourneyService/journeyStep.service";
import StaffBasicDetails from "../../../../../shared/components/StaffBasicDetails";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import { useJourneyContext } from "../../../../../context/JourneyContext";
import DpChip from "../../../../../shared/components/DpChip";
import { getFullName } from "../../../../../shared/utils/dataFormatConverter";

interface JourneyTemplateMembersProps {
  step?: JourneyTemplateStep;
  hidePopover?: boolean;
  setShowMembers: Function;
  showMembers: boolean;
}

const JourneyTemplateMembers: FC<JourneyTemplateMembersProps> = (props) => {
  const { step, hidePopover, showMembers, setShowMembers } = props;

  const { id } = useParams<{ id: string }>();

  const {
    journeyDetails: { activeJourneyCategory },
  } = useJourneyContext();

  const { showJourney, journey } = JourneyService();
  const { updateJourneyStep, showJourneyStep, journeyStep } =
    JourneyStepService();

  const [visible, setVisible] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [currentStaff, setCurrentStaff] = useState<Staff>({});
  const [memberOptions, setMemberOptions] = useState<any>([]);
  const [members, setMembers] = useState<Array<Staff> | undefined>([]);
  const [selectedMemberIds, setSelectedMemberIds] = useState<any>([]);

  useEffect(() => {
    setMembers([]);
    setShowMembers(false);
    if (id) showJourney(id);
    if (step?.id && activeJourneyCategory?.id) showJourneyStep(activeJourneyCategory?.id, step?.id);
  }, [step]);

  useEffect(() => {
    if (journey)
      setMemberOptions(
        journey?.staffs?.filter((staff:Staff) => staff?.status)?.map((staff: Staff) => ({
          label: (
            <div className="member-option">
              <div className="member-image">
                {/* <img src={ ?? userPlaceHolder} alt="" /> */}
                <DpChip name={getFullName(staff)}
                  url={staff?.displayPictureUrl} />
              </div>
              <div className="member-info">
                <div className="member-name text-capitalize">
                {getFullName(staff)}
                </div>
                <div className="member-speciality">
                  {staff?.department?.name}
                </div>
              </div>
            </div>
          ),
          value: staff?.id,
        }))
      );
  }, [journey]);

  useEffect(() => {
    if (journeyStep) {
      setMembers(journeyStep?.staffs);
      setShowMembers(journeyStep?.staffs && journeyStep?.staffs?.length > 0);
      setSelectedMemberIds(
        journeyStep?.staffs ? journeyStep?.staffs?.map((o) => o.id) : []
      );
    }
  }, [journeyStep]);

  const membersPopoverContent = (
    <>
      <div className="journey-template-members__select">
        <Select
          placeholder="Select members"
          options={memberOptions}
          mode={DropdownModeEnum.MULTIPLE}
          value={selectedMemberIds}
          onSelect={(values) => {
            handleAddMember(values);
          }}
          onDeselect={(values) => {
            handleRemoveMember(values);
          }}
        />
      </div>
    </>
  );

  const handleAddMember = (staffId: any) => {
    const data = {
      staffIds: [...selectedMemberIds, staffId],
    };

    activeJourneyCategory?.id && updateJourneyStep(activeJourneyCategory?.id, step?.id, data).then(() =>
      showJourneyStep(activeJourneyCategory?.id, step?.id)
    )
  };

  const handleRemoveMember = (staffId: any) => {
    const data = {
      staffIds: selectedMemberIds.filter((value: number) => value !== staffId),
    };

    activeJourneyCategory?.id && updateJourneyStep(activeJourneyCategory?.id, step?.id, data).then(() =>
      showJourneyStep(activeJourneyCategory?.id, step?.id)
    );
  };

  return (
    <>
      {currentStaff && <StaffBasicDetails
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        staff={currentStaff}
        setCurrentStaff={setCurrentStaff}
      />}

      {!!showMembers && (
        <div className="journey-template-members">
          <div className="journey-template-step__label">Doctors</div>
          {!hidePopover && (
            <Popover
              content={membersPopoverContent}
              title="Members"
              placement="rightTop"
              trigger="click"
              visible={visible}
              onVisibleChange={(visible) => setVisible(visible)}
            >
              <ButtonComponent
                text={"+"}
                type={"text"}
                loading={false}
                className="journey-template-members__add"
              />
            </Popover>
          )}
          {members?.map((staff: Staff) => (
            <div
              className="journey-template-members__list"
              onClick={() => {
                setCurrentStaff(staff);
                setShowDetails(true);
              }}
            >
              <DpChip name={getFullName(staff)}
                url={staff?.displayPictureUrl} />
              <span className="journey-events__card__staff__name ml-2">
                {getFullName(staff)}
              </span>
              {!hidePopover && (
                <div
                  className="remove-icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleRemoveMember(staff?.id);
                  }}
                >
                  x
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default JourneyTemplateMembers;
